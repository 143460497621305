import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSnackbarOptions } from "../../lib/getSnackbarOptions";
import { TeamQuestionnaire } from "../../models/questionnaire/teamQuestionnaire";
import { Error } from "../common/error/Error.component";
import { Loader } from "../common/loader/Loader.component";
import { useApi } from "../common/providers/Api.provider";
import { useUser } from "../common/providers/User.provider";
import { useTranslation } from "react-i18next";

export function InviteComponent() {
  const { t: inviteTranslations } = useTranslation("invite-page", {
    useSuspense: false,
  });
  const api = useApi();
  const navigate = useNavigate();
  const { user, userRole, setUserRole } = useUser();
  const snackbar = useSnackbar();
  const { uuid } = useParams<{ uuid: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [teamQuestionnaireState, setTeamQuestionnaireState] =
    useState<TeamQuestionnaire | null>(null);

  const displayError = (error: React.ReactNode) => {
    setHasError(true);

    snackbar.enqueueSnackbar(
      error,
      getSnackbarOptions({
        variant: "error",
      })
    );
  };

  const getTeamQuestionnaireByUuid = async (uuid: string) => {
    try {
      setIsLoading(true);

      const { data: teamQuestionnaire } = await api.getInvite(uuid);

      if (teamQuestionnaire) {
        setTeamQuestionnaireState(teamQuestionnaire);
      } else {
        displayError(inviteTranslations("notFound"));
      }
    } catch (e) {
      displayError(e as String);
    } finally {
      setIsLoading(false);
    }
  };

  const acceptInvite = async (uuid: string, queryParams: string) => {
    try {
      const { data: newUserRole } = await api.acceptInvite(uuid);

      if (newUserRole) {
        setUserRole(newUserRole);
        navigate(`/scans${queryParams}`);
      } else {
        displayError(inviteTranslations("cannotAccept"));
      }
    } catch (e) {
      displayError(e as String);
    }
  };

  useEffect(() => {
    if (uuid) {
      getTeamQuestionnaireByUuid(uuid);
    } else {
      displayError(inviteTranslations("notFound"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    if (uuid) {
      const queryParams = `?invite=${uuid}`;

      // CASE: NOT LOGGED IN
      if (teamQuestionnaireState && !user) {
        navigate(`/login${queryParams}`);
        // CASE: LOGGED IN
      } else if (teamQuestionnaireState && user) {
        const correspondingUserRoleIndex = user.userRoles.findIndex(
          (userRole) => userRole.teamId === teamQuestionnaireState.teamId
        );
        // Logged in, but role not corresponding with team
        if (correspondingUserRoleIndex === -1) {
          acceptInvite(uuid, queryParams);
          // Logged in, is in corresponding team
        } else {
          if (userRole.teamId !== teamQuestionnaireState.teamId) {
            setUserRole(user.userRoles[correspondingUserRoleIndex]);
          }

          navigate(`/scans${queryParams}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamQuestionnaireState]);

  if (isLoading) {
    return <Loader />;
  }

  if (hasError || !teamQuestionnaireState) {
    return <Error />;
  }

  return <></>;
}
