import React, { useState, useEffect } from "react";
import { User } from "../../../../../../models/user/user";
import { useSnackbar } from "notistack";
import { useUser } from "../../../../../common/providers/User.provider";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import "./UserChangePasswordDialog.component.sass";
import { useApi } from "../../../../../common/providers/Api.provider";
import {
  ChangePasswordState,
  initialChangePasswordState,
  isChangePasswordStateValid,
  validateChangePasswordState,
} from "./UserChangePasswordState";
import { useTranslation } from "react-i18next";

export interface UserChangePasswordDialogComponentProps {
  open: boolean;
  user?: User;
  handleClose: () => void;
}

export function UserChangePasswordDialogComponent({
  open,
  user,
  handleClose,
}: UserChangePasswordDialogComponentProps) {
  const { t: userTranslations } = useTranslation("users-page", {
    keyPrefix: "dialogs.changePassword",
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();

  const { user: authenticatedUser } = useUser();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [showValidationState, setShowValidationState] =
    useState<boolean>(false);
  const isAuthenticatedUser = () =>
    !!user ? authenticatedUser.id === user.id : false;
  const [changePasswordState, setChangePasswordState] =
    useState<ChangePasswordState>(initialChangePasswordState);

  const handleChangePassword = async (
    changePasswordState: ChangePasswordState
  ) => {
    setChangePasswordState(
      validateChangePasswordState(changePasswordState, userTranslations)
    );
    if (!isChangePasswordStateValid(changePasswordState)) {
      setShowValidationState(true);
      return;
    }

    setShowValidationState(false);

    try {
      setIsLoadingState(true);

      const response = await api.usersChangePassword(user!.id, {
        oldPassword: changePasswordState.oldPassword,
        newPassword: changePasswordState.password,
        repeatNewPassword: changePasswordState.repeatPassword,
      });

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({
            variant: "success",
          })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({
            variant: "error",
          })
        );
      }

      handleClose();
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({ variant: "error" })
      );
    } finally {
      setChangePasswordState(initialChangePasswordState);
      setIsLoadingState(false);
    }
  };

  const renderOldPassword = () => {
    if (!isAuthenticatedUser()) {
      return;
    }

    if (!changePasswordState) {
      return <Skeleton variant="text" />;
    }

    return (
      <TextField
        autoFocus
        fullWidth
        required
        variant="outlined"
        margin="dense"
        id="oldPassword"
        label={userTranslations("oldPassword.label")}
        type="password"
        value={changePasswordState.oldPassword}
        onChange={(event) =>
          setChangePasswordState(
            validateChangePasswordState(
              {
                ...changePasswordState,
                oldPassword: event.target.value,
              },
              userTranslations
            )
          )
        }
        error={!!changePasswordState.oldPasswordError && showValidationState}
        helperText={
          !!changePasswordState.oldPasswordError && showValidationState
            ? changePasswordState.oldPasswordError
            : undefined
        }
      />
    );
  };

  useEffect(() => {
    if (!!user) {
      setChangePasswordState({
        ...initialChangePasswordState,
        oldPasswordRequired: isAuthenticatedUser(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Dialog open={open} className="user-change-password-dialog-container">
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!changePasswordState ? (
          <Skeleton variant="text" />
        ) : (
          userTranslations("title", { email: user?.email })
        )}
      </DialogTitle>
      <DialogContent>
        {renderOldPassword()}
        {!changePasswordState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus={!isAuthenticatedUser()}
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="newPassword"
            label={userTranslations("newPassword.label")}
            type="password"
            value={changePasswordState.password}
            onChange={(event) =>
              setChangePasswordState(
                validateChangePasswordState(
                  {
                    ...changePasswordState,
                    password: event.target.value,
                  },
                  userTranslations
                )
              )
            }
            error={!!changePasswordState.passwordError && showValidationState}
            helperText={
              !!changePasswordState.passwordError && showValidationState
                ? changePasswordState.passwordError
                : undefined
            }
          />
        )}
        {!changePasswordState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="repeatNewPassword"
            label={userTranslations("confirmPassword.label")}
            type="password"
            value={changePasswordState.repeatPassword}
            onChange={(event) =>
              setChangePasswordState(
                validateChangePasswordState(
                  {
                    ...changePasswordState,
                    repeatPassword: event.target.value,
                  },
                  userTranslations
                )
              )
            }
            error={
              !!changePasswordState.repeatPasswordError && showValidationState
            }
            helperText={
              !!changePasswordState.repeatPasswordError && showValidationState
                ? changePasswordState.repeatPasswordError
                : undefined
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        {!changePasswordState ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button
            onClick={() => {
              setChangePasswordState(initialChangePasswordState);
              setShowValidationState(false);
              handleClose();
            }}
            color="secondary"
          >
            {commonTranslations("cancel")}
          </Button>
        )}
        {!changePasswordState ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button
            onClick={() => handleChangePassword(changePasswordState)}
            color="primary"
          >
            {commonTranslations("edit")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
