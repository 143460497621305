import { TFunction } from "i18next";

export class RevisionWithCycle {
  questionnaireId!: number;
  revision!: number;
  cycle!: number;

  toString(t: TFunction): string {
    return t("revisionWithCycle", {
      revision: this.revision,
      cycle: this.cycle,
    });
  }
}
