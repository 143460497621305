import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import React from "react";
import { QuestionnaireRole } from "../../../../../../../models/questionnaire-role/questionnaireRole";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

export interface RoleListItemComponentProps {
  questionnaireRole: QuestionnaireRole;
  setQuestionnaireRoleToDelete: React.Dispatch<
    React.SetStateAction<QuestionnaireRole | undefined>
  >;
  setQuestionnaireRoleToUpdate: React.Dispatch<
    React.SetStateAction<QuestionnaireRole | undefined>
  >;
}

export function RoleListItemComponent({
  questionnaireRole,
  setQuestionnaireRoleToDelete,
  setQuestionnaireRoleToUpdate,
}: RoleListItemComponentProps) {
  const { t } = useTranslation("common", {
    useSuspense: false,
  });

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {questionnaireRole.id}
      </TableCell>
      <TableCell>{questionnaireRole.name}</TableCell>
      <TableCell>
        <Tooltip title={t("edit")} placement="top">
          <IconButton
            onClick={() => setQuestionnaireRoleToUpdate(questionnaireRole)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("remove")} placement="top">
          <IconButton
            onClick={() => setQuestionnaireRoleToDelete(questionnaireRole)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
