import React, { useEffect, useState } from "react";
import { Paper, TextField, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Team } from "../../../../../../models/team/team";
import { TeamUpdateDto } from "../../../../../../models/team/teamUpdateDto";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { Loader } from "../../../../../common/loader/Loader.component";
import "./Details.component.sass";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface DetailsComponentProps {
  teamId: string;
  onSaveChanges: (team: Team) => void;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function DetailsComponent({
  teamId,
  onSaveChanges,
  setHasError,
}: DetailsComponentProps) {
  const { t: teamDetailTranslations } = useTranslation("team-detail-page", {
    keyPrefix: "tabs.details",
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();

  const [isDetailsLoadingState, setIsDetailsLoadingState] =
    useState<boolean>(false);

  const [teamUpdateDtoState, setTeamUpdateDtoState] =
    useState<TeamUpdateDto | null>(null);

  const getTeam = async (teamId: number) => {
    try {
      setIsDetailsLoadingState(true);
      const response = await api.teamById(teamId);

      setTeamUpdateDtoState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsDetailsLoadingState(false);
    }
  };

  const handleUpdate = async (teamUpdateDto: TeamUpdateDto) => {
    if (!teamUpdateDto.id || !teamUpdateDto.name.length) {
      return;
    }

    try {
      const response = await api.teamsUpdate(teamUpdateDto);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      setTeamUpdateDtoState(response.data);
      onSaveChanges(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    }
  };

  useEffect(() => {
    getTeam(Number(teamId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  return (
    <Paper className={"team-details-container"}>
      {isDetailsLoadingState || !teamUpdateDtoState ? (
        <Loader loadingText={teamDetailTranslations("loading")} />
      ) : (
        <>
          <span className={"team-details-container__title"}>Team details</span>
          <TextField
            className={"team-details-container__form-control"}
            fullWidth
            variant="outlined"
            label={teamDetailTranslations("name")}
            value={teamUpdateDtoState.name}
            onChange={(event) =>
              setTeamUpdateDtoState({
                ...teamUpdateDtoState,
                name: event.target.value,
              })
            }
          />
          <div className={"team-details-container__button-container"}>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => handleUpdate(teamUpdateDtoState)}
            >
              {commonTranslations("saveChanges")}
            </Button>
          </div>
        </>
      )}
    </Paper>
  );
}
