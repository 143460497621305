import React from "react";
import "./Error.component.sass";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ErrorProps {
  code?: string;
  message?: string;
  buttonText?: string;
}

export function Error({ code, message, buttonText }: ErrorProps) {
  const { t } = useTranslation("common", {
    keyPrefix: "error",
    useSuspense: false,
  });

  return (
    <div className="error-container">
      <h2 className="error-container__title">{t("title")}</h2>
      {!!code && (
        <h3 className="error-container__code">{t("code", { code })}</h3>
      )}
      {!!message && <p className="error-container__message">{message}</p>}
      <Button
        variant="contained"
        color="primary"
        className={"error-container__button"}
        onClick={() => window.location.reload()}
      >
        {!!buttonText ? buttonText : t("buttonText")}
      </Button>
    </div>
  );
}
