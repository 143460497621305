export function zip<T>(values: Array<Array<T>>): Array<T> {
  const [a, ...others] = values;
  return a.map((entry, index) => {
    var result = { ...entry };

    others.forEach(o => {
      result = { ...result, ...o[index]}
    })

    return result;
  });
}
