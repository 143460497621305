import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { getTeamQuestionnaireFromTeam } from "../../../../../../../lib/getTeamQuestionnaire";
import { Team } from "../../../../../../../models/team/team";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface TeamUpdateCycleDialogComponentProps {
  open: boolean;
  questionnaireId: number;
  team?: Team;
  handleClose: (teamId?: number, cycle?: number) => void;
}

export function TeamUpdateCycleDialogComponent({
  open,
  questionnaireId,
  team,
  handleClose,
}: TeamUpdateCycleDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "teams.dialogs.updateCycle",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [selectedCycleState, setSelectedCycleState] = useState<
    number | undefined
  >(undefined);

  const handleUpdateCyclus = async () => {
    if (selectedCycleState === undefined || !team) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.updateTeamQuestionnaireCycle(
        questionnaireId,
        team.id,
        selectedCycleState
      );

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(team.id, selectedCycleState);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setIsLoadingState(false);
    }
  };

  const renderCyclusSelect = () => {
    return (
      <TextField
        fullWidth
        required
        variant="outlined"
        margin="dense"
        type="number"
        id="cyclus"
        label={questionnaireTranslations("activeCycle")}
        value={selectedCycleState?.toString()}
        onChange={(event) => {
          const n = Number(event.target.value);

          if (n > -1) {
            setSelectedCycleState(n);
          }
        }}
      />
    );
  };

  useEffect(() => {
    if (!!team) {
      setSelectedCycleState(
        getTeamQuestionnaireFromTeam(team, questionnaireId)?.cycle
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!team || selectedCycleState === undefined ? (
          <Skeleton variant="text" />
        ) : (
          questionnaireTranslations("title", { team: team.name })
        )}
      </DialogTitle>
      <DialogContent>
        {!team || selectedCycleState === undefined ? (
          <Skeleton variant="rectangular" />
        ) : (
          renderCyclusSelect()
        )}
      </DialogContent>
      <DialogActions>
        {!team || selectedCycleState === undefined ? (
          <Skeleton variant="rectangular" />
        ) : (
          <>
            <Button
              onClick={() => {
                setSelectedCycleState(undefined);
                handleClose(undefined, undefined);
              }}
              color="secondary"
            >
              {commonTranslations("cancel")}
            </Button>
            <Button
              onClick={() => {
                if (
                  selectedCycleState !== undefined &&
                  team &&
                  getTeamQuestionnaireFromTeam(team, questionnaireId)?.cycle !==
                    selectedCycleState
                ) {
                  handleUpdateCyclus();
                }
              }}
              color="primary"
            >
              {commonTranslations("edit")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
