import React from "react";
import { Question } from "../../../../../../../../models/question/question";
import { Button, Typography, TextField, IconButton } from "@mui/material";
import { LikertScaleData } from "../../../../../../../../models/question/likertScaleData";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ColumnData } from "../../../../../../../../models/question/columnData";
import "./LikertScaleInput.component.sass";
import { RowData } from "../../../../../../../../models/question/rowData";
import { useTranslation } from "react-i18next";

export interface LikertScaleInputComponentProps {
  value: Question<LikertScaleData>;
  setValue: (value: Question<LikertScaleData>) => void;
}

export function LikertScaleInputComponent({
  value,
  setValue,
}: LikertScaleInputComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "steps.questions.likertScale",
      useSuspense: false,
    }
  );

  const emptyColumnOption: ColumnData = {
    label: "",
    score: 0,
  };

  const addColumn = () => {
    setValue({
      ...value,
      data: {
        ...value.data,
        columns: [...(value.data.columns || []), emptyColumnOption],
      },
    });
  };

  const removeColumn = (index: number) => {
    const columns = value.data.columns;

    columns.splice(index, 1);

    setValue({
      ...value,
      data: {
        ...value.data,
        columns,
      },
    });
  };

  const handleChangeColumn = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: "label" | "score",
    index: number
  ) => {
    setValue({
      ...value,
      data: {
        ...value.data,
        columns: (value.data.columns || []).map((column, i) =>
          index === i ? { ...column, [key]: event.target.value } : column
        ),
      },
    });
  };

  const renderColumns = () => {
    const columns = value.data.columns;

    if (!columns || !columns.length) {
      return <Typography>{questionnaireTranslations("noColumns")}</Typography>;
    }

    return columns.map((column, index) => (
      <div className="likert-scale-input__column" key={index}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label={questionnaireTranslations("label")}
          color="primary"
          className="likert-scale-input__form-control"
          value={column.label}
          onChange={(event) => handleChangeColumn(event, "label", index)}
        />
        <TextField
          fullWidth
          required
          variant="outlined"
          label={questionnaireTranslations("score")}
          color="primary"
          className="likert-scale-input__form-control"
          value={column.score}
          onChange={(event) => handleChangeColumn(event, "score", index)}
        />
        <IconButton color="secondary" onClick={() => removeColumn(index)}>
          <RemoveIcon />
        </IconButton>
      </div>
    ));
  };

  const emptyRowOption: RowData = {
    instruction: "",
    hint: "",
  };

  const addRow = () => {
    setValue({
      ...value,
      data: {
        ...value.data,
        rows: [...(value.data.rows || []), emptyRowOption],
      },
    });
  };

  const removeRow = (index: number) => {
    const rows = value.data.rows;

    rows.splice(index, 1);

    setValue({
      ...value,
      data: {
        ...value.data,
        rows,
      },
    });
  };

  const handleChangeRow = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    property: "instruction" | "hint",
    index: number
  ) => {
    setValue({
      ...value,
      data: {
        ...value.data,
        rows: (value.data || []).rows.map((row, i) =>
          index === i ? { ...row, [property]: event.target.value } : row
        ),
      },
    });
  };

  const renderRows = () => {
    const rows = value.data.rows;

    if (!rows || !rows.length) {
      return <Typography>{questionnaireTranslations("noRows")}</Typography>;
    }

    return rows.map((row, index) => (
      <div className="likert-scale-input__row" key={index}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label={questionnaireTranslations("instruction")}
          color="primary"
          className="likert-scale-input__form-control"
          value={row.instruction}
          onChange={(event) => handleChangeRow(event, "instruction", index)}
        />
        <TextField
          fullWidth
          variant="outlined"
          label={questionnaireTranslations("hint")}
          color="primary"
          className="likert-scale-input__form-control"
          value={row.hint}
          onChange={(event) => handleChangeRow(event, "hint", index)}
        />
        <IconButton color="secondary" onClick={() => removeRow(index)}>
          <RemoveIcon />
        </IconButton>
      </div>
    ));
  };

  return (
    <div className="likert-scale-input">
      <div className="likert-scale-input__title">
        {questionnaireTranslations("columns")}
      </div>
      <div className="likert-scale-input__columns">{renderColumns()}</div>
      <div className="likert-scale-input__add-column">
        <Button onClick={addColumn} color="secondary">
          <AddIcon />
          {questionnaireTranslations("addColumn")}
        </Button>
      </div>
      <div className="likert-scale-input__title">
        {questionnaireTranslations("rows")}
      </div>
      <div className="likert-scale-input__rows">{renderRows()}</div>
      <div className="likert-scale-input__add-row">
        <Button onClick={addRow} color="secondary">
          <AddIcon />
          {questionnaireTranslations("addRow")}
        </Button>
      </div>
    </div>
  );
}
