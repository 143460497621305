import { Scan } from "../models/scan/scan";
import { RadarGraphEntry } from "../components/dashboard/pages/scan/graph/radar-graph/radarGraphEntry";
import { Mode } from "./mode";
import { convertModeToText } from "./convertModeToText";
import { TFunction } from "i18next";

export function formatScanToRadarGraphData(
  scan: Scan,
  mode: Mode,
  t: TFunction
): RadarGraphEntry[] {
  const entries: RadarGraphEntry[] = [];
  if (!!scan) {
    for (const categoryResult of scan.scanCategoryResults) {
      const category = categoryResult.category;

      entries.push({
        category: category.name,
        [convertModeToText(mode, t)]: Number(
          categoryResult.scaledScore.toPrecision(2)
        ),
      } as any);
    }
  }

  return entries;
}
