import React from "react";
import { ResponsiveRadar } from "@nivo/radar";
import { RadarGraphEntry } from "./radarGraphEntry";

export interface RadarGraphProps {
  isMobile: boolean;
  data:
    | RadarGraphEntry[]
    | Array<{ [key: string]: number | string; category: string }>;
  keys: string[];
  indexedBy: string;
  maxValue: number;
}

// TODO: Radar label re-sizing should be updated, don't use jsx-runtime (9 years old)
// var jsxRuntime = require("react/jsx-runtime");
// var web = require("@react-spring/web");
// export const RadarLabelComponent =
//   (isMobile: boolean) =>
//   (
//     { anchor, id, animated }: PropsWithChildren<GridLabelProps>,
//     context?: any
//   ) => {
//     if (isMobile) {
//       return null;
//     }

//     return jsxRuntime.jsx(web.animated.g, {
//       transform: animated.transform,
//       children: jsxRuntime.jsx("text", {
//         dominantBaseline: "central",
//         textAnchor: anchor,
//         children: getTspanGroups(id, 20, 3),
//       }),
//     });
//   };

export function RadarGraph({
  isMobile,
  data,
  keys,
  indexedBy,
  maxValue,
}: RadarGraphProps) {
  return (
    <ResponsiveRadar
      data={data as any}
      keys={keys}
      indexBy={indexedBy}
      maxValue={maxValue}
      margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
      curve="cardinalClosed"
      borderWidth={2}
      gridLevels={5}
      gridShape="circular"
      gridLabelOffset={36}
      enableDots={true}
      dotSize={10}
      dotColor="#ffffff"
      dotBorderWidth={2}
      enableDotLabel={true}
      dotLabel="value"
      dotLabelYOffset={-12}
      fillOpacity={0.25}
      blendMode="multiply"
      animate={true}
      isInteractive={true}
      // gridLabel={RadarLabelComponent(isMobile)}
      legends={
        isMobile
          ? []
          : [
              {
                anchor: "top-left",
                direction: "column",
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]
      }
    />
  );
}
