import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  Link,
  Paper,
  Button,
  Tooltip,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Team } from "../../../../../../models/team/team";
import { Loader } from "../../../../../common/loader/Loader.component";
import { Error } from "../../../../../common/error/Error.component";
import "./TeamList.component.sass";
import AddIcon from "@mui/icons-material/Add";
import { TeamAddDialogComponent } from "../dialog/team-add-dialog/TeamAddDialog.component";
import { TeamDeleteDialogComponent } from "../dialog/team-delete-dialog/TeamDeleteDialog.component";
import { TeamListItemComponent } from "../team-list-item/TeamListItem.component";
import { useApi } from "../../../../../common/providers/Api.provider";
import GroupIcon from "@mui/icons-material/Group";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

export function TeamListComponent() {
  const { t: teamsTranslations } = useTranslation("teams-page", {
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const navigate = useNavigate();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);
  const [teamsState, setTeamsState] = useState<Team[]>([]);
  const [filteredTeamsState, setFilteredTeamsState] = useState<Team[]>([]);
  const [searchTermState, setSearchTermState] = useState<string>("");
  const [isTeamAddDialogOpenState, setIsTeamAddDialogOpenState] =
    useState<boolean>(false);

  const [teamToDeleteState, setTeamToDeleteState] = useState<Team | undefined>(
    undefined
  );

  const getTeams = async () => {
    try {
      setIsLoadingState(true);
      const response = await api.teams();

      setTeamsStates(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTeamAddDialog = (result?: Team) => {
    if (!!result) {
      setTeamsStates([...teamsState, result]);
    }

    setIsTeamAddDialogOpenState(false);
  };

  const navigateToTeam = (teamId: number) => {
    setTimeout(() => {
      navigate(`/teams/${teamId}`);
    });
  };

  const handleCloseTeamDeleteDialog = (result?: number) => {
    if (!!result) {
      setTeamsStates(teamsState.filter((c) => c.id !== result));
    }

    setTeamToDeleteState(undefined);
  };

  const onChangeSearchTerm = (event: { target: { value: string } }) => {
    const searchTerm = event.target.value.toLowerCase();

    setSearchTermState(searchTerm);

    setFilteredTeamsState(
      teamsState.filter(
        (t) =>
          t.id.toString().toLowerCase().includes(searchTerm) ||
          t.name.toLowerCase().includes(searchTerm)
      )
    );
  };

  const setTeamsStates = (teams: Team[]) => {
    setTeamsState(teams);
    setFilteredTeamsState(teams);
  };

  const renderTeams = () => {
    if (isLoadingState) {
      return (
        <TableRow>
          <TableCell className="no-data-row" colSpan={4} align="center">
            <Loader />
          </TableCell>
        </TableRow>
      );
    }

    if (!filteredTeamsState || filteredTeamsState.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="center">
            {commonTranslations("search.noResults")}
          </TableCell>
        </TableRow>
      );
    }

    return filteredTeamsState.map((row, index) => (
      <TeamListItemComponent
        key={index}
        team={row}
        navigateToTeam={navigateToTeam}
        setTeamToDelete={setTeamToDeleteState}
      />
    ));
  };

  if (isLoadingState) {
    return <Loader />;
  }

  if (hasErrorState) {
    return <Error />;
  }

  return (
    <>
      <TeamAddDialogComponent
        open={isTeamAddDialogOpenState}
        handleClose={handleCloseTeamAddDialog}
      />
      <TeamDeleteDialogComponent
        open={!!teamToDeleteState}
        team={teamToDeleteState}
        handleClose={handleCloseTeamDeleteDialog}
      />
      <div className="team-list-container">
        <div className="team-list-container__header">
          <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
            <Link className="active" color="textPrimary" aria-current="page">
              <GroupIcon />
              {teamsTranslations("title")}
            </Link>
          </Breadcrumbs>
          <div className="questionnaire-list-container__information__statistics">
            <span>
              {commonTranslations("search.resultsLength", {
                length: filteredTeamsState.length,
              })}
            </span>
          </div>
        </div>
        <div className="questionnaire-list-container__search">
          <form noValidate autoComplete="off">
            <FormControl className="questionnaire-list-container__search__control">
              <OutlinedInput
                className="questionnaire-list-container__search__control__input"
                type="text"
                placeholder={commonTranslations("search.placeholder")}
                onChange={(event) => onChangeSearchTerm(event)}
                fullWidth={true}
                value={searchTermState}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search for results" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
          <Tooltip title={commonTranslations("add")} placement="top">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsTeamAddDialogOpenState(true)}
            >
              <AddIcon fontSize="large" />
            </Button>
          </Tooltip>
        </div>
        <TableContainer
          className={"team-list-container__table"}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{teamsTranslations("table.id")}</TableCell>
                <TableCell>{teamsTranslations("table.name")}</TableCell>
                <TableCell>{teamsTranslations("table.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTeams()}</TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
