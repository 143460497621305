import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
} from "@mui/material";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { Team } from "../../../../../../../models/team/team";
import Autocomplete from "@mui/material/Autocomplete";
import { TeamQuestionnaire } from "../../../../../../../models/questionnaire/teamQuestionnaire";
import { useTranslation } from "react-i18next";

export interface TeamAssignDialogComponentProps {
  questionnaireId: number;
  open: boolean;
  teams: number[];
  handleClose: (result?: Team) => void;
}

export function TeamAssignDialogComponent({
  questionnaireId,
  open,
  handleClose,
  teams,
}: TeamAssignDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "teams.dialogs.assign",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [isTeamsLoadingState, setIsTeamsLoadingState] =
    useState<boolean>(false);
  const [teamsState, setTeamsState] = useState<Team[]>([]);
  const [selectedTeamState, setSelectedTeamState] = useState<Team | null>(null);

  useEffect(() => {
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  const getTeams = async () => {
    try {
      setIsTeamsLoadingState(true);
      const response = await api.teams();

      setTeamsState(response.data.filter((t) => teams.indexOf(t.id) === -1));
    } catch (e) {
      console.error(e);
    } finally {
      setIsTeamsLoadingState(false);
    }
  };

  const handleAssign = async (team: Team | null) => {
    if (!team) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.assignTeamToQuestionnaire(
        questionnaireId,
        team.id
      );

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      if (team.teamQuestionnaires === undefined) {
        team.teamQuestionnaires = [];
      }

      team.teamQuestionnaires.push({
        questionnaireId: questionnaireId,
        teamId: team.id,
        cycle: 0,
      } as TeamQuestionnaire);

      handleClose(team);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setSelectedTeamState(null);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open} className="step-add-dialog-container">
      {isLoadingState && <LinearProgress />}
      <DialogTitle>{questionnaireTranslations("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {questionnaireTranslations("description")}
        </DialogContentText>
        {!isTeamsLoadingState && (
          <Autocomplete
            options={teamsState}
            getOptionLabel={(option) => option.name}
            onChange={(event: React.ChangeEvent<{}>, value: Team | null) => {
              setSelectedTeamState(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={questionnaireTranslations("team")}
                variant="outlined"
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          {commonTranslations("cancel")}
        </Button>
        <Button onClick={() => handleAssign(selectedTeamState)} color="primary">
          {commonTranslations("assign")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
