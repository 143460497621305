import React, { useEffect, useState } from "react";
import { Step } from "../../../../../../models/step/step";
import { StepAddDialogComponent } from "../dialog/step-add-dialog/StepAddDialog.component";
import { StepUpdateDialogComponent } from "../dialog/step-update-dialog/StepUpdateDialog.component";
import { StepDeleteDialogComponent } from "../dialog/step-delete-dialog/StepDeleteDialog.component";
import { StepComponent } from "../step/Step.component";
import { Fab, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Loader } from "../../../../../common/loader/Loader.component";
import "./Steps.component.sass";
import { move } from "../../../../../../lib/move";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface StepsComponentProps {
  questionnaireId: string | undefined;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function StepsComponent({
  questionnaireId,
  setHasError,
}: StepsComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "steps",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();

  const [isStepsLoadingState, setIsStepsLoadingState] =
    useState<boolean>(false);
  const [stepsState, setStepsState] = useState<Step[]>([]);

  const [isStepAddDialogOpenState, setIsStepAddDialogOpenState] =
    useState<boolean>(false);

  const [stepToUpdateState, setStepToUpdateState] = useState<Step | undefined>(
    undefined
  );

  const [stepToDeleteState, setStepToDeleteState] = useState<Step | undefined>(
    undefined
  );

  const [activeStep, setActiveStep] = useState<Step | null>(null);

  const getSteps = async (questionnaireId: number) => {
    try {
      setIsStepsLoadingState(true);
      const response = await api.stepsByQuestionnaireId(questionnaireId);

      setStepsState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsStepsLoadingState(false);
    }
  };

  const handleCloseStepAddDialog = (result?: Step) => {
    if (!!result) {
      setStepsState([...stepsState, result]);
    }

    setIsStepAddDialogOpenState(false);
  };

  const handleCloseStepUpdateDialog = (result?: Step) => {
    if (!!result) {
      setStepsState(
        stepsState.map((s) => (s.id === result.id ? { ...s, ...result } : s))
      );
    }

    setStepToUpdateState(undefined);
  };

  const handleCloseStepDeleteDialog = (result?: number) => {
    if (!!result) {
      setStepsState(stepsState.filter((s) => s.id !== result));
    }

    setStepToDeleteState(undefined);
  };

  const handleSendStepInDirection = async (
    event: React.ChangeEvent<{}>,
    index: number,
    direction: "upward" | "downward"
  ) => {
    event.stopPropagation();
    setActiveStep(null);
    const newIndex = direction === "upward" ? index - 1 : index + 1;
    let steps = stepsState;

    if (
      (direction === "upward" && newIndex >= 0) ||
      (direction === "downward" && newIndex <= steps.length - 1)
    ) {
      const response = await api.stepsMove(steps[index].id, {
        order: newIndex,
      });

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({
            variant: "success",
          })
        );

        steps = move(steps, index, newIndex).map((s, i) => ({
          ...s,
          order: i,
        }));

        setStepsState(steps);
      }
    }
  };

  useEffect(() => {
    if (!!questionnaireId) {
      getSteps(Number(questionnaireId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  return (
    <>
      <StepAddDialogComponent
        open={isStepAddDialogOpenState}
        handleClose={handleCloseStepAddDialog}
        questionnaireId={Number(questionnaireId)}
      />
      <StepUpdateDialogComponent
        open={!!stepToUpdateState}
        step={stepToUpdateState}
        handleClose={handleCloseStepUpdateDialog}
      />
      <StepDeleteDialogComponent
        open={!!stepToDeleteState}
        step={stepToDeleteState}
        handleClose={handleCloseStepDeleteDialog}
      />
      <div className="steps-container">
        {isStepsLoadingState ? (
          <Paper className="steps-container__paper">
            <Loader loadingText={commonTranslations("loading")} />
          </Paper>
        ) : (
          <>
            <div className="steps-container__steps">
              {stepsState.map((step: Step, index: number) => (
                <StepComponent
                  key={index}
                  step={step}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  setHasError={setHasError}
                  setStepToUpdate={setStepToUpdateState}
                  setStepToDelete={setStepToDeleteState}
                  sendValueUpward={(event: React.ChangeEvent<{}>) =>
                    handleSendStepInDirection(event, index, "upward")
                  }
                  sendValueDownward={(event: React.ChangeEvent<{}>) =>
                    handleSendStepInDirection(event, index, "downward")
                  }
                  isUpwardDisabled={index === 0}
                  isDownwardDisabled={index === stepsState.length - 1}
                  setStepMaxScore={(score) => {
                    if (score) {
                      setStepsState(
                        stepsState.map((s) =>
                          s.id === step.id ? { ...s, maxScore: score } : s
                        )
                      );
                    }
                  }}
                />
              ))}
            </div>
            <div className="steps-container__fab-container">
              <Fab
                color="secondary"
                aria-label={questionnaireTranslations("addStep")}
                onClick={() => setIsStepAddDialogOpenState(true)}
              >
                <AddIcon />
              </Fab>
            </div>
          </>
        )}
      </div>
    </>
  );
}
