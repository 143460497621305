import { MODE } from "./constants";

export interface GenerateLinkProps {
  uuid: string;
  onSuccess: () => void;
  onError: (error: any) => void;
}

export async function generateLink({ uuid, onSuccess, onError }: GenerateLinkProps): Promise<void> {
  try {
    let hostname = window.location.hostname;
    
    if (MODE === 'dev') {
      hostname += `:${window.location.port}`;
    }

    await navigator.clipboard.writeText(`${hostname}/invite/${uuid}`);
    onSuccess()
  } catch (e) {
    onError(e);
  }
}