import React, { PropsWithChildren, createContext, useContext } from "react";
import { User } from "../../../models/user/user";
import { API_URL, CURRENT_USER_ROLE_KEY } from "../../../lib/constants";
import { LoginDto } from "../../../models/user/loginDto";
import { Team } from "../../../models/team/team";
import { TeamAddDto } from "../../../models/team/teamAddDto";
import { TeamUpdateDto } from "../../../models/team/teamUpdateDto";
import { Questionnaire } from "../../../models/questionnaire/questionnaire";
import { Category } from "../../../models/category/category";
import { QuestionnaireUpdateDto } from "../../../models/questionnaire/questionnaireUpdateDto";
import { CategoryAddDto } from "../../../models/category/categoryAddDto";
import { CategoryUpdateDto } from "../../../models/category/categoryUpdateDto";
import { StepAddDto } from "../../../models/step/stepAddDto";
import { Step } from "../../../models/step/step";
import { StepUpdateDto } from "../../../models/step/stepUpdateDto";
import { Question } from "../../../models/question/question";
import { QuestionnaireAddDto } from "../../../models/questionnaire/questionnaireAddDto";
import { Scan } from "../../../models/scan/scan";
import { ScanAddDto } from "../../../models/scan/scanAddDto";
import { ScanValue } from "../../../models/scan-value/scanValue";
import { UserAddDto } from "../../../models/user/userAddDto";
import { UserChangePasswordDto } from "../../../models/user/userChangePasswordDto";
import { UserUpdateDto } from "../../../models/user/userUpdateDto";
import { QuestionnaireRole } from "../../../models/questionnaire-role/questionnaireRole";
import { QuestionnaireRoleUpdateDto } from "../../../models/questionnaire/questionnaireRoleUpdateDto";
import { QuestionnaireRoleAddDto } from "../../../models/questionnaire/questionnaireRoleAddDto";
import { UserRegisterDto } from "../../../models/user/userRegisterDto";
import { UserRole } from "../../../models/user-role/userRole";
import { TeamQuestionnaire } from "../../../models/questionnaire/teamQuestionnaire";
import { RevisionWithCycles } from "../../dashboard/pages/questionnaire/questionnaire-statistic-page/revisionWithCycles";
import i18n from "../../../i18n";

export interface ApiResponse<T = any> {
  success: boolean;
  message: string;
  data: T;
}

export class Api {
  private async call<T = any>(
    path: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    body?: any
  ): Promise<ApiResponse<T>> {
    const currentUserRole = localStorage.getItem(CURRENT_USER_ROLE_KEY);
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
      "Accept-Language": i18n.language,
    };

    if (currentUserRole) {
      headers["X-Role"] = currentUserRole;
    }

    try {
      const res = await fetch(`${API_URL}${path}`, {
        method,
        credentials: "include",
        headers,
        body: JSON.stringify(body),
      });

      return res.json() as unknown as ApiResponse<T>;
    } catch (e) {
      console.error(e);
      return undefined as any;
    }
  }

  // AUTH CALLS
  login(loginDto: LoginDto): Promise<ApiResponse<User>> {
    return this.call<User>("/auth/login", "POST", loginDto);
  }

  async logout(): Promise<void> {
    await fetch(`${API_URL}/auth/logout`, {
      method: "GET",
      credentials: "include",
    });
  }

  // USER CALLS
  users(): Promise<ApiResponse<User[]>> {
    return this.call<User[]>("/users", "GET");
  }

  usersRegister(userAddDto: UserRegisterDto): Promise<ApiResponse<User>> {
    return this.call<User>("/users/register", "POST", userAddDto);
  }

  usersAdd(userAddDto: UserAddDto): Promise<ApiResponse<User>> {
    return this.call<User>("/users", "POST", userAddDto);
  }

  usersUpdate(userUpdateDto: UserUpdateDto): Promise<ApiResponse<User>> {
    return this.call<User>(`/users/${userUpdateDto.id}`, "PUT", userUpdateDto);
  }

  usersMe(): Promise<ApiResponse<User>> {
    return this.call<User>("/users/me", "GET");
  }

  usersChangePassword(
    userId: number,
    changePasswordDto: UserChangePasswordDto
  ): Promise<ApiResponse> {
    return this.call(
      `/users/${userId}/change-password`,
      "PUT",
      changePasswordDto
    );
  }

  usersFilter(questionnaireIds: number[]): Promise<ApiResponse<User[]>> {
    return this.call<User[]>("/users/filter", "POST", { questionnaireIds });
  }

  usersDelete(userId: number): Promise<ApiResponse> {
    return this.call(`/users/${userId}`, "DELETE");
  }

  usersByTeamId(teamId: string): Promise<ApiResponse<User[]>> {
    return this.call(`/users/team/${teamId}`, "GET");
  }

  // TEAMS CALLS
  teams(): Promise<ApiResponse<Team[]>> {
    return this.call<Team[]>("/teams", "GET");
  }

  teamById(teamId: number): Promise<ApiResponse<Team>> {
    return this.call<Team>(`/teams/${teamId}`, "GET");
  }

  teamsAdd(teamAddDto: TeamAddDto): Promise<ApiResponse<Team>> {
    return this.call<Team>("/teams", "POST", teamAddDto);
  }

  teamsUpdate(teamUpdateDto: TeamUpdateDto): Promise<ApiResponse<Team>> {
    return this.call<Team>(`/teams/${teamUpdateDto.id}`, "PUT", teamUpdateDto);
  }

  teamsDelete(teamId: number): Promise<ApiResponse> {
    return this.call(`/teams/${teamId}`, "DELETE");
  }

  teamsFilter(): Promise<ApiResponse<Team[]>> {
    return this.call<Team[]>(`/teams/data/filter`, "GET");
  }

  teamsByQuestionnaireId(
    questionnaireId: number
  ): Promise<ApiResponse<Team[]>> {
    return this.call<Team[]>(`/teams/questionnaire/${questionnaireId}`, "GET");
  }

  // TEAMS QUESTIONNAIRES CALLS
  assignTeamToQuestionnaire(
    questionnaireId: number,
    teamId: number
  ): Promise<ApiResponse> {
    return this.call(`/teams/questionnaires/assign`, "POST", {
      teamId,
      questionnaireId,
    });
  }

  unassignTeamFromQuestionnaire(
    questionnaireId: number,
    teamId: number
  ): Promise<ApiResponse<Team>> {
    return this.call<Team>(`/teams/questionnaires/unassign`, "DELETE", {
      teamId,
      questionnaireId,
    });
  }

  getInvite(uuid: string): Promise<ApiResponse<TeamQuestionnaire>> {
    return this.call<TeamQuestionnaire>(
      `/teams/questionnaires/invite/${uuid}`,
      "GET"
    );
  }

  acceptInvite(uuid: string): Promise<ApiResponse<UserRole>> {
    return this.call<UserRole>(`/teams/questionnaires/accept/invite`, "POST", {
      uuid,
    });
  }

  updateTeamQuestionnaireCycle(
    questionnaireId: number,
    teamId: number,
    cycle: number
  ): Promise<ApiResponse<boolean>> {
    return this.call<boolean>(`/teams/questionnaires/cycle`, "PUT", {
      questionnaireId,
      teamId,
      cycle,
    });
  }

  // QUESTIONNAIRE ROLE CALLS
  questionnaireRolesByQuestionnaireId(
    questionnaireId: number
  ): Promise<ApiResponse<Array<QuestionnaireRole>>> {
    return this.call(`/questionnaires/${questionnaireId}/roles`, "GET");
  }

  questionnaireRolesByQuestionnaireIdWithTotalScans(
    questionnaireId: number,
    cycle: number
  ): Promise<ApiResponse<Array<QuestionnaireRole & { totalScans?: number }>>> {
    return this.call(
      `/questionnaires/${questionnaireId}/roles/cycle/${cycle}`,
      "GET"
    );
  }

  questionnaireRoleAdd(
    questionnaireRoleAddDto: QuestionnaireRoleAddDto
  ): Promise<ApiResponse<QuestionnaireRole>> {
    return this.call(
      `/questionnaires/${questionnaireRoleAddDto.questionnaireId}/roles`,
      "POST",
      questionnaireRoleAddDto
    );
  }

  questionnaireRoleUpdate(
    questionnaireRoleUpdateDto: QuestionnaireRoleUpdateDto
  ): Promise<ApiResponse<QuestionnaireRole>> {
    return this.call<QuestionnaireRole>(
      `/questionnaires/${questionnaireRoleUpdateDto.questionnaireId}/roles/${questionnaireRoleUpdateDto.id}`,
      "PUT",
      questionnaireRoleUpdateDto
    );
  }

  questionnaireRoleDelete(
    questionnaireId: string,
    questionnaireRoleId: string
  ): Promise<ApiResponse> {
    return this.call(
      `/questionnaires/${questionnaireId}/roles/${questionnaireRoleId}`,
      "DELETE"
    );
  }

  // QUESTIONNAIRES CALLS
  questionnaires(): Promise<ApiResponse<Questionnaire[]>> {
    return this.call<Questionnaire[]>("/questionnaires", "GET");
  }

  questionnairesAdd(
    questionnaireAddDto: QuestionnaireAddDto
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      "/questionnaires",
      "POST",
      questionnaireAddDto
    );
  }

  questionnairesCopy(
    questionnaireId: number,
    copyDto: { archiveSource: boolean; newQuestionnaireName?: string }
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/${questionnaireId}/copy`,
      "PUT",
      copyDto
    );
  }

  questionnairesNewCycle(questionnaireId: number): Promise<ApiResponse> {
    return this.call<Questionnaire>(
      `/questionnaires/${questionnaireId}/cycle`,
      "PUT"
    );
  }

  questionnairesUpdate(
    questionnaireUpdateDto: QuestionnaireUpdateDto
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/${questionnaireUpdateDto.id}`,
      "PUT",
      questionnaireUpdateDto
    );
  }

  questionnairesDelete(questionnaireId: number): Promise<ApiResponse> {
    return this.call(`/questionnaires/${questionnaireId}`, "DELETE");
  }

  questionnairesPublished(): Promise<ApiResponse<Questionnaire[]>> {
    return this.call<Questionnaire[]>("/questionnaires/published", "GET");
  }

  questionnairesSetPublished(
    questionnaireId: number,
    published: boolean
  ): Promise<ApiResponse> {
    return this.call(`/questionnaires/${questionnaireId}/published`, "PUT", {
      published,
    });
  }

  questionnaireById(
    questionnaireId: number
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/${questionnaireId}`,
      "GET"
    );
  }

  questionnaireByUUID(uuid: string): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(`/questionnaires/uuid/${uuid}`, "GET");
  }

  questionnairesCheck(
    questionnaireId: number
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/${questionnaireId}/check`,
      "GET"
    );
  }

  questionnairesFilter(
    teamIds: number[]
  ): Promise<ApiResponse<Questionnaire[]>> {
    return this.call<Questionnaire[]>("/questionnaires/filter", "POST", {
      teamIds,
    });
  }

  questionnaireByInviteUuid(
    inviteUuid: string
  ): Promise<ApiResponse<Questionnaire>> {
    return this.call<Questionnaire>(
      `/questionnaires/invite/${inviteUuid}`,
      "GET"
    );
  }

  questionnaireVersionsById(
    questionnaireId: number
  ): Promise<ApiResponse<RevisionWithCycles[]>> {
    return this.call<RevisionWithCycles[]>(
      `/questionnaires/versions/${questionnaireId}`,
      "GET"
    );
  }

  // CATEGORIES CALLS
  categoriesAdd(
    categoryAddDto: CategoryAddDto
  ): Promise<ApiResponse<Category>> {
    return this.call<Category>("/categories", "POST", categoryAddDto);
  }

  categoryById(categoryId: number): Promise<ApiResponse<Category>> {
    return this.call<Category>(`/categories/${categoryId}`, "GET");
  }

  categoriesUpdate(
    categoryUpdateDto: CategoryUpdateDto
  ): Promise<ApiResponse<Category>> {
    return this.call<Category>(
      `/categories/${categoryUpdateDto.id}`,
      "PUT",
      categoryUpdateDto
    );
  }

  categoriesDelete(categoryId: number): Promise<ApiResponse> {
    return this.call(`/categories/${categoryId}`, "DELETE");
  }

  categoriesByQuestionnaireId(
    questionnaireId: number
  ): Promise<ApiResponse<Category[]>> {
    return this.call<Category[]>(
      `/categories/questionnaire/${questionnaireId}`,
      "GET"
    );
  }

  // STEPS CALLS
  stepsAdd(stepAddDto: StepAddDto): Promise<ApiResponse<Step>> {
    return this.call<Step>("/steps", "POST", stepAddDto);
  }

  stepsUpdate(stepUpdateDto: StepUpdateDto): Promise<ApiResponse<Step>> {
    return this.call<Step>(`/steps/${stepUpdateDto.id}`, "PUT", stepUpdateDto);
  }

  stepsDelete(stepId: number): Promise<ApiResponse> {
    return this.call(`/steps/${stepId}`, "DELETE");
  }

  stepsByQuestionnaireId(
    questionnaireId: number
  ): Promise<ApiResponse<Step[]>> {
    return this.call<Step[]>(`/steps/questionnaire/${questionnaireId}`, "GET");
  }

  stepsMove(stepId: number, moveDto: { order: number }): Promise<ApiResponse> {
    return this.call(`/steps/${stepId}/move`, "PUT", moveDto);
  }

  // QUESTIONS CALLS
  questionsAdd(questionsAddDto: {
    stepId: number;
    questions: Question[];
    maxScore: number;
  }): Promise<ApiResponse<Question[]>> {
    return this.call<Question[]>("/questions", "POST", questionsAddDto);
  }

  questionsByStepId(stepId: number): Promise<ApiResponse<Question[]>> {
    return this.call<Question[]>(`/questions/step/${stepId}`, "GET");
  }

  // SCANS CALLS
  scans(): Promise<ApiResponse<Scan[]>> {
    return this.call<Scan[]>("/scans", "GET");
  }

  scansUUID(uuid: string): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(`/scans/${uuid}`, "GET");
  }

  scansAdd(scanAddDto: ScanAddDto): Promise<ApiResponse<Scan>> {
    return this.call<Scan>("/scans", "POST", scanAddDto);
  }

  scansDelete(scanId: number): Promise<ApiResponse> {
    return this.call(`/scans/${scanId}`, "DELETE");
  }

  scansFinish(scanId: number, scan: Scan): Promise<ApiResponse> {
    return this.call(`/scans/${scanId}/finish`, "PUT", scan);
  }

  scansDrafts(): Promise<ApiResponse<Scan[]>> {
    return this.call<Scan[]>(`/scans/drafts`, "GET");
  }

  scansHistory(): Promise<ApiResponse<Scan[]>> {
    return this.call<Scan[]>("/scans/history", "GET");
  }

  scansResult(uuid: string): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(`/scans/${uuid}/result`, "GET");
  }

  scansResultPrevious(uuid: string): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(`/scans/${uuid}/result/previous`, "GET");
  }

  scansAverageByQuestionnaireRoleId(
    uuid: string,
    questionnaireRoleId: number | null
  ): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(
      `/scans/${uuid}/result/average/questionnaire-roles/${questionnaireRoleId}`,
      "GET"
    );
  }

  scansAverageByQuestionnaireIdAndQuestionnaireRoleId(
    questionnaireId: number,
    cycle: number,
    questionnaireRoleId: number
  ): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(
      `/scans/id/${questionnaireId}/result/average/questionnaire-roles/${questionnaireRoleId}/cycle/${cycle}`,
      "GET"
    );
  }

  scansAverageByEntireTeam(uuid: string): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(`/scans/${uuid}/result/average/entire-team`, "GET");
  }

  scansAverageByEntireTeamAndQuestionnaireId(
    questionnaireId: number,
    cycle: number
  ): Promise<ApiResponse<Scan>> {
    return this.call<Scan>(
      `/scans/id/${questionnaireId}/result/average/entire-team/cycle/${cycle}`,
      "GET"
    );
  }

  // SCAN-VALUES CALLS
  scanValuesUUID(uuid: string): Promise<ApiResponse<ScanValue[]>> {
    return this.call<ScanValue[]>(`/scan-values/scan/${uuid}`, "GET");
  }

  scanValuesSave(saveDto: {
    scanId: number;
    scanValues: ScanValue[];
  }): Promise<ApiResponse<ScanValue[]>> {
    return this.call<ScanValue[]>("/scan-values", "POST", saveDto);
  }
}

const ApiStateContext = createContext<Api>(null as any);

export const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ApiStateContext.Provider value={new Api()}>
      {children}
    </ApiStateContext.Provider>
  );
};

export const useApi = () => {
  return useContext(ApiStateContext);
};
