import React from "react";
import { Tooltip } from "@mui/material";
import { ScanValue } from "../../../models/scan-value/scanValue";
import { QuestionType } from "../../../models/question/questionType";
import { SelectTypeComponent } from "./type/select-type/SelectType.component";
import { OpenTypeComponent } from "./type/open-type/OpenType.component";
import { PolarTypeComponent } from "./type/polar-type/PolarType.component";
import { LikertScaleTypeComponent } from "./type/likert-scale-type/LikertScaleType.component";
import { Question } from "../../../models/question/question";
import InfoIcon from "@mui/icons-material/Info";
import "./Question.component.sass";
import { SelectData } from "../../../models/question/selectData";
import { OpenData } from "../../../models/question/openData";

export interface QuestionComponentProps {
  editingMode: boolean;
  disabled?: boolean;
  question?: Question;
  scanValue?: ScanValue;
  setScanValue: (value: ScanValue) => void;
}

export function QuestionComponent({
  editingMode,
  question,
  scanValue,
  setScanValue,
  disabled = false,
}: QuestionComponentProps) {
  const q =
    !!scanValue && !!scanValue.question ? scanValue.question : question!;

  const renderHint = () => {
    return (
      <Tooltip title={q.hint!} placement="top">
        <InfoIcon fontSize="small" color="secondary" />
      </Tooltip>
    );
  };

  const renderQuestion = () => {
    switch (q.type) {
      case QuestionType.LIKERT_SCALE:
        return (
          <LikertScaleTypeComponent
            disabled={disabled}
            question={question}
            scanValue={scanValue}
            setScanValue={setScanValue}
          />
        );
      case QuestionType.OPEN:
        return (
          <OpenTypeComponent
            disabled={disabled}
            question={question as Question<OpenData>}
            scanValue={scanValue as ScanValue<OpenData>}
            setScanValue={setScanValue}
          />
        );
      case QuestionType.SELECT:
        return (
          <SelectTypeComponent
            disabled={disabled}
            question={question as Question<SelectData>}
            scanValue={scanValue as ScanValue<SelectData>}
            setScanValue={setScanValue}
          />
        );
      case QuestionType.POLAR:
        return (
          <PolarTypeComponent
            disabled={disabled}
            question={question}
            scanValue={scanValue}
            setScanValue={setScanValue}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="question-container">
      <div className="question-container__header">
        <span className="question-container__header__title">
          {editingMode && q.id ? `${q.id}. ` : ""}
          {q.label}
          {q.required && "*"}
        </span>
        {!!q.hint && renderHint()}
      </div>
      {renderQuestion()}
    </div>
  );
}
