import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { QuestionnaireRole } from "../../../../../../models/questionnaire-role/questionnaireRole";
import { Loader } from "../../../../../common/loader/Loader.component";
import { useApi } from "../../../../../common/providers/Api.provider";
import AddIcon from "@mui/icons-material/Add";
import { RoleListItemComponent } from "./role-list-item/RoleListItem.component";
import "./Roles.component.sass";
import { QuestionnaireRoleAddDialogComponent } from "./dialog/questionnaire-role-add-dialog/QuestionnaireRoleAddDialog.component";
import { QuestionnaireRoleUpdateDialogComponent } from "./dialog/questionnaire-role-update-dialog/QuestionnaireRoleUpdateDialog.component";
import { QuestionnaireRoleDeleteDialogComponent } from "./dialog/questionnaire-role-delete-dialog/QuestionnaireRoleDeleteDialog.component";
import { Error } from "../../../../../common/error/Error.component";
import { useTranslation } from "react-i18next";

export interface RolesComponentProps {
  questionnaireId: string | undefined;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function RolesComponent({
  questionnaireId,
  setHasError,
}: RolesComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "roles",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const [rolesState, setRolesState] = useState<QuestionnaireRole[]>([]);

  const [isRolesLoadingState, setIsRolesLoadingState] =
    useState<boolean>(false);

  const [
    isQuestionnaireRoleAddDialogOpenState,
    setIsQuestionnaireRoleAddDialogOpenState,
  ] = useState<boolean>(false);
  const [questionnaireRoleToUpdateState, setQuestionnaireRoleToUpdateState] =
    useState<QuestionnaireRole | undefined>(undefined);
  const [questionnaireRoleToDeleteState, setQuestionnaireRoleToDeleteState] =
    useState<QuestionnaireRole | undefined>(undefined);

  const getQuestionnaireRoles = async (questionnaireId: string) => {
    try {
      setIsRolesLoadingState(true);

      const response = await api.questionnaireRolesByQuestionnaireId(
        Number(questionnaireId)
      );

      setRolesState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsRolesLoadingState(false);
    }
  };

  const renderRows = () => {
    if (rolesState.length === 0) {
      return (
        <TableRow className="empty-row">
          <TableCell colSpan={3}>
            <span>{questionnaireTranslations("noRoles")}</span>
          </TableCell>
        </TableRow>
      );
    }

    return rolesState.map((row, index) => (
      <RoleListItemComponent
        key={index}
        questionnaireRole={row}
        setQuestionnaireRoleToDelete={setQuestionnaireRoleToDeleteState}
        setQuestionnaireRoleToUpdate={setQuestionnaireRoleToUpdateState}
      />
    ));
  };

  const handleCloseQuestionnaireRoleAddDialog = (
    result?: QuestionnaireRole
  ) => {
    if (!!result) {
      setRolesState([...rolesState, result]);
    }

    setIsQuestionnaireRoleAddDialogOpenState(false);
  };

  const handleCloseQuestionnaireRoleUpdateDialog = (
    result?: QuestionnaireRole
  ) => {
    if (!!result) {
      setRolesState(
        rolesState.map((role) => (result.id === role.id ? result : role))
      );
    }

    setQuestionnaireRoleToUpdateState(undefined);
  };

  const handleCloseQuestionnaireRoleDeleteDialog = (result?: number) => {
    if (!!result) {
      setRolesState(rolesState.filter((role) => role.id !== result));
    }

    setQuestionnaireRoleToDeleteState(undefined);
  };

  useEffect(() => {
    if (questionnaireId) {
      getQuestionnaireRoles(questionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  if (!questionnaireId) {
    return <Error />;
  }

  return (
    <Paper className="roles-container">
      {isRolesLoadingState ? (
        <Loader loadingText={commonTranslations("loading")} />
      ) : (
        <>
          <QuestionnaireRoleAddDialogComponent
            open={isQuestionnaireRoleAddDialogOpenState}
            questionnaireId={questionnaireId}
            handleClose={handleCloseQuestionnaireRoleAddDialog}
          />
          <QuestionnaireRoleUpdateDialogComponent
            open={!!questionnaireRoleToUpdateState}
            questionnaireId={questionnaireId}
            questionnaireRole={questionnaireRoleToUpdateState}
            handleClose={handleCloseQuestionnaireRoleUpdateDialog}
          />
          <QuestionnaireRoleDeleteDialogComponent
            open={!!questionnaireRoleToDeleteState}
            questionnaireId={questionnaireId}
            questionnaireRole={questionnaireRoleToDeleteState}
            handleClose={handleCloseQuestionnaireRoleDeleteDialog}
          />
          <div className="roles-container__title-container">
            <span className="title">{questionnaireTranslations("title")}</span>
            <Button
              className="button"
              variant="contained"
              color="secondary"
              onClick={() => setIsQuestionnaireRoleAddDialogOpenState(true)}
            >
              <AddIcon />
              {questionnaireTranslations("addRole")}
            </Button>
          </div>
          <TableContainer className="roles-list-container__table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{questionnaireTranslations("table.id")}</TableCell>
                  <TableCell>
                    {questionnaireTranslations("table.name")}
                  </TableCell>
                  <TableCell>
                    {questionnaireTranslations("table.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderRows()}</TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
}
