import React, { useState } from "react";
import { User } from "../../../../../../models/user/user";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface UserDeleteDialogComponentProps {
  open: boolean;
  user?: User;
  handleClose: (result?: number) => void;
}

export function UserDeleteDialogComponent({
  open,
  user,
  handleClose,
}: UserDeleteDialogComponentProps) {
  const { t: userTranslations } = useTranslation("users-page", {
    keyPrefix: "dialogs.delete",
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [emailState, setEmailState] = useState<string>("");

  const handleDelete = async (user?: User) => {
    if (!user || !user.id || !user.email || user.email !== emailState) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.usersDelete(user.id);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(user.id);
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setEmailState("");
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!user ? (
          <Skeleton variant="text" />
        ) : (
          userTranslations("title", { email: user.email })
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!user ? (
            <Skeleton variant="rectangular" />
          ) : (
            userTranslations("description", { email: user.email })
          )}
        </DialogContentText>
        {!user ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="email"
            label={userTranslations("label")}
            type="email"
            value={emailState}
            onChange={(event) => setEmailState(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!user ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            {commonTranslations("cancel")}
          </Button>
        )}
        {!user ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button
            disabled={
              !user || !user.id || !user.email || user.email !== emailState
            }
            onClick={() => handleDelete(user)}
            color="primary"
          >
            {commonTranslations("delete")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
