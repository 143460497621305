import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { DashboardComponent } from "./dashboard/Dashboard.component";
import { LoginComponent } from "./login/Login.component";
import { useUser } from "./common/providers/User.provider";
import { createTheme, ThemeProvider } from "@mui/material";
import { RegisterComponent } from "./register/Register.component";
import { InviteComponent } from "./invite/Invite.component";
import { RequireAuth } from "./common/require-auth/RequireAuth.component";
import { Role } from "../models/user-role/role";
import { DataListPageComponent } from "./dashboard/pages/data/data-list-page/DataListPage.component";
import { HomePageComponent } from "./dashboard/pages/home/HomePage.component";
import { QuestionnaireDetailPageComponent } from "./dashboard/pages/questionnaire/questionnaire-detail-page/QuestionnaireDetailPage.component";
import { QuestionnaireListPageComponent } from "./dashboard/pages/questionnaire/questionnaire-list-page/QuestionnaireListPage.component";
import { QuestionnaireStatisticPageComponent } from "./dashboard/pages/questionnaire/questionnaire-statistic-page/QuestionnaireStatisticPage.component";
import { ScanDetailPageComponent } from "./dashboard/pages/scan/scan-detail-page/ScanDetailPage.component";
import { ScanListPageComponent } from "./dashboard/pages/scan/scan-list-page/ScanListPage.component";
import { ScanResultPageComponent } from "./dashboard/pages/scan/scan-result-page/ScanResultPage.component";
import { TeamDetailPageComponent } from "./dashboard/pages/team/team-detail-page/TeamDetailPage.component";
import { TeamListPageComponent } from "./dashboard/pages/team/team-list-page/TeamListPage.component";
import { UserListPageComponent } from "./dashboard/pages/user/user-list-page/UserListPage.component";

const theme = createTheme({
  palette: {
    primary: {
      main: "#663366",
    },
    secondary: {
      main: "#E5007D",
    },
  },
});

export default function RootComponent() {
  const { user, userRole: currentUserRole } = useUser();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="login" element={<LoginComponent />} />
          <Route path="register" element={<RegisterComponent />} />
          <Route path="invite/:uuid" element={<InviteComponent />} />
          <Route
            path="/"
            element={!!user ? <DashboardComponent /> : <Navigate to="login" />}
          >
            <Route
              path="/"
              element={
                <RequireAuth
                  authorizedRoles={[
                    Role.Admin,
                    Role.Researcher,
                    Role.TeamManager,
                    Role.User,
                  ]}
                  currentUserRole={currentUserRole}
                  redirectTo="login"
                >
                  <HomePageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="questionnaires"
              element={
                <RequireAuth
                  authorizedRoles={[
                    Role.Admin,
                    Role.Researcher,
                    Role.TeamManager,
                  ]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <QuestionnaireListPageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="questionnaires/:questionnaireId"
              element={
                <RequireAuth
                  authorizedRoles={[Role.Admin, Role.TeamManager]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <QuestionnaireDetailPageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="questionnaires/:questionnaireId/statistics"
              element={
                <RequireAuth
                  authorizedRoles={[Role.Admin, Role.TeamManager]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <QuestionnaireStatisticPageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="scans"
              element={
                <RequireAuth
                  authorizedRoles={[
                    Role.Admin,
                    Role.Researcher,
                    Role.TeamManager,
                    Role.User,
                  ]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <ScanListPageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="scans/:scanUuid"
              element={
                <RequireAuth
                  authorizedRoles={[Role.Admin, Role.TeamManager, Role.User]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <ScanDetailPageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="scans/:scanUuid/result"
              element={
                <RequireAuth
                  authorizedRoles={[Role.Admin, Role.TeamManager, Role.User]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <ScanResultPageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="users"
              element={
                <RequireAuth
                  authorizedRoles={[Role.Admin]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <UserListPageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="teams"
              element={
                <RequireAuth
                  authorizedRoles={[Role.Admin]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <TeamListPageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="teams/:teamId"
              element={
                <RequireAuth
                  authorizedRoles={[Role.Admin, Role.TeamManager]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <TeamDetailPageComponent />
                </RequireAuth>
              }
            />
            <Route
              path="data"
              element={
                <RequireAuth
                  authorizedRoles={[Role.Admin, Role.Researcher]}
                  currentUserRole={currentUserRole}
                  redirectTo="/"
                >
                  <DataListPageComponent />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
