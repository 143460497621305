import React from "react";
import { Navigate } from "react-router-dom";
import { Role } from "../../../models/user-role/role";
import { UserRole } from "../../../models/user-role/userRole";

interface RequireAuthProps {
  children: any;
  currentUserRole: UserRole;
  authorizedRoles: Array<Role>;
  redirectTo: string;
}

export function RequireAuth({
  children,
  currentUserRole,
  authorizedRoles,
  redirectTo,
}: RequireAuthProps) {
  return authorizedRoles.some((role) => role === currentUserRole.role) ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
}
