import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { Category } from "../../../../../../../models/category/category";
import { CategoryUpdateDto } from "../../../../../../../models/category/categoryUpdateDto";
import { Skeleton } from "@mui/material";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface CategoryUpdateDialogComponentProps {
  open: boolean;
  category?: Category;
  handleClose: (result?: Category) => void;
}

export function CategoryUpdateDialogComponent({
  open,
  category,
  handleClose,
}: CategoryUpdateDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "categories.dialogs.edit",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [categoryUpdateDtoState, setCategoryUpdateDtoState] =
    useState<CategoryUpdateDto | null>(null);

  const handleUpdate = async (categoryUpdateDto: CategoryUpdateDto) => {
    if (
      !categoryUpdateDto.id ||
      !categoryUpdateDto.name.length ||
      !categoryUpdateDto.scale
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.categoriesUpdate(categoryUpdateDto);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setCategoryUpdateDtoState(null);
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    if (!!category) {
      setCategoryUpdateDtoState(category);
    }
  }, [category]);

  return (
    <Dialog open={open} className="category-update-dialog-container">
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!categoryUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          questionnaireTranslations("title")
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!categoryUpdateDtoState ? (
            <Skeleton variant="rectangular" />
          ) : (
            questionnaireTranslations("description")
          )}
        </DialogContentText>
        {!categoryUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label={questionnaireTranslations("name")}
            type="text"
            value={categoryUpdateDtoState.name}
            onChange={(event) =>
              setCategoryUpdateDtoState({
                ...categoryUpdateDtoState,
                name: event.target.value,
              })
            }
          />
        )}
        {!categoryUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="description"
            label={questionnaireTranslations("scale")}
            type="number"
            inputProps={{
              min: 0,
            }}
            value={categoryUpdateDtoState.scale}
            onChange={(event) =>
              setCategoryUpdateDtoState({
                ...categoryUpdateDtoState,
                scale: Number(event.target.value),
              })
            }
          />
        )}
        {!categoryUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={categoryUpdateDtoState.showInResult}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCategoryUpdateDtoState({
                      ...categoryUpdateDtoState,
                      showInResult: event.target.checked,
                    });
                  }}
                />
              }
              label={questionnaireTranslations("showInScanResult")}
            />
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        {!categoryUpdateDtoState ? (
          <Skeleton variant="rectangular" />
        ) : (
          <>
            <Button onClick={() => handleClose(undefined)} color="secondary">
              {commonTranslations("cancel")}
            </Button>
            <Button
              onClick={() => handleUpdate(categoryUpdateDtoState)}
              color="primary"
            >
              {commonTranslations("edit")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
