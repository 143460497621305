import React, { useState, useEffect } from "react";
import { Questionnaire } from "../../../../../../../models/questionnaire/questionnaire";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface QuestionnaireCopyDialogComponentProps {
  open: boolean;
  questionnaire?: Questionnaire;
  handleClose: (result?: number) => void;
}

export function QuestionnaireCopyDialogComponent({
  open,
  questionnaire,
  handleClose,
}: QuestionnaireCopyDialogComponentProps) {
  const { t: questionnairesTranslations } = useTranslation(
    "questionnaires-page",
    {
      keyPrefix: "dialogs.copy",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [newQuestionnaireNameState, setNewQuestionnaireNameState] =
    useState<string>("");

  const handleCopy = async (questionnaire?: Questionnaire) => {
    if (!questionnaire || !questionnaire.id) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnairesCopy(questionnaire.id, {
        archiveSource: false,
        newQuestionnaireName: newQuestionnaireNameState,
      });

      handleClose(response.data.id);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    if (open && questionnaire) {
      setNewQuestionnaireNameState(questionnaire.name);
    }
  }, [open, questionnaire]);

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          questionnairesTranslations("title")
        )}
      </DialogTitle>
      <DialogContent>
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <p>
            {questionnairesTranslations("description", {
              name: questionnaire.name,
            })}
          </p>
        )}
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label={questionnairesTranslations("nameInput")}
            type="text"
            value={newQuestionnaireNameState}
            onChange={(event) =>
              setNewQuestionnaireNameState(event.target.value)
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            {commonTranslations("cancel")}
          </Button>
        )}
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleCopy(questionnaire)} color="primary">
            {questionnairesTranslations("copy")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
