import React from "react";
import { TextField } from "@mui/material";
import { PolarData } from "../../../../../../../../models/question/polarData";
import { Question } from "../../../../../../../../models/question/question";
import { useTranslation } from "react-i18next";

export interface PolarInputComponentProps {
  value: Question<PolarData>;
  setValue: (value: Question<PolarData>) => void;
}

export function PolarInputComponent({
  value,
  setValue,
}: PolarInputComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "steps.questions.polar",
      useSuspense: false,
    }
  );

  return (
    <div className="polar-input">
      <TextField
        fullWidth
        required
        variant="outlined"
        label={questionnaireTranslations("score")}
        color="primary"
        type="number"
        className="form-control"
        value={value.data.score || ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setValue({ ...value, data: { score: Number(event.target.value) } })
        }
      />
    </div>
  );
}
