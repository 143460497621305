import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { Team } from "../../../../../../../models/team/team";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface TeamDeleteDialogComponentProps {
  open: boolean;
  team?: Team;
  handleClose: (result?: number) => void;
}

export function TeamDeleteDialogComponent({
  open,
  team,
  handleClose,
}: TeamDeleteDialogComponentProps) {
  const { t: teamsTranslations } = useTranslation("teams-page", {
    useSuspense: false,
    keyPrefix: "dialog.delete",
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [nameState, setNameState] = useState<string>("");

  const handleDelete = async (team?: Team) => {
    if (!team || !team.id || team.name !== nameState) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.teamsDelete(team.id);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(team.id);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({ variant: "error" })
      );
    } finally {
      setNameState("");
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!team ? (
          <Skeleton variant="text" />
        ) : (
          teamsTranslations("title", { name: team.name })
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!team ? (
            <Skeleton variant="rectangular" />
          ) : (
            teamsTranslations("description", { name: team.name })
          )}
        </DialogContentText>
        {!team ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label={teamsTranslations("label")}
            type="text"
            value={nameState}
            onChange={(event) => setNameState(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!team ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            {commonTranslations("cancel")}
          </Button>
        )}
        {!team ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button
            disabled={!team || team.name !== nameState}
            onClick={() => handleDelete(team)}
            color="primary"
          >
            {commonTranslations("delete")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
