import React from "react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useApi } from "../../../../../../../common/providers/Api.provider";
import { getSnackbarOptions } from "../../../../../../../../lib/getSnackbarOptions";
import { QuestionnaireRole } from "../../../../../../../../models/questionnaire-role/questionnaireRole";
import { useTranslation } from "react-i18next";

export interface QuestionnaireRoleDeleteDialogComponentProps {
  open: boolean;
  questionnaireId: string;
  questionnaireRole?: QuestionnaireRole;
  handleClose: (result?: number) => void;
}

export function QuestionnaireRoleDeleteDialogComponent({
  open,
  questionnaireId,
  questionnaireRole,
  handleClose,
}: QuestionnaireRoleDeleteDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "roles.dialogs.delete",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [nameState, setNameState] = useState<string>("");

  const handleDelete = async (questionnaireRole?: QuestionnaireRole) => {
    if (
      !questionnaireRole ||
      !questionnaireRole.id ||
      questionnaireRole.name !== nameState
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnaireRoleDelete(
        questionnaireId,
        questionnaireRole.id.toString()
      );

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      handleClose(questionnaireRole.id);
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setNameState("");
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!questionnaireRole ? (
          <Skeleton variant="text" />
        ) : (
          questionnaireTranslations("title", {
            role: questionnaireRole.name,
          })
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!questionnaireRole ? (
            <Skeleton variant="rectangular" />
          ) : (
            questionnaireTranslations("description", {
              role: questionnaireRole.name,
            })
          )}
        </DialogContentText>
        {!questionnaireRole ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label={questionnaireTranslations("repeatName")}
            type="text"
            value={nameState}
            onChange={(event) => setNameState(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!questionnaireRole ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            {commonTranslations("cancel")}
          </Button>
        )}
        {!questionnaireRole ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button
            disabled={
              !questionnaireRole || questionnaireRole.name !== nameState
            }
            onClick={() => handleDelete(questionnaireRole)}
            color="primary"
          >
            {commonTranslations("remove")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
