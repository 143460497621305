import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { QuestionnaireRole } from "../../../../../../../../models/questionnaire-role/questionnaireRole";
import { QuestionnaireRoleUpdateDto } from "../../../../../../../../models/questionnaire/questionnaireRoleUpdateDto";
import { useApi } from "../../../../../../../common/providers/Api.provider";
import { getSnackbarOptions } from "../../../../../../../../lib/getSnackbarOptions";
import { useTranslation } from "react-i18next";

export interface QuestionnaireRoleUpdateDialogComponentProps {
  open: boolean;
  questionnaireId: string;
  questionnaireRole?: QuestionnaireRole;
  handleClose: (result?: QuestionnaireRole) => void;
}

export function QuestionnaireRoleUpdateDialogComponent({
  open,
  questionnaireId,
  questionnaireRole,
  handleClose,
}: QuestionnaireRoleUpdateDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "roles.dialogs.edit",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [questionnaireRoleUpdateDtoState, setQuestionnaireRoleUpdateDtoState] =
    useState<QuestionnaireRoleUpdateDto | null>(null);

  useEffect(() => {
    if (!!questionnaireRole) {
      setQuestionnaireRoleUpdateDtoState({
        ...questionnaireRole,
        questionnaireId: Number(questionnaireId),
      });
    }
  }, [questionnaireRole, questionnaireId]);

  const handleChange = (
    questionnaireRoleUpdateDto: QuestionnaireRoleUpdateDto
  ) => {
    setQuestionnaireRoleUpdateDtoState(questionnaireRoleUpdateDto);
  };

  const handleUpdate = async (
    questionnaireRoleUpdateDto: QuestionnaireRoleUpdateDto
  ) => {
    if (
      !questionnaireRoleUpdateDto.id ||
      !questionnaireRoleUpdateDto.questionnaireId ||
      !questionnaireRoleUpdateDto.name.length
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnaireRoleUpdate(
        questionnaireRoleUpdateDto
      );

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setQuestionnaireRoleUpdateDtoState(null);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!questionnaireRoleUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          questionnaireTranslations("title")
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!questionnaireRoleUpdateDtoState ? (
            <Skeleton variant="rectangular" />
          ) : (
            questionnaireTranslations("description")
          )}
        </DialogContentText>
        {!questionnaireRoleUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            InputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            margin="dense"
            id="name"
            label={questionnaireTranslations("name")}
            type="text"
            value={questionnaireRoleUpdateDtoState.name}
            onChange={(event) =>
              handleChange({
                ...questionnaireRoleUpdateDtoState,
                name: event.target.value,
              })
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        {!questionnaireRoleUpdateDtoState ? (
          <Skeleton variant="rectangular" />
        ) : (
          <>
            <Button onClick={() => handleClose(undefined)} color="secondary">
              {commonTranslations("cancel")}
            </Button>
            <Button
              onClick={() => handleUpdate(questionnaireRoleUpdateDtoState)}
              color="primary"
            >
              {commonTranslations("edit")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
