import { Team } from "../team/team";
import { User } from "../user/user";
import { Role } from "./role";

export class UserRole {
  id!: number;
  role!: Role;

  user!: User | null;
  userId!: number;
  
  teamId!: number;
  team!: Team;
}