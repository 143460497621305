import { TFunction } from "i18next";
import { Mode } from "./mode";

export function convertModeToText(mode: Mode, t: TFunction) {
  let text: string;

  switch (mode) {
    case Mode.FINISHED:
      text = t("finishedScan");
      break;
    case Mode.PREVIOUS:
      text = t("previousScan");
      break;
    case Mode.ROLE_AVERAGE:
      text = t("averageOfSelectedRole");
      break;
    case Mode.TEAM_AVERAGE:
      text = t("averageOfEntireTeam");
      break;
    default:
      throw new Error("Cannot find mode");
  }

  return text;
}
