import React from "react";
import { User } from "../../../../../models/user/user";
import { TableRow, TableCell, IconButton, Menu, MenuItem } from "@mui/material";
import { useUser } from "../../../../common/providers/User.provider";
import SettingsIcon from "@mui/icons-material/Settings";
import { convertRoleToText } from "../../../../../lib/convertRoleToText";
import { useTranslation } from "react-i18next";

export interface UserListItemComponentProps {
  user: User;

  setUserToUpdate: React.Dispatch<React.SetStateAction<User | undefined>>;
  setUserToDelete: React.Dispatch<React.SetStateAction<User | undefined>>;
  setUserToChangePassword: React.Dispatch<
    React.SetStateAction<User | undefined>
  >;
}

export function UserListItemComponent({
  user,
  setUserToUpdate,
  setUserToDelete,
  setUserToChangePassword,
}: UserListItemComponentProps) {
  const { t: usersTranslations } = useTranslation("users-page", {
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const { user: authenticatedUser } = useUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isAuthenticatedUser = () => authenticatedUser.id === user.id;

  const handleSettingsMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      <TableCell component="th" scope="user">
        {user.id}
      </TableCell>
      <TableCell>
        <span
          style={{
            textDecoration: isAuthenticatedUser() ? "underline" : "none",
          }}
        >
          {user.email}
        </span>
      </TableCell>
      <TableCell>
        <ul>
          {user.userRoles.map((userRole, index) => (
            <li key={index}>
              {userRole.team.name} -{" "}
              {convertRoleToText(userRole.role, commonTranslations)}
            </li>
          ))}
        </ul>
      </TableCell>
      <TableCell>
        <IconButton onClick={handleSettingsMenuClick}>
          <SettingsIcon />
        </IconButton>
        <Menu
          id="settings-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleSettingsMenuClose}
        >
          <MenuItem
            onClick={() => {
              handleSettingsMenuClose();
              setUserToChangePassword(user);
            }}
          >
            {usersTranslations("changePassword")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleSettingsMenuClose();
              setUserToUpdate(user);
            }}
          >
            {commonTranslations("edit")}
          </MenuItem>
          {!isAuthenticatedUser() && (
            <MenuItem
              onClick={() => {
                handleSettingsMenuClose();
                setUserToDelete(user);
              }}
            >
              {commonTranslations("remove")}
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
}
