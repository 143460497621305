import React from "react";
import { Question } from "../../../../../../../../models/question/question";
import { TextField, FormControlLabel, Switch } from "@mui/material";
import { OpenData } from "../../../../../../../../models/question/openData";
import { useTranslation } from "react-i18next";

export interface OpenInputComponentProps {
  value: Question<OpenData>;
  setValue: (value: Question<OpenData>) => void;
}

export function OpenInputComponent({
  value,
  setValue,
}: OpenInputComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "steps.questions.open",
      useSuspense: false,
    }
  );

  return (
    <div className="open-input">
      <TextField
        fullWidth
        variant="outlined"
        label={questionnaireTranslations("placeholder")}
        color="primary"
        className="form-control"
        value={value.data.placeholder || ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setValue({
            ...value,
            data: { ...value.data, placeholder: event.target.value },
          })
        }
      />
      <div>
        <FormControlLabel
          control={
            <Switch
              required
              checked={value.data.multilineAnswer || false}
              value={value.data.multilineAnswer || false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue({
                  ...value,
                  data: {
                    ...value.data,
                    multilineAnswer: event.target.checked,
                  },
                })
              }
            />
          }
          label={questionnaireTranslations("allowMultipleLines")}
        />
      </div>
    </div>
  );
}
