import React, { createElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../common/providers/Api.provider";
import { Error } from "../../../../common/error/Error.component";
import "./QuestionnaireStatisticPage.component.sass";
import {
  Breadcrumbs,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  ListSubheader,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Questionnaire } from "../../../../../models/questionnaire/questionnaire";
import { Loader } from "../../../../common/loader/Loader.component";
import { Category } from "../../../../../models/category/category";
import { RadarGraph } from "../../scan/graph/radar-graph/RadarGraph.component";
import { formatScanToRadarGraphDataStatistics } from "./formatScanToRadarGraphDataStatistics";
import { zip } from "../../../../../lib/zip";
import { BarGraph } from "../../scan/graph/bar-graph/BarGraph.component";
import { formatScanToBarGraphDataStatistics } from "./formatScanToBarGraphDataStatistics";
import { RevisionWithCycles } from "./revisionWithCycles";
import { RoleWithScan } from "./roleWithScan";
import { RevisionWithCycle } from "./revisionWithCycle";
import { useMediaQuery } from "react-responsive";
import PrintIcon from "@mui/icons-material/Print";
import { useTranslation } from "react-i18next";

const ENTIRE_TEAM = "ENTIRE_TEAM";

export function QuestionnaireStatisticPageComponent() {
  const { t: questionnaireStatisticTranslations } = useTranslation(
    "questionnaire-statistic-page",
    {
      useSuspense: false,
    }
  );

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const api = useApi();
  const { questionnaireId } = useParams<{ questionnaireId: string }>();
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);
  const [questionnaireState, setQuestionnaireState] =
    useState<Questionnaire | null>(null);
  const entireTeamRoleStateOption = {
    id: ENTIRE_TEAM,
    name: questionnaireStatisticTranslations("entireTeam"),
    averageScan: undefined,
    checked: false,
    total: undefined,
  };
  const [rolesState, setRolesState] = useState<RoleWithScan[]>([
    entireTeamRoleStateOption,
  ]);
  const [versionsState, setVersionsState] = useState<RevisionWithCycles[]>([]);
  const [isLoadingQuestionnaireState, setIsLoadingQuestionnaireState] =
    useState<boolean>(false);
  const [
    isLoadingQuestionnaireRolesState,
    setIsLoadingQuestionnaireRolesState,
  ] = useState<boolean>(false);
  const [
    isLoadingQuestionnaireVersionsState,
    setIsLoadingQuestionnaireVersionsState,
  ] = useState<boolean>(false);
  const [categoriesState, setCategoriesState] = useState<Category[]>([]);
  const [activeCategoryFilterState, setActiveCategoryFilterState] = useState<
    Category | undefined
  >(undefined);
  const [activeVersionState, setActiveVersionState] = useState<
    RevisionWithCycle | undefined
  >(undefined);

  const getFilteredRoles = () =>
    rolesState.filter(
      (roleState) => roleState.checked && roleState.averageScan
    );

  const getQuestionnaireVersions = async (questionnaireId: number) => {
    try {
      setIsLoadingQuestionnaireVersionsState(true);

      const { data: versions } = await api.questionnaireVersionsById(
        questionnaireId
      );

      if (versions) {
        setVersionsState(versions);

        if (!activeVersionState && versions.length) {
          const latestVersion = versions[0];

          setActiveVersionState({
            questionnaireId: latestVersion.questionnaireId,
            revision: latestVersion.revision,
            cycle: latestVersion.cycles[0],
          });
        }
      }
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingQuestionnaireVersionsState(false);
    }
  };

  const getQuestionnaire = async (questionnaireId: number) => {
    try {
      setIsLoadingQuestionnaireState(true);

      const { data: questionnaire } = await api.questionnaireById(
        questionnaireId
      );

      setQuestionnaireState(questionnaire);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingQuestionnaireState(false);
    }
  };

  const getQuestionnaireRoles = async (
    questionnaireId: number,
    cycle: number
  ) => {
    try {
      setIsLoadingQuestionnaireRolesState(true);

      const { data: questionnaireRoles } =
        await api.questionnaireRolesByQuestionnaireIdWithTotalScans(
          questionnaireId,
          cycle
        );

      setRolesState([
        entireTeamRoleStateOption,
        ...questionnaireRoles.map((questionnaireRole) => ({
          id: questionnaireRole.id.toString(),
          name: questionnaireRole.name,
          averageScan: undefined,
          checked: false,
          total: questionnaireRole.totalScans!,
        })),
      ]);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingQuestionnaireRolesState(false);
    }
  };

  const getAverageScanResultByQuestionnaireRole = async (
    questionnaireRoleId: string
  ) => {
    try {
      let data: any = null;

      const questionnaireId = activeVersionState!.questionnaireId;
      const cycle = activeVersionState!.cycle;

      const result =
        questionnaireRoleId === ENTIRE_TEAM
          ? await api.scansAverageByEntireTeamAndQuestionnaireId(
              questionnaireId,
              cycle
            )
          : await api.scansAverageByQuestionnaireIdAndQuestionnaireRoleId(
              questionnaireId,
              cycle,
              Number(questionnaireRoleId)
            );
      data = result.data;

      setRolesState((prevState) =>
        prevState.map((prev) => {
          if (prev.id === questionnaireRoleId) {
            prev.averageScan = data;
          }

          return prev;
        })
      );
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    }
  };

  const getCategories = async (questionnaireId: number) => {
    try {
      const { data: categories } = await api.categoriesByQuestionnaireId(
        questionnaireId
      );

      setCategoriesState(categories);
    } catch (e) {
      console.error(e);
    }
  };

  const renderGraph = () => {
    if (!activeCategoryFilterState) {
      return (
        <RadarGraph
          isMobile={isMobile}
          data={
            getFilteredRoles().length
              ? zip(
                  getFilteredRoles().map((roleState) =>
                    formatScanToRadarGraphDataStatistics(roleState)
                  )
                )
              : []
          }
          keys={
            getFilteredRoles().length
              ? getFilteredRoles().map((roleState) => roleState.name)
              : []
          }
          indexedBy="category"
          maxValue={Math.max.apply(
            Math,
            getFilteredRoles()
              .filter((roleState) => roleState.averageScan)
              .map((roleState) => roleState.averageScan!)
              .flatMap((scan) =>
                scan.scanCategoryResults.map((scr) => scr.category.scale)
              )
          )}
        />
      );
    }

    return (
      <BarGraph
        isMobile={isMobile}
        data={zip(
          rolesState.map((roleState) =>
            formatScanToBarGraphDataStatistics(
              roleState,
              activeCategoryFilterState
            )
          )
        )}
        keys={getFilteredRoles().map((roleState) => roleState.name)}
        indexedBy="step"
        maxValue={Math.max.apply(
          Math,
          getFilteredRoles()
            .filter((roleState) => roleState.averageScan)
            .map((roleState) => roleState.averageScan!)
            .flatMap((scan) =>
              scan.scanCategoryResults.map((scr) => scr.category.scale)
            )
        )}
      />
    );
  };

  const renderVersions = () => {
    return (
      <TextField
        select
        fullWidth
        className="filters__filter"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          displayEmpty: true,
        }}
        value={JSON.stringify(activeVersionState)}
        onChange={(event) => {
          const revisionWithCycle: RevisionWithCycle = JSON.parse(
            event.target.value
          );
          setActiveVersionState(revisionWithCycle);
        }}
      >
        {versionsState.map((version: any) => {
          return [
            <ListSubheader>
              {questionnaireStatisticTranslations("revision", {
                revision: version.revision,
              })}
            </ListSubheader>,
            version.cycles.map((cycle: any) => {
              const revisionWithCycle = Object.assign(new RevisionWithCycle(), {
                questionnaireId: version.questionnaireId,
                revision: version.revision,
                cycle,
              });

              return (
                <MenuItem
                  key={revisionWithCycle.toString(
                    questionnaireStatisticTranslations
                  )}
                  value={JSON.stringify(revisionWithCycle)}
                >
                  {questionnaireStatisticTranslations("revisionWithCycle", {
                    revision: version.revision,
                    cycle,
                  })}
                </MenuItem>
              );
            }),
          ];
        })}
      </TextField>
    );
  };

  const renderCategories = () => {
    if (!categoriesState) {
      return <></>;
    }

    return (
      <TextField
        select
        fullWidth
        className="filters__filter"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          displayEmpty: true,
        }}
        value={activeCategoryFilterState ? activeCategoryFilterState.id : ""}
        onChange={(event) => {
          const value = event.target.value;
          setActiveCategoryFilterState(
            categoriesState.find((c) => c.id === Number(value))
          );
        }}
      >
        <MenuItem value={""}>
          <i>{questionnaireStatisticTranslations("none")}</i>
        </MenuItem>
        {categoriesState.length && (
          <ListSubheader>
            {questionnaireStatisticTranslations("perCategory")}
          </ListSubheader>
        )}
        {categoriesState.map((filter) => (
          <MenuItem key={filter.id} value={filter.id}>
            {filter.name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderCheckboxes = () => {
    return rolesState.map((roleState, index) => (
      <FormControlLabel
        className="checkbox"
        key={index}
        control={
          <Checkbox
            checked={roleState.checked}
            onChange={(_, checked) => {
              setRolesState((prevState) =>
                prevState.map((prev) => ({
                  ...prev,
                  checked: prev.id === roleState.id ? checked : prev.checked,
                }))
              );

              if (!roleState.averageScan) {
                getAverageScanResultByQuestionnaireRole(roleState.id);
              }
            }}
          />
        }
        label={
          <Typography display="inline" variant="body2">
            {roleState.total !== undefined
              ? questionnaireStatisticTranslations("perRole", {
                  role: roleState.name,
                  total: roleState.total,
                })
              : roleState.name}
          </Typography>
        }
      />
    ));
  };

  useEffect(() => {
    const numericQuestionnaireId = Number(questionnaireId);
    if (
      !activeVersionState ||
      activeVersionState!.questionnaireId !== numericQuestionnaireId
    ) {
      getQuestionnaireVersions(numericQuestionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  useEffect(() => {
    if (activeVersionState) {
      getQuestionnaire(activeVersionState.questionnaireId);
      getQuestionnaireRoles(
        activeVersionState.questionnaireId,
        activeVersionState.cycle
      );
      getCategories(activeVersionState.questionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVersionState]);

  if (!questionnaireId || hasErrorState) {
    return <Error />;
  }

  if (
    questionnaireState === null ||
    isLoadingQuestionnaireState ||
    isLoadingQuestionnaireRolesState ||
    isLoadingQuestionnaireVersionsState
  ) {
    return <Loader />;
  }

  return (
    <div className="questionnaire-statistics-page-container page">
      <div className="header">
        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
          <Link color="inherit" href="/questionnaires">
            <AssignmentIcon />
            {questionnaireStatisticTranslations("title")}
          </Link>
          <Link
            color="inherit"
            href={`/questionnaire/${questionnaireState!.id}`}
          >
            {questionnaireStatisticTranslations("questionnaireDetail", {
              questionnaire: questionnaireState!.name,
            })}
          </Link>
          <Link className="active" color="textPrimary" aria-current="page">
            {questionnaireStatisticTranslations("statistics")}
          </Link>
        </Breadcrumbs>
        <IconButton
          edge="start"
          onClick={() => window.print()}
          className="print"
        >
          <PrintIcon />
        </IconButton>
      </div>
      <Grid container spacing={isMobile ? 0 : 3}>
        <Grid item md={9} xs={12} className="graph-container">
          <Paper className="paper graph">
            <div className="title-container">
              <span>
                {questionnaireStatisticTranslations("statisticsOf", {
                  questionnaire: questionnaireState!.name,
                })}
              </span>
            </div>
            <div className="inner">{renderGraph()}</div>
          </Paper>
        </Grid>
        <Grid item md={3} xs={12} className="filter-container">
          <Paper className="paper filters">
            <div className="title-container">
              <span>{questionnaireStatisticTranslations("versions")}</span>
            </div>
            {renderVersions()}
            <div className="title-container">
              <span>{questionnaireStatisticTranslations("categories")}</span>
            </div>
            {renderCategories()}
            <div className="title-container">
              <span>{questionnaireStatisticTranslations("compareWith")}</span>
            </div>
            {renderCheckboxes()}
          </Paper>
        </Grid>
        {questionnaireState.scanExplanation && (
          <Grid item xs={12} className="explanation-container">
            <Paper className="paper">
              {createElement("div", {
                dangerouslySetInnerHTML: {
                  __html: questionnaireState.scanExplanation,
                },
              })}
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
