import React, { useState, useEffect } from "react";
import { Category } from "../../../../../../models/category/category";
import {
  Paper,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Fab,
} from "@mui/material";
import { Loader } from "../../../../../common/loader/Loader.component";
import { CategoryComponent } from "../category/Category.component";
import "./Categories.component.sass";
import AddIcon from "@mui/icons-material/Add";
import { CategoryAddDialogComponent } from "../dialog/category-add-dialog/CategoryAddDialog.component";
import { CategoryUpdateDialogComponent } from "../dialog/category-update-dialog/CategoryUpdateDialog.component";
import { CategoryDeleteDialogComponent } from "../dialog/category-delete-dialog/CategoryDeleteDialog.component";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface CategoriesComponentProps {
  questionnaireId: string | undefined;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CategoriesComponent({
  questionnaireId,
  setHasError,
}: CategoriesComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "categories",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const [isCategoriesLoadingState, setIsCategoriesLoadingState] =
    useState<boolean>(false);
  const [categoriesState, setCategoriesState] = useState<Category[]>([]);
  const [isCategoryAddDialogOpenState, setIsCategoryAddDialogOpenState] =
    useState<boolean>(false);
  const [categoryToUpdateState, setCategoryToUpdateState] = useState<
    Category | undefined
  >(undefined);
  const [categoryIdToDeleteState, setCategoryIdToDeleteState] = useState<
    number | undefined
  >(undefined);

  const getCategories = async (questionnaireId: number) => {
    try {
      setIsCategoriesLoadingState(true);
      const response = await api.categoriesByQuestionnaireId(questionnaireId);

      setCategoriesState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsCategoriesLoadingState(false);
    }
  };

  useEffect(() => {
    if (!!questionnaireId) {
      getCategories(Number(questionnaireId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  const handleCloseCategoryAddDialog = (result?: Category) => {
    if (!!result) {
      setCategoriesState([...categoriesState, result]);
    }

    setIsCategoryAddDialogOpenState(false);
  };

  const handleCloseCategoryUpdateDialog = (result?: Category) => {
    if (!!result) {
      setCategoriesState(
        categoriesState.map((c) =>
          c.id === result.id ? { ...c, ...result } : c
        )
      );
    }

    setCategoryToUpdateState(undefined);
  };

  const handleCloseCategoryDeleteDialog = (result?: number) => {
    if (!!result) {
      setCategoriesState(categoriesState.filter((c) => c.id !== result));
    }

    setCategoryIdToDeleteState(undefined);
  };

  return (
    <>
      <CategoryAddDialogComponent
        open={isCategoryAddDialogOpenState}
        handleClose={handleCloseCategoryAddDialog}
        questionnaireId={Number(questionnaireId)}
      />
      <CategoryUpdateDialogComponent
        open={!!categoryToUpdateState}
        category={categoryToUpdateState}
        handleClose={handleCloseCategoryUpdateDialog}
      />
      <CategoryDeleteDialogComponent
        open={!!categoryIdToDeleteState}
        categoryId={categoryIdToDeleteState}
        handleClose={handleCloseCategoryDeleteDialog}
      />
      <div className="categories-container">
        {isCategoriesLoadingState ? (
          <Paper className="categories-container__paper">
            <Loader loadingText={commonTranslations("loading")} />
          </Paper>
        ) : (
          <>
            <TableContainer
              className="categories-container__table"
              component={Paper}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {questionnaireTranslations("table.id")}
                    </TableCell>
                    <TableCell>
                      {questionnaireTranslations("table.name")}
                    </TableCell>
                    <TableCell>
                      {questionnaireTranslations("table.scale")}
                    </TableCell>
                    <TableCell>
                      {questionnaireTranslations("table.showInScanResult")}
                    </TableCell>
                    <TableCell>
                      {questionnaireTranslations("table.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoriesState.map((row, index) => (
                    <CategoryComponent
                      key={index}
                      category={row}
                      setCategoryToUpdate={setCategoryToUpdateState}
                      setCategoryIdToDelete={setCategoryIdToDeleteState}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="categories-container__fab-container">
              <Fab
                color="secondary"
                onClick={() => setIsCategoryAddDialogOpenState(true)}
              >
                <AddIcon />
              </Fab>
            </div>
          </>
        )}
      </div>
    </>
  );
}
