import React, { useEffect } from "react";
import { Question } from "../../../../../models/question/question";
import { ScanValue } from "../../../../../models/scan-value/scanValue";
import { LikertScaleData } from "../../../../../models/question/likertScaleData";
import { MenuItem, Radio, Tooltip } from "@mui/material";
import "./LikertScaleType.component.sass";
import InfoIcon from "@mui/icons-material/Info";
import { useMediaQuery } from 'react-responsive';
import { TextField } from "@mui/material";

export interface LikertScaleTypeComponentProps {
  disabled?: boolean;
  question?: Question;
  scanValue?: ScanValue;
  setScanValue?: (value: ScanValue) => void;
}

export function LikertScaleTypeComponent({
  question,
  scanValue,
  setScanValue,
  disabled = false,
}: LikertScaleTypeComponentProps) {
  const isCompact = useMediaQuery({ maxWidth: 1329 });

  const q =
    !!scanValue && !!scanValue.question ? scanValue.question : question!;
  const likertScaleData = q.data as LikertScaleData;
  
  const gridTemplateColumns = {
    gridTemplateColumns: `auto ${likertScaleData.columns
      .map(() => `calc((100% - 350px) / ${likertScaleData.columns.length})`)
      .join(" ")}`,
  };

  const serialize = (array: Array<string>): string =>
    array.join(",");

  const deserialize = (str: string): Array<string> =>
    str.split(",").map((part) => (!!part ? part : ""));

  const renderHint = (hint: string) => {
    if (!hint || !hint.length) {
      return;
    }

    return (
      <Tooltip title={hint} placement="top">
        <InfoIcon fontSize="small" color="secondary" />
      </Tooltip>
    );
  };

  const handleChange = (rowIndex: string, columnIndex: string) => {
    if (!!scanValue && !!setScanValue) {
      let values = deserialize(scanValue.value);
      values[Number(rowIndex)] = columnIndex;
      const score = values.reduce((total, current) => {
        let value = 0;

        if (current !== undefined) {
          value = Number(likertScaleData.columns[Number(current)].score);
        }

        return total !== undefined ? total + value : value;
      }, 0);

      setScanValue({
        ...scanValue,
        value: serialize(values),
        score: score !== undefined ? score : null,
      });
    }
  };

  const renderDetailed = () => {
    return (
      <div className="detailed">
        <div className="likert-scale-type__row" style={gridTemplateColumns}>
          <div className="likert-scale-type__column__instruction-cell"></div>
            {likertScaleData.columns.map((column, index) => (
              <div className="likert-scale-type__column" key={index}>
                <div className="likert-scale-type__header">{column.label}</div>
              </div>
            ))}
        </div>
        {likertScaleData.rows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            style={gridTemplateColumns}
            className={
              "likert-scale-type__row " +
              (rowIndex % 2 === 0
                ? "likert-scale-type__row__light-grey"
                : "likert-scale-type__row__grey")
            }
          >
            <div className="likert-scale-type__column__instruction-cell">
              <span>{row.instruction}</span>
              {renderHint(row.hint)}
            </div>
            {likertScaleData.columns.map((_, columnIndex) => (
              <div className="likert-scale-type__column" key={columnIndex}>
                <div className="likert-scale-type__content">
                  <Radio
                    disabled={disabled}
                    checked={
                      !!scanValue
                        ? deserialize(scanValue.value)[rowIndex] === columnIndex.toString() 
                        : false
                    }
                    onChange={() => handleChange(rowIndex.toString(), columnIndex.toString())}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const renderCompact = () => {
    return (
      <div className="compact">
        {likertScaleData.rows.map((row, rowIndex) => (
          <div className="container" key={rowIndex}>
            <div className="instruction">
              <span>{row.instruction}</span>
              {renderHint(row.hint)}
            </div>
            <TextField
              key={rowIndex}
              disabled={disabled}
              fullWidth
              select
              variant="outlined"
              SelectProps={{
                displayEmpty: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              hiddenLabel
              required={q.required}
              value={!!scanValue ? deserialize(scanValue.value)[rowIndex] : ""}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => handleChange(rowIndex.toString(), event.target.value)}  
            >
              <MenuItem value="">Kies een optie</MenuItem>
              {likertScaleData.columns.map((column, columnIndex) => (
                <MenuItem style={{ whiteSpace: 'normal' }} key={columnIndex} value={columnIndex.toString()}>
                  {column.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (!!scanValue && !!setScanValue && !scanValue.value.length) {
      setScanValue({
        ...scanValue,
        value: serialize(
          Array(likertScaleData.rows.length).fill("")
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="likert-scale-type">
      {isCompact ? renderCompact() : renderDetailed()}
    </div>
  );
}
