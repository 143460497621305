import React from "react";
import { IconButton, TableRow, TableCell, Tooltip } from "@mui/material";
import "./QuestionnaireListItem.component.sass";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReplayIcon from "@mui/icons-material/Replay";
import ShareIcon from "@mui/icons-material/Share";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Questionnaire } from "../../../../../../models/questionnaire/questionnaire";
import { UserRole } from "../../../../../../models/user-role/userRole";
import { Role } from "../../../../../../models/user-role/role";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { useNavigate } from "react-router-dom";
import { getTeamQuestionnaireFromQuestionnaire } from "../../../../../../lib/getTeamQuestionnaire";
import { useTranslation } from "react-i18next";

export interface QuestionnaireListItemComponentProps {
  questionnaire: Questionnaire;
  userRole: UserRole;
  setQuestionnaireToDelete: React.Dispatch<
    React.SetStateAction<Questionnaire | undefined>
  >;
  setQuestionnaireToCopy: React.Dispatch<
    React.SetStateAction<Questionnaire | undefined>
  >;
  setQuestionnaireToEdit: (questionnaireId: number) => void;
  setQuestionnaireNewCycle: React.Dispatch<
    React.SetStateAction<{ questionnaireId: number; cycle: number } | undefined>
  >;
  setQuestionnaireShare: React.Dispatch<
    React.SetStateAction<Questionnaire | undefined>
  >;
  togglePublished: (questionnaireId: number, published: boolean) => void;
}

export function QuestionnaireListItemComponent({
  questionnaire,
  userRole,
  setQuestionnaireToDelete,
  setQuestionnaireToCopy,
  setQuestionnaireToEdit,
  setQuestionnaireNewCycle,
  setQuestionnaireShare,
  togglePublished,
}: QuestionnaireListItemComponentProps) {
  const { t: questionnairesTranslations } = useTranslation(
    "questionnaires-page",
    {
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const navigate = useNavigate();

  const isTeamLinked = () =>
    questionnaire.published &&
    questionnaire.teamQuestionnaires &&
    questionnaire.teamQuestionnaires.length > 0;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {questionnaire.id}
      </TableCell>
      <TableCell>{questionnaire.name}</TableCell>
      <TableCell>{questionnaire.revision}</TableCell>
      <TableCell>
        {getTeamQuestionnaireFromQuestionnaire(questionnaire, userRole.teamId)
          ?.cycle ?? "-"}
      </TableCell>
      <TableCell>
        {userRole.role === Role.Admin && (
          <>
            <Tooltip
              title={
                questionnaire.published
                  ? questionnairesTranslations("actions.open")
                  : questionnairesTranslations("actions.closed")
              }
              placement="top"
            >
              <IconButton
                onClick={() =>
                  togglePublished(questionnaire.id, !questionnaire.published)
                }
              >
                {questionnaire.published ? (
                  <VisibilityIcon style={{ color: "green" }} />
                ) : (
                  <VisibilityOffIcon color="error" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={questionnairesTranslations("actions.copyQuestionnaire")}
              placement="top"
            >
              <IconButton onClick={() => setQuestionnaireToCopy(questionnaire)}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={commonTranslations("edit")} placement="top">
              <IconButton
                onClick={() => setQuestionnaireToEdit(questionnaire.id)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {!questionnaire.published && (
              <Tooltip title={commonTranslations("remove")} placement="top">
                <IconButton
                  onClick={() => setQuestionnaireToDelete(questionnaire)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        {isTeamLinked() ? (
          <Tooltip
            title={questionnairesTranslations("actions.copyLink")}
            placement="top"
          >
            <IconButton onClick={() => setQuestionnaireShare(questionnaire)}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {isTeamLinked() ? (
          <Tooltip
            title={questionnairesTranslations("actions.newCycle")}
            placement="top"
          >
            <IconButton
              onClick={() =>
                setQuestionnaireNewCycle({
                  questionnaireId: questionnaire.id,
                  cycle:
                    getTeamQuestionnaireFromQuestionnaire(
                      questionnaire,
                      userRole.teamId
                    )?.cycle ?? 0,
                })
              }
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {isTeamLinked() ? (
          <Tooltip
            title={questionnairesTranslations("actions.statistics")}
            placement="top"
          >
            <IconButton
              onClick={() =>
                navigate(`/questionnaires/${questionnaire.id}/statistics`)
              }
            >
              <EqualizerIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </TableCell>
    </TableRow>
  );
}
