import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
  MenuItem,
  IconButton,
} from "@mui/material";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { User } from "../../../../../../models/user/user";
import { UserAddDto } from "../../../../../../models/user/userAddDto";
import { Team } from "../../../../../../models/team/team";
import { useApi } from "../../../../../common/providers/Api.provider";
import { Role } from "../../../../../../models/user-role/role";
import { convertRoleToText } from "../../../../../../lib/convertRoleToText";
import DeleteIcon from "@mui/icons-material/Delete";
import "./UserAddDialog.component.sass";
import { UserRole } from "../../../../../../models/user-role/userRole";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

export interface UserAddDialogComponentProps {
  open: boolean;
  handleClose: (result?: User) => void;
}

export function UserAddDialogComponent({
  open,
  handleClose,
}: UserAddDialogComponentProps) {
  const { t: userTranslations } = useTranslation("users-page", {
    keyPrefix: "dialogs.add",
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();
  // TODO: Refactor for new user
  const emptyState = {
    email: "",
    password: "",
    userRoles: [new UserRole()],
  };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [userAddDtoState, setUserAddDtoState] =
    useState<UserAddDto>(emptyState);

  const [teamsState, setTeamsState] = useState<Team[]>([]);

  const getTeams = async () => {
    try {
      const response = await api.teams();

      setTeamsState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (userAddDto: UserAddDto) => {
    setUserAddDtoState(userAddDto);
  };

  const handleAdd = async (userAddDto: UserAddDto) => {
    if (
      !userAddDto.email.length ||
      !userAddDto.password.length ||
      !userAddDto.userRoles.length ||
      !userAddDto.userRoles.every(
        (userRole) => userRole.teamId && userRole.role
      ) ||
      !(
        userAddDto.userRoles.length ===
        new Set<number>([
          ...userAddDto.userRoles.map((userRole) => userRole.teamId),
        ]).size
      )
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.usersAdd(userAddDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setUserAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    if (open) {
      getTeams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog className={"user-add-dialog-container"} open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>{userTranslations("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{userTranslations("description")}</DialogContentText>
        <TextField
          fullWidth
          required
          InputProps={{
            autoComplete: "off",
          }}
          variant="outlined"
          margin="dense"
          id="email"
          label={userTranslations("email")}
          type="email"
          value={userAddDtoState.email}
          onChange={(event) =>
            handleChange({
              ...userAddDtoState,
              email: event.target.value,
            })
          }
        />
        <TextField
          fullWidth
          required
          InputProps={{
            autoComplete: "off",
          }}
          variant="outlined"
          margin="dense"
          id="password"
          label={userTranslations("password")}
          type="password"
          value={userAddDtoState.password}
          onChange={(event) =>
            handleChange({
              ...userAddDtoState,
              password: event.target.value,
            })
          }
        />
        <span className="label">{userTranslations("minRoles")}</span>
        {userAddDtoState.userRoles.map((userRole, userRoleIndex) => (
          <div key={userRoleIndex} className={"user-role"}>
            <TextField
              fullWidth
              required
              select
              variant="outlined"
              margin="dense"
              id={`${userRoleIndex}_user_role_team`}
              label={userTranslations("team")}
              value={userRole.teamId ?? ""}
              onChange={(event) => {
                const teamId = event.target.value;
                const userRoles = userAddDtoState.userRoles;
                userRoles[userRoleIndex].teamId = Number(teamId);

                handleChange({
                  ...userAddDtoState,
                  userRoles,
                });
              }}
            >
              {teamsState.map((team, teamIndex) => (
                <MenuItem key={teamIndex} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </TextField>
            <div className={"divider"}></div>
            <TextField
              fullWidth
              required
              select
              variant="outlined"
              margin="dense"
              id={`${userRoleIndex}_user_role_role`}
              label={userTranslations("role")}
              value={userRole.role ?? ""}
              onChange={(event) => {
                const role = event.target.value;
                const userRoles = userAddDtoState.userRoles;
                userRoles[userRoleIndex].role = role as Role;

                handleChange({
                  ...userAddDtoState,
                  userRoles,
                });
              }}
            >
              {Object.values(Role).map((role, roleIndex) => (
                <MenuItem key={roleIndex} value={role}>
                  {convertRoleToText(role, commonTranslations)}
                </MenuItem>
              ))}
            </TextField>
            <div className="divider"></div>
            <IconButton
              disabled={userAddDtoState.userRoles.length === 1}
              className="delete-button"
              onClick={() => {
                userAddDtoState.userRoles.splice(userRoleIndex, 1);

                handleChange({
                  ...userAddDtoState,
                });
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        ))}
        <Button
          className="add-button"
          color="secondary"
          variant="contained"
          onClick={() => {
            userAddDtoState.userRoles.push(new UserRole());

            handleChange({
              ...userAddDtoState,
            });
          }}
        >
          <AddIcon />
          {userTranslations("addRole")}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          {commonTranslations("cancel")}
        </Button>
        <Button onClick={() => handleAdd(userAddDtoState)} color="primary">
          {commonTranslations("create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
