import React from "react";
import { Questionnaire } from "../../../../../../../models/questionnaire/questionnaire";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface QuestionnaireShareDialogComponentProps {
  open: boolean;
  questionnaire?: Questionnaire;
  handleClose: (result?: Questionnaire) => void;
}

export function QuestionnaireShareDialogComponent({
  open,
  questionnaire,
  handleClose,
}: QuestionnaireShareDialogComponentProps) {
  const { t: questionnairesTranslations } = useTranslation(
    "questionnaires-page",
    {
      keyPrefix: "dialogs.share",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  return (
    <Dialog open={open}>
      <DialogTitle>
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          questionnairesTranslations("title", { name: questionnaire.name })
        )}
      </DialogTitle>
      <DialogContent>
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <p>{questionnairesTranslations("description")}</p>
        )}
      </DialogContent>
      <DialogActions>
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            {commonTranslations("cancel")}
          </Button>
        )}
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(questionnaire)} color="primary">
            {questionnairesTranslations("copy")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
