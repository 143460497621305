import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { OptionsObject, useSnackbar } from "notistack";

export interface GetSnackbarOptionsProps extends OptionsObject {}

const SnackbarDismissDefault = ({ snackbarKey }: { snackbarKey: string }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      key={"close"}
      onClick={e => {
        closeSnackbar(snackbarKey);
      }}
    >
      <CloseIcon htmlColor="white" />
    </IconButton>
  );
};

export const getSnackbarOptions = (
  { variant, action }: GetSnackbarOptionsProps = {
    variant: "success",
    autoHideDuration: 1000
  }
): OptionsObject => ({
  variant,
  // action: !!action
  //   ? action
  //   : (key: string) => {
  //       return <SnackbarDismissDefault snackbarKey={key} />;
  //     }
});
