import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { CURRENT_USER_ROLE_KEY } from "../../../lib/constants";
import { UserRole } from "../../../models/user-role/userRole";
import { User } from "../../../models/user/user";
import { useApi } from "./Api.provider";

export interface UserStateContextProps {
  user: User;
  setUser: Dispatch<SetStateAction<User | null>>;
  userRole: UserRole;
  setUserRole: Dispatch<SetStateAction<UserRole | null>>;
}

const UserStateContext = createContext<UserStateContextProps>({
  user: null as any,
  setUser: () => {},
  userRole: null as any,
  setUserRole: () => {},
});

export interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [userRole, setUserRole] = useState<UserRole | null>(null);
  const api = useApi();

  const currentUserRoleKey = () =>
    Number(localStorage.getItem(CURRENT_USER_ROLE_KEY));

  useEffect(() => {
    if (userRole) {
      localStorage.setItem(CURRENT_USER_ROLE_KEY, userRole.id.toString());
    }
  }, [userRole]);

  useEffect(() => {
    if (user) {
      setUserRole(
        user.userRoles.find((r) => r.id === currentUserRoleKey()) ??
          user.userRoles[0]
      );
    }
  }, [user, setUserRole]);

  useEffect(() => {
    api
      .usersMe()
      .then((response) => {
        const user = response.data as User;

        setUser(user);
      })
      .finally(() => {
        setInitialized(true);
      });
  }, [api]);

  return (
    <UserStateContext.Provider
      value={{
        user: user as any,
        setUser,
        userRole: userRole as any,
        setUserRole,
      }}
    >
      {initialized && children}
    </UserStateContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserStateContext);
};
