import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { Step } from "../../../../../../../models/step/step";
import { StepUpdateDto } from "../../../../../../../models/step/stepUpdateDto";
import { Skeleton } from "@mui/material";
import "./StepUpdateDialog.component.sass";
import { Category } from "../../../../../../../models/category/category";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { getCKEditorConfig } from "../../../../../../../lib/getCKEditorConfig";
import { useTranslation } from "react-i18next";

export interface StepUpdateDialogComponentProps {
  open: boolean;
  step?: Step;
  handleClose: (result?: Step) => void;
}

export function StepUpdateDialogComponent({
  open,
  step,
  handleClose,
}: StepUpdateDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "steps.dialogs.edit",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [stepUpdateDtoState, setStepUpdateDtoState] =
    useState<StepUpdateDto | null>(null);

  const [categoriesState, setCategoriesState] = useState<Category[]>([]);

  const getCategories = async (questionnaireId: number) => {
    try {
      const response = await api.categoriesByQuestionnaireId(questionnaireId);

      setCategoriesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdate = async (stepUpdateDto: StepUpdateDto) => {
    if (
      !stepUpdateDto.id ||
      !stepUpdateDto.name.length ||
      !stepUpdateDto.categoryId
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.stepsUpdate(stepUpdateDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setStepUpdateDtoState(null);
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    if (open && !!step) {
      setStepUpdateDtoState(step);
      getCategories(step.questionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, step]);

  return (
    <Dialog open={open} className="step-update-dialog-container">
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!stepUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          questionnaireTranslations("title")
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!stepUpdateDtoState ? (
            <Skeleton variant="rectangular" />
          ) : (
            questionnaireTranslations("description")
          )}
        </DialogContentText>
        {!stepUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            className={"mb"}
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label={questionnaireTranslations("name")}
            type="text"
            value={stepUpdateDtoState.name}
            onChange={(event) =>
              setStepUpdateDtoState({
                ...stepUpdateDtoState,
                name: event.target.value,
              })
            }
          />
        )}
        {!stepUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <CKEditor
            className={"mb"}
            editor={ClassicEditor}
            data={stepUpdateDtoState.description}
            config={{
              ...getCKEditorConfig(),
              placeholder: questionnaireTranslations("inputDescription"),
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setStepUpdateDtoState({
                ...stepUpdateDtoState,
                description: data,
              });
            }}
          />
        )}
        {!stepUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            className={"mt mb"}
            fullWidth
            select
            margin="dense"
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
            }}
            id="categoryId"
            label={questionnaireTranslations("category")}
            required
            value={stepUpdateDtoState.categoryId}
            onChange={(event) => {
              setStepUpdateDtoState({
                ...stepUpdateDtoState,
                categoryId: Number(event.target.value),
              });
            }}
          >
            <MenuItem value={0}>
              {questionnaireTranslations("selectCategory")}
            </MenuItem>
            {categoriesState.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </DialogContent>
      <DialogActions>
        {!stepUpdateDtoState ? (
          <Skeleton variant="rectangular" />
        ) : (
          <>
            <Button onClick={() => handleClose(undefined)} color="secondary">
              {commonTranslations("cancel")}
            </Button>
            <Button
              onClick={() => handleUpdate(stepUpdateDtoState)}
              color="primary"
            >
              {commonTranslations("edit")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
