import { TFunction } from "i18next";
import { testEmail } from "../../lib/testEmail";

export interface RegisterState {
  email: string;
  emailError?: string;

  password: string;
  passwordError?: string;

  repeatPassword: string;
  repeatPasswordError?: string;
}

export const initialRegisterState: RegisterState = {
  email: "",
  password: "",
  repeatPassword: "",
};

export function validateRegisterState(
  RegisterState: RegisterState,
  t: TFunction
): RegisterState {
  RegisterState.passwordError =
    RegisterState.password.length === 0
      ? t("passwordInput.errors.required")
      : undefined;

  if (RegisterState.email.length === 0) {
    RegisterState.emailError = t("emailInput.errors.required");
  } else if (
    RegisterState.email.length > 0 &&
    !testEmail(RegisterState.email)
  ) {
    RegisterState.emailError = t("emailInput.errors.invalid");
  } else if (RegisterState.password.length < 8) {
    RegisterState.passwordError = t("passwordInput.errors.minCharacters");
  } else if (!RegisterState.password.match(/[A-Z]/)) {
    RegisterState.passwordError = t("passwordInput.errors.capitalLetter");
  } else if (
    !RegisterState.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/)
  ) {
    RegisterState.passwordError = t("passwordInput.errors.specialCharacter");
  } else {
    RegisterState.emailError = undefined;
  }

  if (RegisterState.repeatPassword.length === 0) {
    RegisterState.repeatPasswordError = t(
      "repeatPasswordInput.errors.required"
    );
  } else if (
    RegisterState.repeatPassword.length > 0 &&
    RegisterState.repeatPassword !== RegisterState.password
  ) {
    RegisterState.repeatPasswordError = t(
      "repeatPasswordInput.errors.notMatch"
    );
  } else {
    RegisterState.repeatPasswordError = undefined;
  }

  return RegisterState;
}

export function isRegisterStateValid(RegisterState: RegisterState): boolean {
  return (
    !RegisterState.emailError &&
    !RegisterState.passwordError &&
    !RegisterState.repeatPasswordError
  );
}
