import React from "react";
import { Questionnaire } from "../../../../../../../models/questionnaire/questionnaire";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface QuestionnaireDeleteDialogComponentProps {
  open: boolean;
  questionnaire?: Questionnaire;
  handleClose: (result?: number) => void;
}

export function QuestionnaireDeleteDialogComponent({
  open,
  questionnaire,
  handleClose,
}: QuestionnaireDeleteDialogComponentProps) {
  const { t: questionnairesTranslations } = useTranslation(
    "questionnaires-page",
    {
      keyPrefix: "dialogs.delete",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [nameState, setNameState] = useState<string>("");

  const handleDelete = async (questionnaire?: Questionnaire) => {
    if (
      !questionnaire ||
      !questionnaire.id ||
      questionnaire.name !== nameState
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnairesDelete(questionnaire.id);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      handleClose(questionnaire.id);
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setNameState("");
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          questionnairesTranslations("title", {
            questionnaire: questionnaire.name,
          })
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!questionnaire ? (
            <Skeleton variant="rectangular" />
          ) : (
            questionnairesTranslations("description", {
              questionnaire: questionnaire.name,
            })
          )}
        </DialogContentText>
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            variant="outlined"
            margin="dense"
            id="name"
            label={questionnairesTranslations("repeatName")}
            type="text"
            value={nameState}
            onChange={(event) => setNameState(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            {commonTranslations("cancel")}
          </Button>
        )}
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button
            disabled={!questionnaire || questionnaire.name !== nameState}
            onClick={() => handleDelete(questionnaire)}
            color="primary"
          >
            {commonTranslations("remove")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
