import React, { useEffect, useState, createElement } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Scan } from "../../../../../models/scan/scan";
import { Loader } from "../../../../common/loader/Loader.component";
import { Error } from "../../../../common/error/Error.component";
import {
  Paper,
  Breadcrumbs,
  Link,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { QuestionComponent } from "../../../../common/question/Question.component";
import { StepperComponent } from "../stepper/Stepper.component";
import { Step } from "../../../../../models/step/step";
import { Question } from "../../../../../models/question/question";
import { ScanValue } from "../../../../../models/scan-value/scanValue";
import { getSnackbarOptions } from "../../../../../lib/getSnackbarOptions";
import CreateIcon from "@mui/icons-material/Create";
import "./ScanDetailPage.component.sass";
import { useApi } from "../../../../common/providers/Api.provider";
import { Role } from "../../../../../models/user-role/role";
import { useUser } from "../../../../common/providers/User.provider";
import { checkScanValueCompleted } from "../../../../../lib/checkScanValueCompleted";
import { useTranslation } from "react-i18next";

export function ScanDetailPageComponent() {
  const { t: scanTranslations } = useTranslation("scan-page", {
    useSuspense: false,
  });

  const api = useApi();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { userRole } = useUser();
  const { scanUuid } = useParams<{ scanUuid: string }>();
  const snackbar = useSnackbar();

  const [viewModeState, setViewModeState] = useState<boolean>(false);

  const [hasErrorState, setHasErrorState] = useState<boolean>(false);

  const [isLoadingScanState, setIsLoadingScanState] = useState<boolean>(false);
  const [scanState, setScanState] = useState<Scan | undefined>(undefined);

  const [scanValuesState, setScanValuesState] = useState<ScanValue[]>([]);

  const [isLoadingStepsState, setIsLoadingStepsState] =
    useState<boolean>(false);
  const [stepsState, setStepsState] = useState<Step[]>([]);
  const [activeStep, setActiveStep] = useState<number | undefined>(undefined);

  const [isLoadingQuestionsState, setIsLoadingQuestionsState] =
    useState<boolean>(false);
  const [questionsState, setQuestionsState] = useState<Question[]>([]);

  const [
    acceptProcessUserInformationState,
    setAcceptProcessUserInformationState,
  ] = useState<boolean>(false);

  const getScan = async (uuid: string) => {
    try {
      setIsLoadingScanState(true);

      const { data: scan } = await api.scansUUID(uuid);

      setScanState(scan);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingScanState(false);
    }
  };

  const getScanValues = async (scanUuid: string) => {
    try {
      const response = await api.scanValuesUUID(scanUuid);

      setScanValuesState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    }
  };

  const getSteps = async (questionnaireId: number) => {
    try {
      setIsLoadingStepsState(true);

      const response = await api.stepsByQuestionnaireId(questionnaireId);

      setStepsState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingStepsState(false);
    }
  };

  const getQuestions = async (stepId: number) => {
    try {
      setIsLoadingQuestionsState(true);

      const response = await api.questionsByStepId(stepId);

      setScanValuesState([
        ...scanValuesState,
        ...response.data
          .filter(
            (q) =>
              scanValuesState.findIndex((sv) => q.id === sv.questionId) === -1
          )
          .map(
            (q) =>
              ({
                questionId: q.id,
                scanId: scanState!.id,
                value: "",
                score: null,
              } as ScanValue)
          ),
      ]);

      setQuestionsState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsLoadingQuestionsState(false);
    }
  };

  const saveScanValues = async () => {
    try {
      const response = await api.scanValuesSave({
        scanId: scanState!.id,
        scanValues: scanValuesState.filter((sv) =>
          questionsState.some((q) => q.id === sv.questionId)
        ),
      });

      setScanValuesState(response.data);
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    }
  };

  const finishScan = async () => {
    try {
      const response = await api.scansFinish(scanState!.id, scanState!);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      navigate(`/scans/${scanUuid}/result`);
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    }
  };

  const isNextStepDisabled = () => {
    if (!scanState) {
      return false;
    }

    if (activeStep === undefined) {
      return (
        scanState!.questionnaire.explicitlyAskUserPermission &&
        !acceptProcessUserInformationState
      );
    }

    const step = stepsState[activeStep!];
    const requiredQuestions = questionsState.filter(
      (q) => q.stepId === step.id && q.required
    );
    const scanValuesToCheck = scanValuesState.filter(
      (sv) => requiredQuestions.findIndex((q) => q.id === sv.questionId) !== -1
    );

    const allRequiredFilledIn = scanValuesToCheck.every((sv) => {
      const question = requiredQuestions.find((q) => q.id === sv.questionId);
      if (question === undefined) {
        return false;
      }
      return checkScanValueCompleted(question, sv);
    });

    return requiredQuestions.length ? !allRequiredFilledIn : false;
  };

  const renderHTML = (html?: string) => {
    return createElement("div", {
      dangerouslySetInnerHTML: {
        __html: html,
      },
    });
  };

  const renderContent = () => {
    if (activeStep === undefined) {
      return (
        <Paper className="scan-detail-page-container__inner__content__information">
          <h1>{scanState?.questionnaire.name}</h1>
          {scanState?.questionnaire &&
            renderHTML(scanState?.questionnaire.description)}
          {scanState?.questionnaire.explicitlyAskUserPermission && (
            <div className="questionnaire-details-container__checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={acceptProcessUserInformationState}
                    onChange={(_, checked) => {
                      setAcceptProcessUserInformationState(checked);
                    }}
                  />
                }
                label={scanTranslations("explicitlyAskUserPermission")}
                labelPlacement="end"
              />
            </div>
          )}
        </Paper>
      );
    }

    const renderTitle = () => {
      const step = stepsState[activeStep!];

      if (step.category) {
        return (
          <>
            <h1>{step.category.name}</h1>
            {step.description && renderHTML(step.description)}
            <h2>{step.name}</h2>
          </>
        );
      }

      return (
        <>
          <h1>{step.name}</h1>
          {step.description && renderHTML(step.description)}
        </>
      );
    };

    return (
      <>
        <Paper className="scan-detail-page-container__inner__content__paper">
          {renderTitle()}
        </Paper>
        {isLoadingQuestionsState && (
          <Paper
            className={"scan-detail-page-container__inner__content__paper"}
          >
            <Loader loadingText="Vragen worden geladen..." />
          </Paper>
        )}
        {!isLoadingQuestionsState && questionsState.length > 0 && (
          <Paper
            className={"scan-detail-page-container__inner__content__paper"}
          >
            {questionsState.map((question, index) => {
              const currentScanValue = scanValuesState.find(
                (sv) => sv.questionId === question.id
              );

              return (
                <QuestionComponent
                  key={index}
                  editingMode={false}
                  disabled={viewModeState}
                  question={question}
                  scanValue={currentScanValue}
                  setScanValue={(value) => {
                    setScanValuesState((prevState) =>
                      prevState.map((sc) =>
                        sc.questionId === question.id! ? value : sc
                      )
                    );
                  }}
                />
              );
            })}
          </Paper>
        )}
      </>
    );
  };

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);
      const viewMode = JSON.parse(queryParams.get("viewMode") ?? "false");
      setViewModeState(viewMode);

      if (viewMode) {
        setActiveStep(0);
      }
    }
  }, [search]);

  useEffect(() => {
    if (userRole.role === Role.Researcher) {
      navigate("/");
    } else {
      if (scanUuid) {
        getScan(scanUuid);
        getScanValues(scanUuid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanUuid]);

  useEffect(() => {
    if (scanState && scanState.questionnaireId) {
      getSteps(scanState.questionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanState]);

  useEffect(() => {
    if (activeStep !== undefined && stepsState.length) {
      getQuestions(stepsState[activeStep].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepsState, activeStep]);

  if (isLoadingScanState || isLoadingStepsState) {
    return <Loader />;
  }

  if (hasErrorState) {
    return <Error />;
  }

  return (
    <>
      <div className="scan-detail-page-container">
        <div className="scan-detail-page-container__inner">
          <div className="scan-detail-page-container__inner__header">
            <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
              <Link color="inherit" href="/scans">
                <CreateIcon />
                {scanTranslations("scans")}
              </Link>
              <Link className="active" color="textPrimary" aria-current="page">
                {scanTranslations("title", {
                  questionnaire: scanState?.questionnaire.name,
                })}
              </Link>
            </Breadcrumbs>
          </div>
          <div className="scan-detail-page-container__inner__content">
            {renderContent()}
          </div>
        </div>
      </div>
      <span className="spacer"></span>
      <StepperComponent
        steps={stepsState.length}
        activeStep={activeStep}
        onChangeActiveStep={async (step) => {
          if (!viewModeState) {
            await saveScanValues();
          }
          setActiveStep(step);
        }}
        onFinish={async () => {
          if (!viewModeState) {
            try {
              setIsLoadingQuestionsState(true);
              await saveScanValues();
              await finishScan();
            } finally {
              setIsLoadingQuestionsState(false);
            }
          } else {
            navigate(`/scans/${scanUuid}/result`);
          }
        }}
        isLoading={isLoadingQuestionsState}
        nextStepDisabled={isNextStepDisabled()}
      />
    </>
  );
}
