import { useState, useEffect, createElement } from "react";
import {
  Paper,
  Breadcrumbs,
  Link,
  TextField,
  MenuItem,
  ListSubheader,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import { Error } from "../../../../common/error/Error.component";
import { Loader } from "../../../../common/loader/Loader.component";
import { Scan } from "../../../../../models/scan/scan";
import { useNavigate, useParams } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";
import "./ScanResultPage.component.sass";
import { RadarGraph } from "../graph/radar-graph/RadarGraph.component";
import { DateTime } from "luxon";
import { zip } from "../../../../../lib/zip";
import { formatScanToRadarGraphData } from "../../../../../lib/formatScanToRadarGraphData";
import { BarGraph } from "../graph/bar-graph/BarGraph.component";
import { formatScanToBarGraphData } from "../../../../../lib/formatScanToBarGraphData";
import { Category } from "../../../../../models/category/category";
import { Mode } from "../../../../../lib/mode";
import { useApi } from "../../../../common/providers/Api.provider";
import { QuestionnaireRole } from "../../../../../models/questionnaire-role/questionnaireRole";
import { useUser } from "../../../../common/providers/User.provider";
import { Role } from "../../../../../models/user-role/role";
import PrintIcon from "@mui/icons-material/Print";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const ENTIRE_TEAM = "ENTIRE_TEAM";

export function ScanResultPageComponent() {
  const { t: scanResultTranslations } = useTranslation("scan-result-page", {
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const { userRole } = useUser();
  const navigate = useNavigate();
  const api = useApi();
  const { scanUuid } = useParams<{ scanUuid: string }>();

  const [isScanLoadingState, setIsScanLoadingState] = useState<boolean>(false);
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);
  const [finishedScanState, setFinishedScanState] = useState<Scan | undefined>(
    undefined
  );
  const [scanToCompareWithState, setScanToCompareWithState] = useState<
    Scan | undefined
  >(undefined);

  // Category states
  const [categoriesState, setCategoriesState] = useState<Category[]>([]);
  const [activeCategoryFilterState, setActiveCategoryFilterState] = useState<
    Category | undefined
  >(undefined);

  // Questionnaire role states
  const [questionnaireRolesState, setQuestionnaireRolesState] = useState<
    QuestionnaireRole[]
  >([]);
  const [
    activeQuestionnaireRoleIdFilterState,
    setActiveQuestionnaireRoleIdFilterState,
  ] = useState<string | undefined>(undefined);

  const getScanResult = async (scanUuid: string) => {
    try {
      setIsScanLoadingState(true);

      const response = await api.scansResult(scanUuid);

      const { data: scan } = response;

      if (response.success) {
        setFinishedScanState(scan);

        if (scan.scanCategoryResults) {
          setCategoriesState(
            scan.scanCategoryResults.map((s) => {
              return s.category;
            })
          );
        }

        getQuestionnaireRoles(scan.questionnaireId);
      }
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsScanLoadingState(false);
    }
  };

  const getQuestionnaireRoles = async (questionnaireId: number) => {
    try {
      const response = await api.questionnaireRolesByQuestionnaireId(
        questionnaireId
      );

      setQuestionnaireRolesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getFilteredScansAveragetoCompareWith = async (
    scanUuid: string,
    teamRoleId: string | null
  ) => {
    try {
      let scan = undefined;

      if (teamRoleId) {
        const { data } =
          teamRoleId === ENTIRE_TEAM
            ? await api.scansAverageByEntireTeam(scanUuid)
            : await api.scansAverageByQuestionnaireRoleId(
                scanUuid,
                Number(teamRoleId)
              );
        scan = data;
      }

      setScanToCompareWithState(scan);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    }
  };

  const renderGraph = () => {
    if (!finishedScanState) {
      return <Loader loadingText={commonTranslations("loading")} />;
    }

    if (!activeCategoryFilterState) {
      return (
        <RadarGraph
          isMobile={isMobile}
          data={
            !!scanToCompareWithState
              ? zip([
                  formatScanToRadarGraphData(
                    finishedScanState,
                    Mode.FINISHED,
                    scanResultTranslations
                  ),
                  formatScanToRadarGraphData(
                    scanToCompareWithState,
                    activeQuestionnaireRoleIdFilterState
                      ? activeQuestionnaireRoleIdFilterState === ENTIRE_TEAM
                        ? Mode.TEAM_AVERAGE
                        : Mode.ROLE_AVERAGE
                      : Mode.PREVIOUS,
                    scanResultTranslations
                  ),
                ])
              : formatScanToRadarGraphData(
                  finishedScanState,
                  Mode.FINISHED,
                  scanResultTranslations
                )
          }
          keys={
            scanToCompareWithState
              ? [
                  scanResultTranslations("finishedScan"),
                  activeQuestionnaireRoleIdFilterState
                    ? activeQuestionnaireRoleIdFilterState === ENTIRE_TEAM
                      ? scanResultTranslations("averageOfEntireTeam")
                      : scanResultTranslations("averageOfSelectedRole")
                    : scanResultTranslations("previousScan"),
                ]
              : [scanResultTranslations("finishedScan")]
          }
          indexedBy="category"
          maxValue={Math.max.apply(
            Math,
            finishedScanState.scanCategoryResults.map(
              (scr) => scr.category.scale
            )
          )}
        />
      );
    }

    return (
      <BarGraph
        isMobile={isMobile}
        data={
          !!scanToCompareWithState
            ? zip([
                formatScanToBarGraphData(
                  finishedScanState,
                  activeCategoryFilterState,
                  Mode.FINISHED,
                  scanResultTranslations
                ),
                formatScanToBarGraphData(
                  scanToCompareWithState,
                  activeCategoryFilterState,
                  activeQuestionnaireRoleIdFilterState
                    ? activeQuestionnaireRoleIdFilterState === ENTIRE_TEAM
                      ? Mode.TEAM_AVERAGE
                      : Mode.ROLE_AVERAGE
                    : Mode.PREVIOUS,
                  scanResultTranslations
                ),
              ])
            : formatScanToBarGraphData(
                finishedScanState,
                activeCategoryFilterState,
                Mode.FINISHED,
                scanResultTranslations
              )
        }
        keys={
          scanToCompareWithState
            ? [
                scanResultTranslations("finishedScan"),
                activeQuestionnaireRoleIdFilterState
                  ? activeQuestionnaireRoleIdFilterState === ENTIRE_TEAM
                    ? scanResultTranslations("averageOfEntireTeam")
                    : scanResultTranslations("averageOfSelectedRole")
                  : scanResultTranslations("previousScan"),
              ]
            : [scanResultTranslations("finishedScan")]
        }
        indexedBy="step"
        maxValue={activeCategoryFilterState.scale}
      />
    );
  };

  useEffect(() => {
    if (userRole.role === Role.Researcher) {
      navigate("/");
    } else {
      if (scanUuid) {
        getScanResult(scanUuid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanUuid]);

  useEffect(() => {
    if (activeQuestionnaireRoleIdFilterState && scanUuid) {
      getFilteredScansAveragetoCompareWith(
        scanUuid,
        activeQuestionnaireRoleIdFilterState ?? null
      );
    } else {
      setScanToCompareWithState(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionnaireRoleIdFilterState, scanUuid]);

  if (isScanLoadingState) {
    return <Loader />;
  }

  if (hasErrorState || !finishedScanState) {
    return <Error />;
  }

  if (
    !finishedScanState.scanCategoryResults ||
    !finishedScanState.scanStepResults
  ) {
    return (
      <div className="scan-result-page-container page">
        <div className="scan-result-page-container__header">
          <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
            <Link color="inherit" href="/scans">
              <CreateIcon />
              {scanResultTranslations("createScan")}
            </Link>
            <Link color="inherit" href={`/scans/${scanUuid}`}>
              {scanResultTranslations("questionnaire", {
                questionnaire: finishedScanState.questionnaire.name,
              })}
            </Link>
            <Link className="active" color="textPrimary" aria-current="page">
              {scanResultTranslations("scanResult")}
            </Link>
          </Breadcrumbs>
        </div>
        <Grid
          container
          className="scan-result-page-container__content"
          spacing={isMobile ? 0 : 3}
        >
          <Grid item xs={12}>
            <Paper className="scan-result-page-container__paper graph">
              <span>{scanResultTranslations("thanks")}</span>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="scan-result-page-container page">
      <div className="scan-result-page-container__header">
        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
          <Link color="inherit" href="/scans">
            <CreateIcon />
            {scanResultTranslations("createScan")}
          </Link>
          <Link color="inherit" href={`/scans/${scanUuid}`}>
            {scanResultTranslations("questionnaire", {
              questionnaire: finishedScanState.questionnaire.name,
            })}
          </Link>
          <Link className="active" color="textPrimary" aria-current="page">
            {scanResultTranslations("scanResult")}
          </Link>
        </Breadcrumbs>
        <IconButton
          onClick={() => {
            window.print();
          }}
        >
          <PrintIcon />
        </IconButton>
      </div>
      <Grid
        container
        className="scan-result-page-container__content"
        spacing={isMobile ? 0 : 3}
      >
        {!finishedScanState.scanCategoryResults ||
        !finishedScanState.scanStepResults ? (
          <Grid item xs={12}>
            <Paper className="scan-result-page-container__paper">
              <span>{scanResultTranslations("thanks")}</span>
            </Paper>
          </Grid>
        ) : (
          <>
            <Grid item md={9} xs={12} className="graph-container">
              <Paper className="scan-result-page-container__paper">
                <div className="scan-result-page-container__paper__title-container with-subtitles">
                  <span className="scan-result-page-container__paper__title">
                    {scanResultTranslations("title", {
                      questionnaire: finishedScanState.questionnaire.name,
                    })}
                  </span>
                  {finishedScanState.questionnaireRole && (
                    <>
                      <span className="scan-result-page-container__paper__sub-title">
                        {scanResultTranslations("scanTimestamps", {
                          start: DateTime.fromISO(
                            finishedScanState.endDate!
                          ).toFormat("dd-MM-yyyy"),
                          end: DateTime.fromISO(
                            finishedScanState.endDate!
                          ).toFormat("HH:mm"),
                        })}
                      </span>
                      <span className="scan-result-page-container__paper__sub-title">
                        {scanResultTranslations("asRole", {
                          role: finishedScanState.questionnaireRole.name,
                        })}
                      </span>
                    </>
                  )}
                  <div className="spacer" />
                </div>
                <div className="scan-result-page-container__paper__radar">
                  {renderGraph()}
                </div>
              </Paper>
            </Grid>
            <Grid item md={3} xs={12} className="filters-container">
              <Paper className="scan-result-page-container__paper filters">
                <div className="scan-result-page-container__paper__title-container wrapper">
                  <span className="scan-result-page-container__paper__title">
                    {scanResultTranslations("filters")}
                  </span>
                  <Button
                    disableElevation
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setActiveCategoryFilterState(undefined);
                      setActiveQuestionnaireRoleIdFilterState(undefined);
                    }}
                  >
                    {scanResultTranslations("reset")}
                  </Button>
                </div>
                <TextField
                  select
                  fullWidth
                  className="filters__filter"
                  variant="outlined"
                  label={scanResultTranslations("categories")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  value={
                    activeCategoryFilterState
                      ? activeCategoryFilterState.id
                      : ""
                  }
                  onChange={(event) => {
                    const value = event.target.value;
                    setActiveCategoryFilterState(
                      categoriesState.find((c) => c.id === Number(value))
                    );
                  }}
                >
                  <MenuItem value={""}>
                    <i>{scanResultTranslations("none")}</i>
                  </MenuItem>
                  {categoriesState.length && (
                    <ListSubheader>
                      {scanResultTranslations("perCategory")}
                    </ListSubheader>
                  )}
                  {categoriesState.map((filter) => (
                    <MenuItem key={filter.id} value={filter.id}>
                      {filter.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  fullWidth
                  className="filters__filter"
                  variant="outlined"
                  label={scanResultTranslations("roles")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  value={activeQuestionnaireRoleIdFilterState ?? ""}
                  onChange={(event) => {
                    const questionnaireRoleId = event.target.value;
                    setActiveQuestionnaireRoleIdFilterState(
                      questionnaireRoleId
                    );
                  }}
                >
                  <MenuItem value={""}>
                    <i>Geen</i>
                  </MenuItem>
                  <MenuItem value={ENTIRE_TEAM}>
                    {scanResultTranslations("entireTeam")}
                  </MenuItem>
                  {questionnaireRolesState.length && (
                    <ListSubheader>
                      {scanResultTranslations("perRole")}
                    </ListSubheader>
                  )}
                  {questionnaireRolesState.map((filter) => (
                    <MenuItem key={filter.id} value={filter.id}>
                      {filter.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
      {finishedScanState.questionnaire.scanExplanation && (
        <Paper className="scan-result-page-container__paper explanation-container">
          {createElement("div", {
            dangerouslySetInnerHTML: {
              __html: finishedScanState.questionnaire.scanExplanation,
            },
          })}
        </Paper>
      )}
    </div>
  );
}
