import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import { Outlet } from "react-router-dom";
import { SideMenuComponent } from "./side-menu/SideMenu.component";
import { useUser } from "../common/providers/User.provider";
import { Box, Button, Divider, Link } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./Dashboard.component.sass";
import { UserRole } from "../../models/user-role/userRole";
import Popover from "@mui/material/Popover";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DoneIcon from "@mui/icons-material/Done";
import { UserChangePasswordDialogComponent } from "./pages/user/dialog/user-change-password-dialog/UserChangePasswordDialog.component";
import { User } from "../../models/user/user";
import { convertRoleToText } from "../../lib/convertRoleToText";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import DutchFlag from "../../assets/dutch.png";
import EnglishFlag from "../../assets/english.png";

export function DashboardComponent() {
  const { t: dashboardTranslations } = useTranslation("dashboard", {
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const theme = useTheme();
  const { user, userRole: currentUserRole, setUserRole } = useUser();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorElUserMenu, setAnchorElUserMenu] =
    React.useState<null | HTMLElement>(null);
  const [anchorElLanguageMenu, setAnchorElLanguageMenu] =
    React.useState<null | HTMLElement>(null);

  const [userToChangePasswordState, setUserToChangePasswordState] = useState<
    User | undefined
  >(undefined);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUserMenu(event.currentTarget);
  };

  const handleOpenLanguageMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElLanguageMenu(event.currentTarget);
  };

  const handleCloseUserMenu = (userRole: UserRole | undefined) => {
    setAnchorElUserMenu(null);

    if (userRole && userRole.id !== currentUserRole.id) {
      setUserRole(userRole);
      window.location.reload();
    }
  };

  const handleCloseLanguageMenu = (language: string | undefined) => {
    setAnchorElLanguageMenu(null);

    if (language) {
      i18n.changeLanguage(language);
    }
  };

  const handleCloseUserChangePasswordDialog = () => {
    setUserToChangePasswordState(undefined);
  };

  const languageToText = (language: string) => {
    switch (language) {
      case "en":
        return commonTranslations("english");
      case "nl":
        return commonTranslations("dutch");
    }
  };

  const languageToFlag = (language: string) => {
    const style = {
      height: "32px",
      width: "32px",
      marginRight: "5px",
    };

    switch (language) {
      case "en":
        return <img style={style} src={EnglishFlag} alt="Engelse vlag" />;
      case "nl":
        return <img style={style} src={DutchFlag} alt="Nederlandse vlag" />;
    }
  };

  return (
    <>
      <UserChangePasswordDialogComponent
        open={!!userToChangePasswordState}
        user={userToChangePasswordState}
        handleClose={handleCloseUserChangePasswordDialog}
      />
      <div className="root">
        <CssBaseline />
        <AppBar className="header-container" position="fixed">
          <Toolbar className="toolbar">
            <IconButton
              className="menu-button"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              className="title"
              variant="h6"
              style={{
                paddingLeft: !mobileOpen ? "180px" : undefined,
              }}
              noWrap
            >
              Questionnaire Tool
            </Typography>
            <div className="spacer"></div>
            <div className="user-container row">
              <div className="column">
                <Button
                  className="button"
                  color="inherit"
                  style={{ color: "black" }}
                  variant="text"
                  disableElevation
                  aria-controls="language-menu"
                  onClick={handleOpenLanguageMenu}
                >
                  {languageToFlag(i18n.language)}
                  {!mobileOpen && languageToText(i18n.language)}
                  <ArrowDropDownIcon />
                </Button>

                <Popover
                  id="language-menu"
                  anchorEl={anchorElLanguageMenu}
                  keepMounted
                  open={Boolean(anchorElLanguageMenu)}
                  onClose={() => handleCloseLanguageMenu(undefined)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <List component="div">
                    {["nl", "en"].map((language, index) => (
                      <ListItem
                        key={index}
                        style={{
                          cursor: "pointer",
                          padding: "8px",
                        }}
                        selected={i18n.language === language}
                        onClick={() => handleCloseLanguageMenu(language)}
                      >
                        {languageToFlag(language)}
                        {!mobileOpen && (
                          <ListItemText>
                            {languageToText(language)}
                          </ListItemText>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Popover>
              </div>
              <div className="column">
                <Button
                  className="button"
                  color="secondary"
                  variant="contained"
                  disableElevation
                  aria-controls="user-menu"
                  onClick={handleOpenUserMenu}
                >
                  {user.email}
                  <ArrowDropDownIcon />
                </Button>

                <Popover
                  id="user-menu"
                  anchorEl={anchorElUserMenu}
                  keepMounted
                  open={Boolean(anchorElUserMenu)}
                  onClose={() => handleCloseUserMenu(undefined)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className="inner-container row">
                    <div className="column">
                      <AccountCircleIcon className="icon" />
                    </div>
                    <div className="column">
                      <span className="email">{user.email}</span>
                      <div className="row">
                        <Link
                          onClick={() => setUserToChangePasswordState(user)}
                        >
                          {dashboardTranslations("changePassword")}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <Divider light />
                  <List
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        {dashboardTranslations("roles")}
                      </ListSubheader>
                    }
                  >
                    {user.userRoles.map((userRole, index) => (
                      <ListItem
                        className="user-container__role-item"
                        key={index}
                        selected={userRole.id === currentUserRole.id}
                        onClick={() => handleCloseUserMenu(userRole)}
                      >
                        <ListItemIcon>
                          {userRole.id === currentUserRole.id ? (
                            <DoneIcon />
                          ) : (
                            <Box />
                          )}
                        </ListItemIcon>
                        <ListItemText>
                          {userRole.team.name} -{" "}
                          {convertRoleToText(userRole.role, commonTranslations)}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Popover>
                <span className="team">
                  {currentUserRole.team.name} -{" "}
                  {convertRoleToText(currentUserRole.role, commonTranslations)}
                </span>
              </div>
              <div className="column">
                <IconButton
                  aria-controls="user-menu"
                  onClick={handleOpenUserMenu}
                  size="small"
                >
                  <AccountCircleIcon className="icon" />
                </IconButton>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <nav className="menu-container menu" aria-label="mailbox folders">
          <Hidden smUp>
            <Drawer
              className="drawer"
              style={{ width: "210px" }}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
            >
              <SideMenuComponent />
            </Drawer>
          </Hidden>
          <Hidden lgDown>
            <Drawer variant="permanent" open>
              <SideMenuComponent />
            </Drawer>
          </Hidden>
        </nav>
        <div className="content-container">
          <Outlet />
        </div>
      </div>
    </>
  );
}
