import React, { useState, useEffect } from "react";
import { Questionnaire } from "../../../../../../models/questionnaire/questionnaire";
import { Loader } from "../../../../../common/loader/Loader.component";
import { Error } from "../../../../../common/error/Error.component";
import { QuestionnaireListItemComponent } from "../questionnaire-list-item/QuestionnaireListItem.component";
import {
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Breadcrumbs,
  Link,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./QuestionnaireList.component.sass";
import AddIcon from "@mui/icons-material/Add";
import { QuestionnaireAddDialogComponent } from "../dialog/questionnaire-add-dialog/QuestionnaireAddDialog.component";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { QuestionnaireDeleteDialogComponent } from "../dialog/questionnaire-delete-dialog/QuestionnaireDeleteDialog.component";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { useNavigate } from "react-router-dom";
import { QuestionnaireNewCycleDialogComponent } from "../dialog/questionnaire-new-cycle-dialog/QuestionnaireNewCycleDialog.component";
import { QuestionnaireCopyDialogComponent } from "../dialog/questionnaire-copy-dialog/QuestionnaireCopyDialog.component";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useUser } from "../../../../../common/providers/User.provider";
import { Role } from "../../../../../../models/user-role/role";
import { getTeamQuestionnaireFromQuestionnaire } from "../../../../../../lib/getTeamQuestionnaire";
import { generateLink } from "../../../../../../lib/generateLink";
import { QuestionnaireShareDialogComponent } from "../dialog/questionnaire-share-dialog/QuestionnaireShareDialog.component";
import { useTranslation } from "react-i18next";

export function QuestionnaireListComponent() {
  const { t: questionnairesTranslations } = useTranslation(
    "questionnaires-page",
    {
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { userRole } = useUser();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [isErrorState, setIsErrorState] = useState<boolean>(false);
  const [searchTermState, setSearchTermState] = useState<string>("");
  const [
    isQuestionnaireAddDialogOpenState,
    setIsQuestionnaireAddDialogOpenState,
  ] = useState<boolean>(false);
  const [questionnaireToDeleteState, setQuestionnaireToDeleteState] = useState<
    Questionnaire | undefined
  >(undefined);
  const [questionnairesState, setQuestionnairesState] = useState<
    Questionnaire[]
  >([]);
  const [filteredQuestionnairesState, setFilteredQuestionnaireState] = useState<
    Questionnaire[]
  >([]);

  const [questionnaireToCopyState, setQuestionnaireToCopyState] = useState<
    Questionnaire | undefined
  >(undefined);
  const [questionnaireToShareState, setQuestionnaireToShareState] = useState<
    Questionnaire | undefined
  >(undefined);
  const [questionnaireNewCycleState, setQuestionnaireNewCycleState] = useState<
    { questionnaireId: number; cycle: number } | undefined
  >(undefined);

  const getQuestionnaires = async () => {
    try {
      setIsLoadingState(true);
      const response = await api.questionnaires();

      setQuestionnairesStates(response.data);
    } catch (e) {
      console.error(e);
      setIsErrorState(true);
    } finally {
      setIsLoadingState(false);
    }
  };

  const togglePublished = async (
    questionnaireId: number,
    published: boolean
  ) => {
    try {
      const response = await api.questionnairesSetPublished(
        questionnaireId,
        published
      );

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      setQuestionnairesStates(
        questionnairesState.map((q) =>
          q.id === questionnaireId ? ({ ...q, published } as Questionnaire) : q
        )
      );
    } catch (e) {
      console.error(e);
      setIsErrorState(true);
    }
  };

  const navigateToQuestionnaire = (questionnaireId: number) => {
    setTimeout(() => {
      navigate(`/questionnaires/${questionnaireId}`);
    });
  };

  const onChangeSearchTerm = (event: { target: { value: string } }) => {
    const searchTerm = event.target.value.toLowerCase();

    setSearchTermState(searchTerm);

    setFilteredQuestionnaireState(
      questionnairesState.filter(
        (q) =>
          q.id.toString().toLowerCase().includes(searchTerm) ||
          q.name.toLowerCase().includes(searchTerm) ||
          q.description.toLowerCase().includes(searchTerm)
      )
    );
  };

  const handleCloseQuestionnaireAddDialog = (result?: Questionnaire) => {
    console.log(result);
    if (!!result) {
      setQuestionnairesStates([result, ...questionnairesState]);
      setSearchTermState("");
    }

    setIsQuestionnaireAddDialogOpenState(false);
  };

  const handleCloseQuestionnaireDeleteDialog = (result?: number) => {
    if (!!result) {
      setQuestionnairesStates(
        questionnairesState.filter((q) => q.id !== result)
      );
      setSearchTermState("");
    }

    setQuestionnaireToDeleteState(undefined);
  };

  const handleCloseQuestionnaireNewCycleDialog = (
    questionnaireId?: number,
    cycle?: number
  ) => {
    setQuestionnaireNewCycleState(undefined);

    if (questionnaireId !== undefined && cycle !== undefined) {
      setQuestionnairesState((prev) =>
        prev.map((q) => {
          if (q.id === questionnaireId) {
            const tq = getTeamQuestionnaireFromQuestionnaire(
              questionnairesState.find((q) => q.id === questionnaireId)!,
              userRole.teamId
            );
            if (tq) {
              const index = q.teamQuestionnaires.findIndex(
                (teamQuestionnaire) =>
                  teamQuestionnaire.questionnaireId === q.id
              );
              if (index !== -1) {
                q.teamQuestionnaires[index].cycle = cycle;
              }
            }
          }

          return q;
        })
      );
    }
  };

  const handleCloseQuestionnaireCopyDialog = (result?: number) => {
    setQuestionnaireToCopyState(undefined);

    if (!!result) {
      navigateToQuestionnaire(result);
    }
  };

  const handleCloseQuestionnaireShareDialog = (
    questionnaire?: Questionnaire
  ) => {
    setQuestionnaireToShareState(undefined);

    if (!!questionnaire) {
      generateLink({
        uuid: getTeamQuestionnaireFromQuestionnaire(
          questionnaire,
          userRole.teamId
        )!.uuid,
        onSuccess: () =>
          snackbar.enqueueSnackbar(
            commonTranslations("linkCopied"),
            getSnackbarOptions({ variant: "success" })
          ),
        onError: (e) =>
          snackbar.enqueueSnackbar(e, getSnackbarOptions({ variant: "error" })),
      });
    }
  };

  const setQuestionnairesStates = (questionnaires: Questionnaire[]) => {
    setQuestionnairesState(questionnaires);
    setFilteredQuestionnaireState(questionnaires);
  };

  const renderQuestionnaires = () => {
    if (isLoadingState) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    }

    if (!questionnairesState.length) {
      return (
        <TableRow>
          <TableCell colSpan={5} align="center">
            {questionnairesTranslations("empty")}
          </TableCell>
        </TableRow>
      );
    }

    return filteredQuestionnairesState.map((row, index) => (
      <QuestionnaireListItemComponent
        key={index}
        userRole={userRole}
        questionnaire={row}
        setQuestionnaireToDelete={setQuestionnaireToDeleteState}
        setQuestionnaireToCopy={setQuestionnaireToCopyState}
        setQuestionnaireToEdit={(questionnaireId: number) => {
          navigateToQuestionnaire(questionnaireId);
        }}
        setQuestionnaireNewCycle={setQuestionnaireNewCycleState}
        setQuestionnaireShare={setQuestionnaireToShareState}
        togglePublished={togglePublished}
      />
    ));
  };

  useEffect(() => {
    getQuestionnaires();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingState) {
    return <Loader />;
  }

  if (isErrorState) {
    return <Error />;
  }

  return (
    <>
      <QuestionnaireAddDialogComponent
        open={isQuestionnaireAddDialogOpenState}
        handleClose={handleCloseQuestionnaireAddDialog}
      />
      <QuestionnaireDeleteDialogComponent
        open={!!questionnaireToDeleteState}
        questionnaire={questionnaireToDeleteState}
        handleClose={handleCloseQuestionnaireDeleteDialog}
      />
      <QuestionnaireCopyDialogComponent
        open={!!questionnaireToCopyState}
        questionnaire={questionnaireToCopyState}
        handleClose={handleCloseQuestionnaireCopyDialog}
      />
      <QuestionnaireNewCycleDialogComponent
        open={!!questionnaireNewCycleState}
        questionnaireId={questionnaireNewCycleState?.questionnaireId}
        cycle={questionnaireNewCycleState?.cycle}
        handleClose={handleCloseQuestionnaireNewCycleDialog}
      />
      <QuestionnaireShareDialogComponent
        open={!!questionnaireToShareState}
        questionnaire={questionnaireToShareState}
        handleClose={handleCloseQuestionnaireShareDialog}
      />

      <div className={"questionnaire-list-container"}>
        <div className={"questionnaire-list-container__information"}>
          <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
            <Link className={"active"} color="textPrimary" aria-current="page">
              <AssignmentIcon />
              {questionnairesTranslations("title")}
            </Link>
          </Breadcrumbs>
          <div
            className={"questionnaire-list-container__information__statistics"}
          >
            <span>
              {questionnairesTranslations("searchResults", {
                length: filteredQuestionnairesState.length,
              })}
            </span>
          </div>
        </div>
        <div className="questionnaire-list-container__search">
          <form noValidate autoComplete="off">
            <FormControl className="questionnaire-list-container__search__control">
              <OutlinedInput
                className="questionnaire-list-container__search__control__input"
                type="text"
                placeholder={questionnairesTranslations("placeholder")}
                onChange={(event) => onChangeSearchTerm(event)}
                fullWidth={true}
                value={searchTermState}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search for results" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
          {userRole.role === Role.Admin && (
            <Tooltip
              title={questionnairesTranslations("addQuestionnaire")}
              placement="top"
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsQuestionnaireAddDialogOpenState(true)}
              >
                <AddIcon fontSize="large" />
              </Button>
            </Tooltip>
          )}
        </div>
        <TableContainer
          className={"questionnaire-list-container__table"}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{questionnairesTranslations("table.id")}</TableCell>
                <TableCell>
                  {questionnairesTranslations("table.questionnaireName")}
                </TableCell>
                <TableCell>
                  {questionnairesTranslations("table.revision")}
                </TableCell>
                <TableCell>
                  {questionnairesTranslations("table.cycle")}
                </TableCell>
                <TableCell>
                  {questionnairesTranslations("table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderQuestionnaires()}</TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
