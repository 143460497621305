import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import { Loader } from "../../../../../common/loader/Loader.component";
import "./Teams.component.sass";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import { useApi } from "../../../../../common/providers/Api.provider";
import { Team } from "../../../../../../models/team/team";
import { TeamAssignDialogComponent } from "../dialog/team-assign-dialog/TeamAssignDialog.component";
import { TeamUnassignDialogComponent } from "../dialog/team-unassign-dialog/TeamUnassignDialog.component";
import { TeamUpdateCycleDialogComponent } from "../dialog/team-update-cycle-dialog/TeamUpdateCycleDialog.component";
import { getTeamQuestionnaireFromTeam } from "../../../../../../lib/getTeamQuestionnaire";
import { useTranslation } from "react-i18next";

export interface TeamsComponentProps {
  questionnaireId: string | undefined;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function TeamsComponent({
  questionnaireId,
  setHasError,
}: TeamsComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "teams",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const [isTeamsLoadingState, setIsTeamsLoadingState] =
    useState<boolean>(false);
  const [teamsState, setTeamsState] = useState<Team[]>([]);
  const [teamtoUpdateCycleState, setTeamToUpdateCycleState] = useState<
    Team | undefined
  >(undefined);
  const [isTeamAddDialogOpenState, setIsTeamAddDialogOpenState] =
    useState<boolean>(false);

  const [teamIdToDeleteState, setTeamIdToDeleteState] = useState<
    number | undefined
  >(undefined);

  const getTeams = async (questionnaireId: number) => {
    try {
      setIsTeamsLoadingState(true);
      const response = await api.teamsByQuestionnaireId(questionnaireId);

      setTeamsState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsTeamsLoadingState(false);
    }
  };

  useEffect(() => {
    if (!!questionnaireId) {
      getTeams(Number(questionnaireId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  const handleCloseTeamAddDialog = (result?: Team) => {
    if (!!result) {
      setTeamsState([...teamsState, result]);
    }

    setIsTeamAddDialogOpenState(false);
  };

  const handleCloseTeamDeleteDialog = (result?: number) => {
    if (!!result) {
      setTeamsState(teamsState.filter((t) => t.id !== result));
    }

    setTeamIdToDeleteState(undefined);
  };

  const handleCloseCycleUpdateDialog = (teamId?: number, cycle?: number) => {
    if (teamId !== undefined && cycle !== undefined) {
      setTeamsState(
        teamsState.map((team) => {
          if (teamId === team.id) {
            const tq = getTeamQuestionnaireFromTeam(
              team,
              Number(questionnaireId)
            );
            if (tq) {
              const index = team.teamQuestionnaires.findIndex(
                (teamQuestionnaire) => teamQuestionnaire.uuid === tq.uuid
              );

              if (index !== -1) {
                team.teamQuestionnaires[index].cycle = cycle;
              }
            }
          }

          return team;
        })
      );
    }

    setTeamToUpdateCycleState(undefined);
  };

  const renderCycle = (team: Team) => {
    return (
      team.teamQuestionnaires.find((tq) => tq.teamId === team.id)?.cycle ?? 0
    );
  };

  const renderRows = () => {
    if (teamsState.length === 0) {
      return (
        <TableRow className="empty-row">
          <TableCell colSpan={4}>
            <span>{questionnaireTranslations("noConnectedTeams")}</span>
          </TableCell>
        </TableRow>
      );
    }

    return teamsState.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{renderCycle(row)}</TableCell>
        <TableCell>
          <Tooltip
            title={questionnaireTranslations("manageCycle")}
            placement="top"
          >
            <IconButton
              onClick={() => {
                setTeamToUpdateCycleState(row);
              }}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={commonTranslations("remove")} placement="top">
            <IconButton onClick={() => setTeamIdToDeleteState(row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Paper className="teams-container">
      {isTeamsLoadingState ? (
        <Loader loadingText={commonTranslations("loading")} />
      ) : (
        <>
          <TeamUpdateCycleDialogComponent
            open={!!teamtoUpdateCycleState}
            team={teamtoUpdateCycleState}
            handleClose={handleCloseCycleUpdateDialog}
            questionnaireId={Number(questionnaireId)}
          />
          <TeamAssignDialogComponent
            open={isTeamAddDialogOpenState}
            handleClose={handleCloseTeamAddDialog}
            questionnaireId={Number(questionnaireId)}
            teams={teamsState.map((t) => t.id)}
          />
          <TeamUnassignDialogComponent
            open={!!teamIdToDeleteState}
            questionnaireId={Number(questionnaireId)}
            teamId={teamIdToDeleteState}
            handleClose={handleCloseTeamDeleteDialog}
          />
          <div className="teams-container__title-container">
            <span className="title">{questionnaireTranslations("title")}</span>
            <Button
              className="button"
              variant="contained"
              color="secondary"
              onClick={() => setIsTeamAddDialogOpenState(true)}
            >
              <AddIcon />
              {questionnaireTranslations("connectTeam")}
            </Button>
          </div>
          <TableContainer className="teams-list-container__table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{questionnaireTranslations("table.id")}</TableCell>
                  <TableCell>
                    {questionnaireTranslations("table.name")}
                  </TableCell>
                  <TableCell>
                    {questionnaireTranslations("table.cycle")}
                  </TableCell>
                  <TableCell>
                    {questionnaireTranslations("table.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderRows()}</TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
}
