import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import {
  Breadcrumbs,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import { Scan } from "../../../../../models/scan/scan";
import { Error } from "../../../../common/error/Error.component";
import { Loader } from "../../../../common/loader/Loader.component";
import "./ScanList.component.sass";
import AddIcon from "@mui/icons-material/Add";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibleIcon from "@mui/icons-material/Visibility";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { ScanAddDialogComponent } from "../dialog/scan-add-dialog/ScanAddDialog.component";
import { ScanDeleteDialogComponent } from "../dialog/scan-delete-dialog/ScanDeleteDialog.component";
import { useNavigate, useLocation } from "react-router-dom";
import { useApi } from "../../../../common/providers/Api.provider";
import { useUser } from "../../../../common/providers/User.provider";
import { Role } from "../../../../../models/user-role/role";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

export function ScanListComponent() {
  const { t: scansTranslations } = useTranslation("scans-page", {
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole } = useUser();

  // Draft
  const [draftAnchorEl, setDraftAnchorEl] = useState<null | HTMLElement>(null);
  const [isScanDraftsLoadingState, setIsScanDraftsLoadingState] =
    useState<boolean>(false);
  const [scanDraftsState, setScanDraftsState] = useState<Scan[]>([]);

  // History
  const [historyAnchorEl, setHistoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [isScanHistoryLoadingState, setIsScanHistoryLoadingState] =
    useState<boolean>(false);
  const [scanHistoryState, setScanHistoryState] = useState<Scan[]>([]);

  // Other hooks
  const [menuScanState, setMenuScanState] = useState<Scan | undefined>(
    undefined
  );
  const [isErrorState, setIsErrorState] = useState<boolean>(false);
  const [isScanAddDialogOpenState, setIsScanAddDialogOpenState] =
    useState<boolean>(false);
  const [scanToDeleteState, setScanToDeleteState] = useState<Scan | undefined>(
    undefined
  );
  const [invitedQuestionnaireIdState, setInvitedQuestionnaireIdState] =
    useState<number>(0);

  const navigateToScanDetailPage = (scanUuid: string, viewMode = false) => {
    setTimeout(() => {
      navigate(`/scans/${scanUuid}?viewMode=${viewMode}`);
    });
  };

  const navigateToScanResultPage = (scanUuid: string) => {
    setTimeout(() => {
      navigate(`/scans/${scanUuid}/result`);
    });
  };

  const addScan = async (inviteUuid: string) => {
    const { data: questionnaire } = await api.questionnaireByInviteUuid(
      inviteUuid
    );
    if (questionnaire) {
      setInvitedQuestionnaireIdState(questionnaire.id);
      setIsScanAddDialogOpenState(true);
    }
  };

  const handleCloseScanAddDialog = (result?: Scan) => {
    setIsScanAddDialogOpenState(false);

    if (!!result) {
      navigateToScanDetailPage(result.uuid);
    }
  };

  const handleCloseScanDeleteDialog = (result?: number) => {
    if (!!result) {
      setScanDraftsState(scanDraftsState.filter((s) => s.id !== result));
    }

    setScanToDeleteState(undefined);
  };

  const renderScanDrafts = () => {
    if (isScanDraftsLoadingState) {
      return (
        <TableRow className="no-data-draft-row">
          <TableCell colSpan={5}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    }

    if (scanDraftsState === undefined || !scanDraftsState.length) {
      return (
        <TableRow className="no-data-draft-row">
          <TableCell colSpan={5} align="center">
            {scansTranslations("draftsEmpty")}
          </TableCell>
        </TableRow>
      );
    }

    return scanDraftsState.map((row, index) => (
      <TableRow className="draft-row" key={index}>
        {userRole.role === Role.Admin && <TableCell>{row.id}</TableCell>}
        <TableCell className="text-fit">{row.questionnaire.name}</TableCell>
        <TableCell>{row.questionnaire.revision}</TableCell>
        <TableCell>
          {`${DateTime.fromISO(row.startDate).toFormat(
            "dd-MM-yyyy"
          )} om ${DateTime.fromISO(row.startDate).toFormat("HH:mm")}`}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="opties"
            aria-controls="draft-menu"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setDraftAnchorEl(event.currentTarget);
              setMenuScanState(row);
            }}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  const renderScanHistory = () => {
    if (isScanHistoryLoadingState) {
      return (
        <TableRow>
          <TableCell className="no-data-history-row" colSpan={6}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    }

    if (scanHistoryState === undefined || !scanHistoryState.length) {
      return (
        <TableRow className="no-data-history-row">
          <TableCell colSpan={6} align="center">
            {scansTranslations("completedEmpty")}
          </TableCell>
        </TableRow>
      );
    }

    return scanHistoryState.map((row, index) => (
      <TableRow className="history-row" key={index}>
        {userRole.role === Role.Admin && <TableCell>{row.id}</TableCell>}
        <TableCell className="text-fit">{row.questionnaire.name}</TableCell>
        <TableCell className="text-fit">
          {row.questionnaireRole?.name || "-"}
        </TableCell>
        <TableCell>{row.questionnaire.revision}</TableCell>
        <TableCell>
          {`${DateTime.fromISO(row.startDate as any).toFormat(
            "dd-MM-yyyy"
          )} om ${DateTime.fromISO(row.startDate as any).toFormat("HH:mm")}`}
        </TableCell>
        <TableCell>
          {`${DateTime.fromISO(row.endDate!).toFormat(
            "dd-MM-yyyy"
          )} om ${DateTime.fromISO(row.endDate!).toFormat("HH:mm")}`}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="options"
            aria-controls="history-menu"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setHistoryAnchorEl(event.currentTarget);
              setMenuScanState(row);
            }}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  const getScanDrafts = async () => {
    try {
      setIsScanDraftsLoadingState(true);

      const response = await api.scansDrafts();

      setScanDraftsState(response.data);
    } catch (e) {
      console.error(e);
      setIsErrorState(true);
    } finally {
      setIsScanDraftsLoadingState(false);
    }
  };

  const getScanHistory = async () => {
    try {
      setIsScanHistoryLoadingState(true);

      const response = await api.scansHistory();

      setScanHistoryState(response.data);
    } catch (e) {
      console.error(e);
      setIsErrorState(true);
    } finally {
      setIsScanHistoryLoadingState(false);
    }
  };

  useEffect(() => {
    if (userRole.role === Role.Researcher) {
      navigate("/");
    } else {
      getScanDrafts();
      getScanHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const inviteUuid = new URLSearchParams(location.search).get("invite");
    if (inviteUuid) {
      addScan(inviteUuid);
    } else {
      setInvitedQuestionnaireIdState(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  if (isErrorState && !isScanDraftsLoadingState && !isScanHistoryLoadingState) {
    return <Error />;
  }

  return (
    <>
      {menuScanState !== undefined && (
        <>
          <Menu
            id="draft-menu"
            anchorEl={draftAnchorEl}
            keepMounted
            open={Boolean(draftAnchorEl)}
            onClose={() => {
              setDraftAnchorEl(null);
              setMenuScanState(undefined);
            }}
          >
            {userRole.role === Role.User && (
              <MenuItem
                onClick={() => navigateToScanDetailPage(menuScanState.uuid)}
              >
                <ListItemIcon>
                  <PlayCircleFilledIcon />
                </ListItemIcon>
                <ListItemText>{scansTranslations("continue")}</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={() => setScanToDeleteState(menuScanState)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>{commonTranslations("remove")}</ListItemText>
            </MenuItem>
          </Menu>
          <Menu
            id="history-menu"
            anchorEl={historyAnchorEl}
            keepMounted
            open={Boolean(historyAnchorEl)}
            onClose={() => {
              setHistoryAnchorEl(null);
              setMenuScanState(undefined);
            }}
          >
            {userRole.userId === menuScanState.createdByUserRole.userId && (
              <MenuItem
                onClick={() =>
                  navigateToScanDetailPage(menuScanState!.uuid, true)
                }
              >
                <ListItemIcon>
                  <VisibleIcon />
                </ListItemIcon>
                <ListItemText>
                  {scansTranslations("actions.answers")}
                </ListItemText>
              </MenuItem>
            )}
            {userRole.role === Role.User ||
              (userRole.teamId === menuScanState.createdByUserRole.teamId && (
                <MenuItem
                  onClick={() => navigateToScanResultPage(menuScanState.uuid)}
                >
                  <ListItemIcon>
                    <EqualizerIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {scansTranslations("actions.result")}
                  </ListItemText>
                </MenuItem>
              ))}
            <MenuItem onClick={() => setScanToDeleteState(menuScanState)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>{commonTranslations("remove")}</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
      <ScanAddDialogComponent
        open={isScanAddDialogOpenState}
        questionnaireId={invitedQuestionnaireIdState}
        handleClose={handleCloseScanAddDialog}
      />
      <ScanDeleteDialogComponent
        open={!!scanToDeleteState}
        scan={scanToDeleteState}
        handleClose={handleCloseScanDeleteDialog}
      />
      <div className={"scan-list-container"}>
        <div className={"scan-list-container__header"}>
          <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
            <Link className={"active"} color="textPrimary" aria-current="page">
              <CreateIcon />
              {scansTranslations("title")}
            </Link>
          </Breadcrumbs>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsScanAddDialogOpenState(true)}
          >
            <AddIcon />
            {scansTranslations("addScan")}
          </Button>
        </div>
        <div className={"scan-list-container__drafts"}>
          <span>{scansTranslations("draftScans")}</span>
          <TableContainer
            className={"scan-list-container__drafts__table"}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow className="draft-row">
                  {userRole.role === Role.Admin && (
                    <TableCell>{scansTranslations("table.id")}</TableCell>
                  )}
                  <TableCell>
                    {scansTranslations("table.questionnaireName")}
                  </TableCell>
                  <TableCell>{scansTranslations("table.revision")}</TableCell>
                  <TableCell>{scansTranslations("table.startDate")}</TableCell>
                  <TableCell>{scansTranslations("table.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderScanDrafts()}</TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={"scan-list-container__history"}>
          <span>{scansTranslations("completedScans")}</span>
          <TableContainer
            className={"scan-list-container__history__table"}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow className="history-row">
                  {userRole.role === Role.Admin && (
                    <TableCell>{scansTranslations("table.id")}</TableCell>
                  )}
                  <TableCell>
                    {scansTranslations("table.questionnaireName")}
                  </TableCell>
                  <TableCell>{scansTranslations("table.role")}</TableCell>
                  <TableCell>{scansTranslations("table.revision")}</TableCell>
                  <TableCell>{scansTranslations("table.startDate")}</TableCell>
                  <TableCell>{scansTranslations("table.endDate")}</TableCell>
                  <TableCell>{scansTranslations("table.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderScanHistory()}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
