import React from "react";
import { Question } from "../../../../../../../../models/question/question";
import {
  SelectData,
  SELECT_TYPE,
} from "../../../../../../../../models/question/selectData";
import {
  TextField,
  Button,
  IconButton,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { OptionData } from "../../../../../../../../models/question/optionData";
import "./SelectInput.component.sass";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";

const SELECT_TYPES: { name: string; value: SELECT_TYPE }[] = [
  {
    value: SELECT_TYPE.DROPDOWN,
    name: "Dropdown",
  },
  {
    value: SELECT_TYPE.RADIO,
    name: "Radio",
  },
  {
    value: SELECT_TYPE.MULTI,
    name: "Multiselect",
  },
];

export interface SelectInputComponentProps {
  value: Question<SelectData>;
  setValue: (value: Question<SelectData>) => void;
}

export function SelectInputComponent({
  value,
  setValue,
}: SelectInputComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "steps.questions.select",
      useSuspense: false,
    }
  );

  const emptySelectOption: OptionData = {
    label: "",
    score: 0,
  };

  const addSelectOption = () => {
    setValue({
      ...value,
      data: {
        ...value.data,
        subType: value.data.subType,
        options: [...(value.data.options || []), emptySelectOption],
      },
    });
  };

  const removeOption = (index: number) => {
    const options = value.data.options;

    options.splice(index, 1);

    setValue({
      ...value,
      data: {
        ...value.data,
        options,
      },
    });
  };

  const handleChangeOption = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: "label" | "score",
    index: number
  ) => {
    setValue({
      ...value,
      data: {
        ...value.data,
        options: value.data.options.map((option, i) =>
          index === i ? { ...option, [key]: event.target.value } : option
        ),
      },
    });
  };

  const handleChangeDefaultValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue({
      ...value,
      data: {
        ...value.data,
        defaultIndex: isNaN(parseInt(event.target.value))
          ? undefined
          : parseInt(event.target.value),
      },
    });
  };

  const handleChangeStyleValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue({
      ...value,
      data: {
        ...value.data,
        subType: event.target.value as SELECT_TYPE,
      },
    });
  };

  const handleChangeAlternative = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValue({
      ...value,
      data: {
        ...value.data,
        enableAlternative: checked,
      },
    });
  };

  const renderOptions = () => {
    const options = value.data.options;

    if (!options || !options.length) {
      return <Typography>{questionnaireTranslations("noOptions")}</Typography>;
    }

    return options.map((option, index) => (
      <div className="select-input__option" key={index}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label={questionnaireTranslations("label")}
          color="primary"
          className="select-input__option__form-control"
          value={option.label}
          onChange={(event) => handleChangeOption(event, "label", index)}
        />
        <TextField
          fullWidth
          required
          type="number"
          variant="outlined"
          label={questionnaireTranslations("score")}
          color="primary"
          className="select-input__option__form-control"
          value={option.score}
          onChange={(event) => handleChangeOption(event, "score", index)}
        />
        <IconButton color="secondary" onClick={() => removeOption(index)}>
          <RemoveIcon />
        </IconButton>
      </div>
    ));
  };

  return (
    <div className="select-input">
      <div className="select-input__title">
        {questionnaireTranslations("options")}
      </div>
      <div className="select-input__options">{renderOptions()}</div>
      <div className="select-input__add-option">
        <Button onClick={addSelectOption} color="secondary">
          <AddIcon />
          {questionnaireTranslations("addOption")}
        </Button>
      </div>
      <div className="select-input__default-value">
        <TextField
          select
          fullWidth
          required
          variant="outlined"
          label="Standaard waarde"
          value={value.data.defaultIndex}
          onChange={handleChangeDefaultValue}
          InputLabelProps={{
            shrink:
              value.data.defaultIndex !== null ||
              value.data.defaultIndex !== undefined,
          }}
          SelectProps={{
            native: true,
          }}
        >
          <option value={""}>{questionnaireTranslations("empty")}</option>
          {(value.data.options || [])
            .filter((option) => !!option.label && !!option.score)
            .map((option, index) => (
              <option key={index} value={index}>
                {option.label}
              </option>
            ))}
        </TextField>
      </div>
      <div className="select-input__title">
        {questionnaireTranslations("style")}
      </div>
      <div className="select-input__default-value">
        <TextField
          select
          fullWidth
          required
          variant="outlined"
          label={questionnaireTranslations("style")}
          value={value.data.subType}
          onChange={handleChangeStyleValue}
          SelectProps={{
            native: true,
          }}
        >
          {SELECT_TYPES.map((option, index) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </TextField>
      </div>
      <div className="select-input__default-value">
        <FormControlLabel
          control={
            <Switch
              required
              checked={value.data.enableAlternative || false}
              onChange={handleChangeAlternative}
              value={value.data.enableAlternative || false}
            />
          }
          label={questionnaireTranslations("customAnswer")}
        />
      </div>
    </div>
  );
}
