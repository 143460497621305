import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../common/providers/Api.provider";
import { Team } from "../../../../../models/team/team";
import { Loader } from "../../../../common/loader/Loader.component";
import { Error } from "../../../../common/error/Error.component";
import { Breadcrumbs, Link, Tab, Tabs } from "@mui/material";
import { TabPanelComponent } from "../../../../common/tab-panel/TabPanel.component";
import GroupIcon from "@mui/icons-material/Group";
import { DetailsComponent } from "./details/Details.component";
import { UsersComponent } from "./users/Users.component";
import { useTranslation } from "react-i18next";

export function TeamDetailPageComponent() {
  const { t } = useTranslation("team-detail-page", {
    useSuspense: false,
  });
  const api = useApi();
  const { teamId } = useParams<{ teamId: string }>();
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);
  const [isTeamLoadingState, setIsTeamLoadingState] = useState<boolean>(false);
  const [teamState, setTeamState] = useState<Team | undefined>(undefined);
  const [activeTabState, setActiveTabState] = useState<number>(0);

  const getTeam = async (teamId: number) => {
    try {
      setIsTeamLoadingState(true);
      const response = await api.teamById(teamId);

      setTeamState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsTeamLoadingState(false);
    }
  };

  const onSaveChanges = (team: Team) => {
    setTeamState(team);
  };

  useEffect(() => {
    if (!!teamId) {
      const nummericTeamId = Number(teamId);
      getTeam(nummericTeamId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  if (isTeamLoadingState || !teamState) {
    return <Loader />;
  }

  if (hasErrorState || !teamId) {
    return <Error />;
  }

  return (
    <>
      <div className={"questionnaire-detail-page-container page"}>
        <Breadcrumbs className={"breadcrumbs"} aria-label="breadcrumb">
          <Link color="inherit" href="/questionnaires">
            <GroupIcon />
            {t("title")}
          </Link>
          <Link className={"active"} color="textPrimary" aria-current="page">
            {teamState.name}
          </Link>
        </Breadcrumbs>
        <div className={"questionnaire-detail-page-container__details"}>
          <Tabs
            value={activeTabState}
            onChange={(event: React.ChangeEvent<{}>, newIndex: number) => {
              setActiveTabState(newIndex);
            }}
          >
            <Tab label={t("tabs.details.title")} />
            <Tab label={t("tabs.users.title")} />
          </Tabs>
          <TabPanelComponent index={0} activeIndex={activeTabState}>
            <DetailsComponent
              teamId={teamId}
              setHasError={setHasErrorState}
              onSaveChanges={onSaveChanges}
            />
          </TabPanelComponent>
          <TabPanelComponent index={1} activeIndex={activeTabState}>
            <UsersComponent teamId={teamId} />
          </TabPanelComponent>
        </div>
      </div>
    </>
  );
}
