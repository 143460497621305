import React from "react";
import { Paper, Typography } from "@mui/material";
import "./HomePage.component.sass";
import { useTranslation } from "react-i18next";

export function HomePageComponent() {
  const { t } = useTranslation("home-page", { useSuspense: false });
  return (
    <Paper className="home-page-container">
      <Typography variant="h4" gutterBottom>
        {t("welcome")}
      </Typography>
    </Paper>
  );
}
