import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import "./Register.component.sass";
import {
  initialRegisterState,
  isRegisterStateValid,
  RegisterState,
  validateRegisterState,
} from "./RegisterState";
import { useApi } from "../common/providers/Api.provider";
import { useNavigate, useLocation } from "react-router-dom";
import { Error } from "../common/error/Error.component";
import { useUser } from "../common/providers/User.provider";
import { useTranslation } from "react-i18next";

export function RegisterComponent() {
  const { t: registerTranslations } = useTranslation("register-page", {
    useSuspense: false,
  });
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useUser();

  const [inviteUuidState, setInviteUuidState] = useState<string | null>(null);
  const [showValidationState, setShowValidationState] =
    useState<boolean>(false);
  const [registerState, setRegisterState] =
    useState<RegisterState>(initialRegisterState);

  useEffect(() => {
    if (location.search) {
      const inviteUuid = new URLSearchParams(location.search).get("invite");

      if (inviteUuid) {
        setInviteUuidState(inviteUuid);
      }
    }
  }, [location.search]);

  if (!inviteUuidState) {
    return <Error />;
  }

  return (
    <div className={"register-container"}>
      <div className={"register-container__content"}>
        <div className={"active-step"}>
          <Card className={"card"}>
            <CardContent>
              <Typography variant="h1">
                {registerTranslations("title")}
              </Typography>
              <form noValidate autoComplete="off">
                <TextField
                  required
                  fullWidth
                  label={registerTranslations("emailInput.label")}
                  variant="outlined"
                  className="form-control"
                  type="email"
                  value={registerState.email}
                  onChange={(event) => {
                    event.persist();
                    setRegisterState((prevState) =>
                      validateRegisterState(
                        {
                          ...prevState,
                          email: event.target.value,
                        },
                        registerTranslations
                      )
                    );
                  }}
                  error={!!registerState.emailError && showValidationState}
                  helperText={
                    !!registerState.emailError && showValidationState
                      ? registerState.emailError
                      : undefined
                  }
                />
                <TextField
                  required
                  fullWidth
                  label={registerTranslations("passwordInput.label")}
                  variant="outlined"
                  className="form-control"
                  type="password"
                  value={registerState.password}
                  onChange={(event) => {
                    event.persist();
                    setRegisterState((prevState) =>
                      validateRegisterState(
                        {
                          ...prevState,
                          password: event.target.value,
                        },
                        registerTranslations
                      )
                    );
                  }}
                  error={!!registerState.passwordError && showValidationState}
                  helperText={
                    !!registerState.passwordError && showValidationState
                      ? registerState.passwordError
                      : undefined
                  }
                />
                <TextField
                  required
                  fullWidth
                  label={registerTranslations("repeatPasswordInput.label")}
                  variant="outlined"
                  className="form-control"
                  type="password"
                  value={registerState.repeatPassword}
                  onChange={(event) => {
                    event.persist();
                    setRegisterState((prevState) =>
                      validateRegisterState(
                        {
                          ...prevState,
                          repeatPassword: event.target.value,
                        },
                        registerTranslations
                      )
                    );
                  }}
                  error={
                    !!registerState.repeatPasswordError && showValidationState
                  }
                  helperText={
                    !!registerState.repeatPasswordError && showValidationState
                      ? registerState.repeatPasswordError
                      : undefined
                  }
                />
              </form>
            </CardContent>
          </Card>
        </div>
        <div className="button-group">
          <div className="spacer" />
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              setRegisterState(
                validateRegisterState(registerState, registerTranslations)
              );
              if (!isRegisterStateValid(registerState)) {
                setShowValidationState(true);
                return;
              }

              setShowValidationState(false);

              const registerResponse = await api.usersRegister({
                email: registerState.email,
                password: registerState.password,
                teamQuestionnaireUuid: inviteUuidState,
              });

              if (registerResponse.success) {
                const { success, data: user } = await api.login({
                  username: registerState.email,
                  password: registerState.password,
                });

                if (success) {
                  setUser(user);
                  navigate(`/invite/${inviteUuidState}`);
                }
              }
            }}
          >
            {registerTranslations("register")}
          </Button>
        </div>
      </div>
    </div>
  );
}
