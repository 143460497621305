import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { getSnackbarOptions } from "../../../../../../../../lib/getSnackbarOptions";
import { QuestionnaireRole } from "../../../../../../../../models/questionnaire-role/questionnaireRole";
import { QuestionnaireRoleAddDto } from "../../../../../../../../models/questionnaire/questionnaireRoleAddDto";
import { useApi } from "../../../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface QuestionnaireRoleAddDialogComponentProps {
  open: boolean;
  questionnaireId: string;
  handleClose: (result?: QuestionnaireRole) => void;
}

export function QuestionnaireRoleAddDialogComponent({
  open,
  questionnaireId,
  handleClose,
}: QuestionnaireRoleAddDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "roles.dialogs.add",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();
  const emptyState = { name: "", questionnaireId: Number(questionnaireId) };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [questionnaireRoleAddDtoState, setQuestionnaireRoleAddDtoState] =
    useState<QuestionnaireRoleAddDto>(emptyState);

  const handleChange = (questionnaireRoleAddDto: QuestionnaireRoleAddDto) => {
    setQuestionnaireRoleAddDtoState(questionnaireRoleAddDto);
  };

  const handleAdd = async (
    questionnaireRoleAddDto: QuestionnaireRoleAddDto
  ) => {
    if (
      !questionnaireRoleAddDto.name.length ||
      questionnaireRoleAddDto.questionnaireId === null
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnaireRoleAdd(questionnaireRoleAddDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setQuestionnaireRoleAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open} className={"questionnaire-add-dialog-container"}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>{questionnaireTranslations("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {questionnaireTranslations("description")}
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="name"
          label={questionnaireTranslations("name")}
          type="text"
          value={questionnaireRoleAddDtoState.name}
          onChange={(event) =>
            handleChange({
              ...questionnaireRoleAddDtoState,
              name: event.target.value,
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          {commonTranslations("cancel")}
        </Button>
        <Button
          onClick={() => handleAdd(questionnaireRoleAddDtoState)}
          color="primary"
        >
          {commonTranslations("add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
