import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { TableContainer } from "@mui/material";
import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { User } from "../../../../../../models/user/user";
import { Loader } from "../../../../../common/loader/Loader.component";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface UsersComponentProps {
  teamId: string;
}

export function UsersComponent({ teamId }: UsersComponentProps) {
  const { t } = useTranslation("team-detail-page", {
    keyPrefix: "tabs.users",
    useSuspense: false,
  });
  const api = useApi();
  const [usersState, setUsersState] = useState<User[]>([]);
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const getTeamUsers = async (teamId: string) => {
    try {
      setIsLoadingState(true);

      const response = await api.usersByTeamId(teamId);

      setUsersState(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingState(false);
    }
  };

  const renderRows = () => {
    if (usersState.length === 0) {
      return (
        <TableRow className={"empty-row"}>
          <TableCell colSpan={3}>
            <span>{t("empty")}</span>
          </TableCell>
        </TableRow>
      );
    }

    return usersState.map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell>{row.email}</TableCell>
      </TableRow>
    ));
  };

  useEffect(() => {
    getTeamUsers(teamId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  return (
    <Paper className={"team-users-container"}>
      {isLoadingState ? (
        <Loader loadingText={t("loading")} />
      ) : (
        <TableContainer className={"roles-list-container__table"}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("table.id")}</TableCell>
                <TableCell>{t("table.name")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderRows()}</TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}
