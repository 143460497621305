import React, { useEffect, useState } from "react";
import "./UserList.component.sass";
import { User } from "../../../../../models/user/user";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  Link,
  Button,
  Tooltip,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Loader } from "../../../../common/loader/Loader.component";
import { Error } from "../../../../common/error/Error.component";
import PeopleIcon from "@mui/icons-material/People";
import AddIcon from "@mui/icons-material/Add";
import { UserListItemComponent } from "../user-list-item/UserListItem.component";
import { UserAddDialogComponent } from "../dialog/user-add-dialog/UserAddDialog.component";
import { UserUpdateDialogComponent } from "../dialog/user-update-dialog/UserUpdateDialog.component";
import { UserDeleteDialogComponent } from "../dialog/user-delete-dialog/UserDeleteDialog.component";
import { UserChangePasswordDialogComponent } from "../dialog/user-change-password-dialog/UserChangePasswordDialog.component";
import { useApi } from "../../../../common/providers/Api.provider";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

export function UserListComponent() {
  const { t: usersTranslations } = useTranslation("users-page", {
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [hasErrorState, setHasError] = useState<boolean>(false);
  const [usersState, setUsersState] = useState<User[]>([]);
  const [filteredUsersState, setFilteredUsersState] = useState<User[]>([]);
  const [searchTermState, setSearchTermState] = useState<string>("");
  const [isUserAddDialogOpenState, setIsUserAddDialogOpenState] =
    useState<boolean>(false);

  const [userToUpdateState, setUserToUpdateState] = useState<User | undefined>(
    undefined
  );

  const [userToDeleteState, setUserToDeleteState] = useState<User | undefined>(
    undefined
  );

  const [userToChangePasswordState, setUserToChangePasswordState] = useState<
    User | undefined
  >(undefined);

  const getUsers = async () => {
    try {
      setIsLoadingState(true);
      const response = await api.users();

      setUsersStates(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsLoadingState(false);
    }
  };

  const handleCloseUserAddDialog = (result?: User) => {
    if (!!result) {
      setUsersStates([...usersState, result]);
    }

    setIsUserAddDialogOpenState(false);
  };

  const handleCloseUserUpdateDialog = (result?: User) => {
    if (!!result) {
      setUsersStates(
        usersState.map((u) => (u.id === result.id ? { ...u, ...result } : u))
      );
    }

    setUserToUpdateState(undefined);
  };

  const handleCloseUserDeleteDialog = (result?: number) => {
    if (!!result) {
      setUsersStates(usersState.filter((u) => u.id !== result));
    }

    setUserToDeleteState(undefined);
  };

  const handleCloseUserChangePasswordDialog = () => {
    setUserToChangePasswordState(undefined);
  };

  const renderUsers = () => {
    if (isLoadingState) {
      return (
        <TableRow>
          <TableCell className="no-data-row" colSpan={4} align="center">
            <Loader />
          </TableCell>
        </TableRow>
      );
    }

    if (!filteredUsersState || filteredUsersState.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="center">
            {commonTranslations("search.noResults")}
          </TableCell>
        </TableRow>
      );
    }

    return filteredUsersState.map((row, index) => (
      <UserListItemComponent
        key={index}
        user={row}
        setUserToUpdate={setUserToUpdateState}
        setUserToDelete={setUserToDeleteState}
        setUserToChangePassword={setUserToChangePasswordState}
      />
    ));
  };

  const onChangeSearchTerm = (event: { target: { value: string } }) => {
    const searchTerm = event.target.value.toLowerCase();

    setSearchTermState(searchTerm);

    setFilteredUsersState(
      usersState.filter(
        (u) =>
          u.id.toString().toLowerCase().includes(searchTerm) ||
          u.email.toLowerCase().includes(searchTerm)
      )
    );
  };

  const setUsersStates = (users: User[]) => {
    setUsersState(users);
    setFilteredUsersState(users);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingState) {
    return <Loader />;
  }

  if (hasErrorState) {
    return <Error />;
  }

  return (
    <>
      <UserAddDialogComponent
        open={isUserAddDialogOpenState}
        handleClose={handleCloseUserAddDialog}
      />
      <UserUpdateDialogComponent
        open={!!userToUpdateState}
        user={userToUpdateState}
        handleClose={handleCloseUserUpdateDialog}
      />
      <UserDeleteDialogComponent
        open={!!userToDeleteState}
        user={userToDeleteState}
        handleClose={handleCloseUserDeleteDialog}
      />
      <UserChangePasswordDialogComponent
        open={!!userToChangePasswordState}
        user={userToChangePasswordState}
        handleClose={handleCloseUserChangePasswordDialog}
      />
      <div className="user-list-container">
        <div className="user-list-container__header">
          <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
            <Link className="active" color="textPrimary" aria-current="page">
              <PeopleIcon />
              {usersTranslations("title")}
            </Link>
          </Breadcrumbs>
          <div className="questionnaire-list-container__information__statistics">
            <span>
              {commonTranslations("search.resultsLength", {
                length: filteredUsersState.length,
              })}
            </span>
          </div>
        </div>
        <div className="questionnaire-list-container__search">
          <form noValidate autoComplete="off">
            <FormControl className="questionnaire-list-container__search__control">
              <OutlinedInput
                className="questionnaire-list-container__search__control__input"
                type="text"
                placeholder={commonTranslations("search.placeholder")}
                onChange={(event) => onChangeSearchTerm(event)}
                fullWidth={true}
                value={searchTermState}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search for results" edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
          <Tooltip title={commonTranslations("add")} placement="top">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsUserAddDialogOpenState(true)}
            >
              <AddIcon fontSize="large" />
            </Button>
          </Tooltip>
        </div>
        <TableContainer
          className="user-list-container__table"
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{usersTranslations("table.id")}</TableCell>
                <TableCell>{usersTranslations("table.email")}</TableCell>
                <TableCell>{usersTranslations("table.teams")}</TableCell>
                <TableCell>{usersTranslations("table.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderUsers()}</TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
