import React, { useState } from "react";
import { Questionnaire } from "../../../../../../../models/questionnaire/questionnaire";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface QuestionnaireNewRevisionDialogComponentProps {
  open: boolean;
  questionnaire?: Questionnaire;
  handleClose: (result?: number) => void;
}

export function QuestionnaireNewRevisionDialogComponent({
  open,
  questionnaire,
  handleClose,
}: QuestionnaireNewRevisionDialogComponentProps) {
  const { t: questionnairesTranslations } = useTranslation(
    "questionnaires-page",
    {
      keyPrefix: "dialogs.newRevision",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const handleCopy = async (questionnaire?: Questionnaire) => {
    if (!questionnaire || !questionnaire.id) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnairesCopy(questionnaire.id, {
        archiveSource: true,
      });

      handleClose(response.data.id);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!questionnaire ? (
          <Skeleton variant="text" />
        ) : (
          questionnairesTranslations("title")
        )}
      </DialogTitle>
      <DialogContent>
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <p>{questionnairesTranslations("description")}</p>
        )}
      </DialogContent>
      <DialogActions>
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            {commonTranslations("cancel")}
          </Button>
        )}
        {!questionnaire ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleCopy(questionnaire)} color="primary">
            {questionnairesTranslations("continue")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
