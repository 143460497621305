import { Category } from "../../../../../models/category/category";
import { RoleWithScan } from "./roleWithScan";

export function formatScanToBarGraphDataStatistics(
  roleWithScan: RoleWithScan,
  activeCategory: Category,
): Array<{ [key:string]: number | string; step: string }> {
  const entries: Array<{ [key:string]: number | string; step: string }> = [];
  if (roleWithScan && roleWithScan.averageScan) {
    
    for (const stepResult of roleWithScan.averageScan!.scanStepResults.filter((s) => s.step.categoryId === activeCategory.id)) {
      const step = stepResult.step;

      entries.push({
        step: step.name,
        [roleWithScan.name]:  Number(stepResult.scaledScore.toPrecision(2)),
      } as any);
    }
  }

  return entries;
}