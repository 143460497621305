import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { Step } from "../../../../../../../models/step/step";
import { StepAddDto } from "../../../../../../../models/step/stepAddDto";
import "./StepAddDialog.component.sass";
import { Category } from "../../../../../../../models/category/category";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { getCKEditorConfig } from "../../../../../../../lib/getCKEditorConfig";
import { useTranslation } from "react-i18next";

export interface StepAddDialogComponentProps {
  questionnaireId: number;
  open: boolean;
  handleClose: (result?: Step) => void;
}

export function StepAddDialogComponent({
  questionnaireId,
  open,
  handleClose,
}: StepAddDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "steps.dialogs.add",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();

  const [categoriesState, setCategoriesState] = useState<Category[]>([]);

  const emptyState = {
    name: "",
    description: "",
    questionnaireId,
    categoryId: 0,
  };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [stepAddDtoState, setStepAddDtoState] =
    useState<StepAddDto>(emptyState);

  const getCategories = async (questionnaireId: number) => {
    try {
      const response = await api.categoriesByQuestionnaireId(questionnaireId);

      setCategoriesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAdd = async (stepAddDto: StepAddDto) => {
    if (
      !stepAddDto.questionnaireId ||
      !stepAddDto.name.length ||
      !stepAddDtoState.categoryId
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.stepsAdd(stepAddDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setStepAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    if (open) {
      getCategories(questionnaireId);
    } else {
      setStepAddDtoState(emptyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, questionnaireId]);

  return (
    <Dialog open={open} className={"step-add-dialog-container"}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>{questionnaireTranslations("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {questionnaireTranslations("description")}
        </DialogContentText>
        <TextField
          className={"mb"}
          autoFocus
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="name"
          label={questionnaireTranslations("name")}
          type="text"
          value={stepAddDtoState.name}
          onChange={(event) =>
            setStepAddDtoState({
              ...stepAddDtoState,
              name: event.target.value,
            })
          }
        />
        <CKEditor
          className={"mb"}
          editor={ClassicEditor}
          data={stepAddDtoState.description}
          config={{
            ...getCKEditorConfig(),
            placeholder: questionnaireTranslations("inputDescription"),
          }}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            setStepAddDtoState({
              ...stepAddDtoState,
              description: data,
            });
          }}
        />
        {!categoriesState.length ? (
          questionnaireTranslations("noCategories")
        ) : (
          <TextField
            className={"mt mb"}
            fullWidth
            select
            margin="dense"
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
            }}
            id="categoryId"
            label={questionnaireTranslations("category")}
            required
            value={stepAddDtoState.categoryId}
            onChange={(event) => {
              setStepAddDtoState({
                ...stepAddDtoState,
                categoryId: Number(event.target.value),
              });
            }}
          >
            <MenuItem value={0}>
              {commonTranslations("selectCategory")}
            </MenuItem>
            {categoriesState.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          {commonTranslations("cancel")}
        </Button>
        <Button onClick={() => handleAdd(stepAddDtoState)} color="primary">
          {commonTranslations("add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
