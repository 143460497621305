import "./SideMenu.component.sass";
import React, { MouseEventHandler, useState } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, useNavigate } from "react-router-dom";
import { useUser } from "../../common/providers/User.provider";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CreateIcon from "@mui/icons-material/Create";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import HomeIcon from "@mui/icons-material/Home";
import { UserChangePasswordDialogComponent } from "../pages/user/dialog/user-change-password-dialog/UserChangePasswordDialog.component";
import { User } from "../../../models/user/user";
import { useApi } from "../../common/providers/Api.provider";
import { Role } from "../../../models/user-role/role";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useTranslation } from "react-i18next";

interface MenuItemProps {
  translationKey: string;
  href?: string;
  icon: any;
  onClick?: MouseEventHandler<Element>;
  roles: Role[];
}

export function SideMenuComponent() {
  const api = useApi();
  const { t } = useTranslation("side-menu", { useSuspense: false });
  const { setUser, userRole } = useUser();
  const navigate = useNavigate();

  const [userToChangePasswordState, setUserToChangePasswordState] = useState<
    User | undefined
  >(undefined);

  const menuItems: MenuItemProps[] = [
    {
      translationKey: "home",
      icon: <HomeIcon />,
      href: "/",
      roles: [Role.Admin, Role.TeamManager, Role.Researcher, Role.User],
    },
    {
      translationKey: "questionnaires",
      icon: <AssignmentIcon />,
      href: "questionnaires",
      roles: [Role.Admin, Role.TeamManager],
    },
    {
      translationKey: "scans",
      icon: <CreateIcon />,
      href: "scans",
      roles: [Role.Admin, Role.TeamManager, Role.User],
    },
    {
      translationKey: "users",
      icon: <PersonIcon />,
      href: "users",
      roles: [Role.Admin],
    },
    {
      translationKey: userRole.role === Role.TeamManager ? "team" : "teams",
      icon: <GroupIcon />,
      href:
        userRole.role === Role.TeamManager
          ? `teams/${userRole.teamId}`
          : "/teams",
      roles: [Role.Admin, Role.TeamManager],
    },
    {
      translationKey: "data",
      icon: <SaveAltIcon />,
      href: "data",
      roles: [Role.Admin, Role.Researcher],
    },
  ];

  const footerMenuItems: MenuItemProps[] = [
    {
      translationKey: "logout",
      icon: <LogoutIcon />,
      onClick: async () => {
        await api.logout();
        setUser(null);
        navigate("login");
      },
      roles: [Role.Admin, Role.TeamManager, Role.Researcher, Role.User],
    },
  ];

  return (
    <>
      <UserChangePasswordDialogComponent
        open={!!userToChangePasswordState}
        user={userToChangePasswordState}
        handleClose={() => setUserToChangePasswordState(undefined)}
      />
      <div className="item-list">
        <div className="logo-container">
          <ListAltIcon className="logo" />
        </div>
        <Divider className="divider" />
        <List style={{ width: "210px", maxWidth: "210px" }}>
          {menuItems
            .filter((menuItem) =>
              menuItem.roles.some((role) => role === userRole.role)
            )
            .map((menuItem, index) => (
              <ListItem
                button
                key={index}
                onClick={menuItem.onClick}
                {...(menuItem.href && {
                  component: NavLink,
                  to: menuItem.href,
                })}
              >
                <ListItemIcon className={"item-list__icon"}>
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText primary={t(menuItem.translationKey)} />
              </ListItem>
            ))}
        </List>
        <span className="spacer" />
        <Divider className="divider" />
        <List>
          {footerMenuItems
            .filter((menuItem) =>
              menuItem.roles.some((role) => role === userRole.role)
            )
            .map((menuItem, index) => (
              <ListItem
                button
                key={index}
                onClick={menuItem.onClick}
                {...(menuItem.href && {
                  component: NavLink,
                  to: menuItem.href,
                  activeClassName: "active",
                })}
              >
                <ListItemIcon className={"item-list__icon"}>
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText primary={t(menuItem.translationKey)} />
              </ListItem>
            ))}
        </List>
      </div>
    </>
  );
}
