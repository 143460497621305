import React from "react";
import ReactDOM from "react-dom";
import "./assets/global_styling.css";
import RootComponent from "./components/Root.component";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
import { UserProvider } from "./components/common/providers/User.provider";
import { ApiProvider } from "./components/common/providers/Api.provider";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

if (!process.env.REACT_APP_MODE) {
  throw Error(".env REACT_APP_MODE must be set to dev or prod");
}

if (!process.env.REACT_APP_API_URL) {
  throw Error(".env REACT_APP_API_URL must be set");
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <SnackbarProvider maxSnack={3}>
      <ApiProvider>
        <UserProvider>
          <RootComponent />
        </UserProvider>
      </ApiProvider>
    </SnackbarProvider>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
