import React, { useState, useEffect } from "react";
import { Scan } from "../../../../../../models/scan/scan";
import { useSnackbar } from "notistack";
import { ScanAddDto } from "../../../../../../models/scan/scanAddDto";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  MenuItem,
} from "@mui/material";
import { Questionnaire } from "../../../../../../models/questionnaire/questionnaire";
import { useApi } from "../../../../../common/providers/Api.provider";
import { QuestionnaireRole } from "../../../../../../models/questionnaire-role/questionnaireRole";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface ScanAddDialogComponentProps {
  open: boolean;
  questionnaireId: number;
  handleClose: (result?: Scan) => void;
}

export function ScanAddDialogComponent({
  open,
  questionnaireId,
  handleClose,
}: ScanAddDialogComponentProps) {
  const { t: scansTranslations } = useTranslation("scans-page", {
    keyPrefix: "dialogs.add",
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const location = useLocation();
  const snackbar = useSnackbar();

  const emptyState: ScanAddDto = { questionnaireId: 0, questionnaireRoleId: 0 };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [scanAddDtoState, setScanAddDtoState] = useState<ScanAddDto>({
    questionnaireId: questionnaireId ?? 0,
    questionnaireRoleId: 0,
  });

  const [questionnairesState, setQuestionnairesState] = useState<
    Questionnaire[]
  >([]);

  const [questionnaireRolesState, setQuestionnaireRolesState] = useState<
    QuestionnaireRole[]
  >([]);

  const [isInviteState, setIsInviteState] = useState<boolean>(false);

  const getQuestionnaires = async () => {
    try {
      const response = await api.questionnairesPublished();
      const inviteUuid = new URLSearchParams(location.search).get("invite");
      const foundQuestionnaire = response.data.find((s) =>
        s.teamQuestionnaires.some((t) => t.uuid === inviteUuid)
      );

      if (inviteUuid && foundQuestionnaire) {
        setIsInviteState(true);
        setScanAddDtoState({
          ...scanAddDtoState,
          questionnaireId: Number(foundQuestionnaire.id),
        });
      }

      setQuestionnairesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getQuestionnaireRoles = async (questionnaireId: number) => {
    try {
      const response = await api.questionnaireRolesByQuestionnaireId(
        questionnaireId
      );

      setQuestionnaireRolesState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const isScanNotValid = () => {
    return (
      !scanAddDtoState.questionnaireId ||
      (scanAddDtoState.questionnaireId > 0 &&
        scanAddDtoState.questionnaireRoleId === 0 &&
        questionnaireRolesState.length > 0)
    );
  };

  const handleAdd = async (scanAddDtoState: ScanAddDto) => {
    if (isScanNotValid()) {
      return;
    }

    try {
      setIsLoadingState(true);

      const {
        data: scan,
        message,
        success,
      } = await api.scansAdd({
        ...scanAddDtoState,
        questionnaireRoleId:
          scanAddDtoState.questionnaireRoleId === 0
            ? null
            : scanAddDtoState.questionnaireRoleId,
      });

      if (success) {
        snackbar.enqueueSnackbar(
          message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(scan);
      setScanAddDtoState(emptyState);
      setIsLoadingState(false);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({ variant: "error" })
      );
      setScanAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  const renderSelectQuestionnaire = () => {
    if (!questionnairesState.length) {
      return <>{scansTranslations("empty")}</>;
    }

    return (
      <TextField
        autoFocus
        fullWidth
        required
        select
        SelectProps={{
          displayEmpty: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        margin="dense"
        id="questionnaireId"
        label={scansTranslations("questionnaire")}
        value={scanAddDtoState.questionnaireId}
        onChange={(event) => {
          setScanAddDtoState({
            ...scanAddDtoState,
            questionnaireId: Number(event.target.value),
            questionnaireRoleId: 0,
          });
          setQuestionnaireRolesState([]);
        }}
      >
        <MenuItem value={0}>{scansTranslations("placeholder")}</MenuItem>
        {questionnairesState.map((questionnaire, index) => (
          <MenuItem
            style={{ whiteSpace: "normal" }}
            key={index}
            value={questionnaire.id}
          >
            {scansTranslations("questionnaireWithRevision", {
              name: questionnaire.name,
              revision: questionnaire.revision,
            })}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderSelectQuestionnaireRole = () => {
    if (!questionnaireRolesState.length) {
      return;
    }

    return (
      <TextField
        fullWidth
        required
        select
        SelectProps={{
          displayEmpty: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        margin="dense"
        id="questionnaireRoleId"
        label={scansTranslations("role")}
        value={scanAddDtoState.questionnaireRoleId}
        onChange={(event) => {
          setScanAddDtoState({
            ...scanAddDtoState,
            questionnaireRoleId: Number(event.target.value),
          });
        }}
      >
        <MenuItem value={0}>{scansTranslations("chooseRole")}</MenuItem>
        {questionnaireRolesState.map((role, index) => (
          <MenuItem
            style={{ whiteSpace: "normal" }}
            key={index}
            value={role.id}
          >
            {role.name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderText = () => {
    if (isInviteState) {
      return questionnaireRolesState.length > 0
        ? scansTranslations("description.invitedQuestionnaire.notEmpty")
        : scansTranslations("description.invitedQuestionnaire.empty");
    }

    return scansTranslations("description.default");
  };

  useEffect(() => {
    setTimeout(() => {
      getQuestionnaires();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scanAddDtoState.questionnaireId !== 0) {
      getQuestionnaireRoles(scanAddDtoState.questionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanAddDtoState.questionnaireId]);

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>{scansTranslations("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{renderText()}</DialogContentText>
        {!isInviteState && renderSelectQuestionnaire()}
        {renderSelectQuestionnaireRole()}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setScanAddDtoState(emptyState);
            setQuestionnaireRolesState([]);
            setIsInviteState(false);
            handleClose(undefined);
          }}
          color="secondary"
        >
          {commonTranslations("cancel")}
        </Button>
        <Button
          onClick={() => {
            setScanAddDtoState(emptyState);
            setQuestionnaireRolesState([]);
            setIsInviteState(false);
            handleAdd(scanAddDtoState);
          }}
          color="primary"
          disabled={isScanNotValid()}
        >
          {scansTranslations("add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
