import React from "react";
import { TeamListComponent } from "./team-list/TeamList.component";

export function TeamListPageComponent() {
  return (
    <div className={"team-list-page-container page"}>
      <TeamListComponent />
    </div>
  );
}
