import { LikertScaleData } from "../models/question/likertScaleData";
import { Question } from "../models/question/question";
import { QuestionType } from "../models/question/questionType";
import { ScanValue } from "../models/scan-value/scanValue";

export function checkScanValueCompleted(question: Question, scanValue: ScanValue): Boolean {
    if(question.type === QuestionType.LIKERT_SCALE) {
        const {data} = question as Question<LikertScaleData>;
        return scanValue.value.split(',').filter(v => !!v && v !== undefined).length === data.rows.length;
    }

    return scanValue.value.length > 0;
  }