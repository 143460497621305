import React from "react";
import { ScanValue } from "../../../../../models/scan-value/scanValue";
import { TextField } from "@mui/material";
import { OpenData } from "../../../../../models/question/openData";
import { Question } from "../../../../../models/question/question";

export interface OpenTypeComponentProps {
  disabled?: boolean,
  question: Question<OpenData>;
  scanValue: ScanValue<OpenData>;
  setScanValue: (value: ScanValue) => void;
}

export function OpenTypeComponent({
  question,
  scanValue,
  setScanValue,
  disabled = false,
}: OpenTypeComponentProps) {
  const q = !!scanValue && !!scanValue.question ? scanValue.question : question;
  const openData = q.data;

  return (
    <TextField
      fullWidth
      hiddenLabel
      disabled={disabled}
      required={q.required}
      multiline={openData.multilineAnswer}
      rows={openData.multilineAnswer ? 4 : 1}
      variant="outlined"
      color="primary"
      className={"form-control"}
      value={!!scanValue ? scanValue.value : ""}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (!!scanValue && !!setScanValue) {
          setScanValue({ ...scanValue, value: event.target.value });
        }
      }}
      placeholder={openData.placeholder}
    />
  );
}
