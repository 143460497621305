import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
} from "@mui/material";
import { QuestionnaireAddDto } from "../../../../../../../models/questionnaire/questionnaireAddDto";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { Questionnaire } from "../../../../../../../models/questionnaire/questionnaire";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./QuestionnaireAddDialog.component.sass";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { getCKEditorConfig } from "../../../../../../../lib/getCKEditorConfig";
import { useTranslation } from "react-i18next";

export interface QuestionnaireAddDialogComponentProps {
  open: boolean;
  handleClose: (result?: Questionnaire) => void;
}

export function QuestionnaireAddDialogComponent({
  open,
  handleClose,
}: QuestionnaireAddDialogComponentProps) {
  const { t: questionnairesTranslations } = useTranslation(
    "questionnaires-page",
    {
      keyPrefix: "dialogs.add",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();
  const emptyState = { name: "", description: "" };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [questionnaireAddDtoState, setQuestionnaireAddDtoState] =
    useState<QuestionnaireAddDto>(emptyState);

  const handleChange = (questionnaireAddDto: QuestionnaireAddDto) => {
    setQuestionnaireAddDtoState(questionnaireAddDto);
  };

  const handleAdd = async (questionnaireAddDto: QuestionnaireAddDto) => {
    if (
      !questionnaireAddDto.name.length ||
      !questionnaireAddDto.description.length
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnairesAdd(questionnaireAddDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setQuestionnaireAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open} className={"questionnaire-add-dialog-container"}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>{questionnairesTranslations("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {questionnairesTranslations("description")}
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="name"
          label={questionnairesTranslations("name")}
          type="text"
          value={questionnaireAddDtoState.name}
          onChange={(event) =>
            handleChange({
              ...questionnaireAddDtoState,
              name: event.target.value,
            })
          }
        />
        <CKEditor
          editor={ClassicEditor}
          config={{
            ...getCKEditorConfig(),
            placeholder: questionnairesTranslations("descriptionInput"),
          }}
          data={questionnaireAddDtoState.description}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            handleChange({
              ...questionnaireAddDtoState,
              description: data,
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          {commonTranslations("cancel")}
        </Button>
        <Button
          onClick={() => handleAdd(questionnaireAddDtoState)}
          color="primary"
        >
          {commonTranslations("add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
