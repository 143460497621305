import React from "react";
import { Category } from "../../../../../../models/category/category";
import { TableCell, TableRow, Tooltip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

export interface CategoryComponentProps {
  category: Category;
  setCategoryToUpdate: React.Dispatch<
    React.SetStateAction<Category | undefined>
  >;
  setCategoryIdToDelete: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}

export function CategoryComponent({
  category,
  setCategoryToUpdate,
  setCategoryIdToDelete,
}: CategoryComponentProps) {
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {category.id}
      </TableCell>
      <TableCell>{category.name}</TableCell>
      <TableCell>{category.scale}</TableCell>
      <TableCell>
        {category.showInResult
          ? commonTranslations("yes")
          : commonTranslations("no")}
      </TableCell>
      <TableCell>
        <Tooltip title={commonTranslations("edit")} placement="top">
          <IconButton onClick={() => setCategoryToUpdate(category)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={commonTranslations("remove")} placement="top">
          <IconButton onClick={() => setCategoryIdToDelete(category.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
