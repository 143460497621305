import React from "react";
import { DataListComponent } from "./data-list/DataList.component";

export function DataListPageComponent() {
  return (
    <div className="data-list-page-container page">
      <DataListComponent />
    </div>
  );
}
