import React, { useState } from "react";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { useTranslation } from "react-i18next";

export interface QuestionnaireNewCycleDialogComponentProps {
  open: boolean;
  questionnaireId?: number;
  cycle?: number;
  handleClose: (questionnaireId?: number, cycle?: number) => void;
}

export function QuestionnaireNewCycleDialogComponent({
  open,
  questionnaireId,
  cycle,
  handleClose,
}: QuestionnaireNewCycleDialogComponentProps) {
  const { t: questionnairesTranslations } = useTranslation(
    "questionnaires-page",
    {
      keyPrefix: "dialogs.newCycle",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const snackbar = useSnackbar();

  const handleNewCycle = async (id?: number, oldCycle?: number) => {
    if (isEmpty()) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.questionnairesNewCycle(id!);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      handleClose(id, oldCycle! + 1);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingState(false);
    }
  };

  const isEmpty = () => questionnaireId === undefined || cycle === undefined;

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {isEmpty() ? (
          <Skeleton variant="text" />
        ) : (
          questionnairesTranslations("title")
        )}
      </DialogTitle>
      <DialogContent>
        {isEmpty() ? (
          <Skeleton variant="rectangular" />
        ) : (
          <p>{questionnairesTranslations("description")}</p>
        )}
      </DialogContent>
      <DialogActions>
        {isEmpty() ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            {commonTranslations("cancel")}
          </Button>
        )}
        {isEmpty() ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button
            onClick={() => handleNewCycle(questionnaireId, cycle)}
            color="primary"
          >
            {questionnairesTranslations("continue")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
