import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { Team } from "../../../../../../../models/team/team";
import { useTranslation } from "react-i18next";

export interface TeamUnassignDialogComponentProps {
  open: boolean;
  questionnaireId: number;
  teamId?: number;
  handleClose: (result?: number) => void;
}

export function TeamUnassignDialogComponent({
  open,
  questionnaireId,
  teamId,
  handleClose,
}: TeamUnassignDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "teams.dialogs.unassign",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [nameState, setNameState] = useState<string>("");
  const [teamState, setTeamState] = useState<Team | undefined>(undefined);

  const getTeam = async (teamId: number) => {
    try {
      const response = await api.teamById(teamId);

      setTeamState(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = async (team?: Team) => {
    if (!team || !team.id) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.unassignTeamFromQuestionnaire(
        questionnaireId,
        team.id
      );

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(team.id);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setNameState("");
      setIsLoadingState(false);
    }
  };

  const renderContentText = () => {
    if (!teamState) {
      return;
    }

    return questionnaireTranslations("description", { team: teamState.name });
  };

  const renderNameFormField = () => {
    if (!teamState) {
      return <Skeleton variant="text" />;
    }

    return (
      <TextField
        autoFocus
        fullWidth
        required
        variant="outlined"
        margin="dense"
        id="name"
        label={questionnaireTranslations("repeatName")}
        type="text"
        value={nameState}
        onChange={(event) => setNameState(event.target.value)}
      />
    );
  };

  const renderDeleteButton = () => {
    if (!teamState) {
      return <Skeleton variant="rectangular" />;
    }

    return (
      <Button
        disabled={teamState.name !== nameState}
        onClick={() => handleDelete(teamState)}
        color="primary"
      >
        {commonTranslations("unassign")}
      </Button>
    );
  };

  useEffect(() => {
    if (!!teamId) {
      getTeam(teamId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!teamState ? (
          <Skeleton variant="text" />
        ) : (
          questionnaireTranslations("title", { team: teamState.name })
        )}
      </DialogTitle>
      <DialogContent>
        {!teamState ? <Skeleton variant="rectangular" /> : renderContentText()}
        {renderNameFormField()}
      </DialogContent>
      <DialogActions>
        {!teamState ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button
            onClick={() => {
              setNameState("");
              handleClose(undefined);
            }}
            color="secondary"
          >
            {commonTranslations("cancel")}
          </Button>
        )}
        {renderDeleteButton()}
      </DialogActions>
    </Dialog>
  );
}
