import { TFunction } from "i18next";
import { Role } from "../models/user-role/role";

export function convertRoleToText(role: Role, t: TFunction) {
  switch (role) {
    case Role.Admin:
      return t("roles.admin");
    case Role.Researcher:
      return t("roles.researcher");
    case Role.TeamManager:
      return t("roles.teamManager");
    case Role.User:
      return t("roles.user");
    default:
      throw Error("Role does not exist.");
  }
}
