import React from "react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  LinearProgress,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import { Team } from "../../../../../../../models/team/team";
import { TeamAddDto } from "../../../../../../../models/team/teamAddDto";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface TeamAddDialogComponentProps {
  open: boolean;
  handleClose: (result?: Team) => void;
}

export function TeamAddDialogComponent({
  open,
  handleClose,
}: TeamAddDialogComponentProps) {
  const { t: teamsTranslations } = useTranslation("teams-page", {
    useSuspense: false,
    keyPrefix: "dialog.add",
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();
  const emptyState = { name: "" };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [teamAddDtoState, setTeamAddDtoState] =
    useState<TeamAddDto>(emptyState);

  const handleChange = (teamAddDto: TeamAddDto) => {
    setTeamAddDtoState(teamAddDto);
  };

  const handleAdd = async (teamAddDto: TeamAddDto) => {
    if (!teamAddDto.name.length) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.teamsAdd(teamAddDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({ variant: "error" })
      );
    } finally {
      setTeamAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>{teamsTranslations("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {teamsTranslations("description")}
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="name"
          label={teamsTranslations("name")}
          type="text"
          value={teamAddDtoState.name}
          onChange={(event) =>
            handleChange({ ...teamAddDtoState, name: event.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          {commonTranslations("cancel")}
        </Button>
        <Button onClick={() => handleAdd(teamAddDtoState)} color="primary">
          {commonTranslations("create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
