import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { BarGraphEntry } from "./barGraphEntry";
import { getTspanGroups } from "../../../../../../lib/getTspanGroups";

export interface BarGraphProps {
  isMobile: boolean;
  data: BarGraphEntry[] | Array<{ [key: string]: number | string; step: string }>;
  keys: string[];
  indexedBy: string;
  maxValue: number;
}

export function BarGraph({ 
  isMobile,
  data, 
  keys, 
  indexedBy, 
  maxValue 
}: BarGraphProps) {
  const customTick = (tick: any) => {
    if (isMobile) {
      return null;
    }

    return (
        <g transform={`translate(${tick.x},${tick.y + 22})`}>
            <text
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                    fill: '#333',
                    fontSize: 14,
                }}
            >
                {getTspanGroups(tick.value, 18, 3)}
            </text>
        </g>
    )
}

  return (
    <ResponsiveBar
      data={data as any}
      keys={keys}
      indexBy={indexedBy}
      groupMode="grouped"
      margin={{ top: 50, right: 130, bottom: 80, left: 60 }}
      padding={0.3}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      maxValue={maxValue}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        renderTick: customTick as any
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Score",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={isMobile ? [] : [
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
    />
  );
}
