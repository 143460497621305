import { TFunction } from "i18next";

export interface ChangePasswordState {
  oldPasswordRequired: boolean;
  oldPassword?: string;
  oldPasswordError?: string;

  password: string;
  passwordError?: string;

  repeatPassword: string;
  repeatPasswordError?: string;
}

export const initialChangePasswordState: ChangePasswordState = {
  oldPasswordRequired: false,
  oldPassword: "",
  password: "",
  repeatPassword: "",
};

export function validateChangePasswordState(
  RegisterState: ChangePasswordState,
  t: TFunction
): ChangePasswordState {
  RegisterState.passwordError =
    RegisterState.password.length === 0 ? t("required") : undefined;

  if (RegisterState.password.length < 8) {
    RegisterState.passwordError = t("newPassword.errors.length");
  } else if (!RegisterState.password.match(/[A-Z]/)) {
    RegisterState.passwordError = t("newPassword.errors.capitalLetter");
  } else if (
    !RegisterState.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/)
  ) {
    RegisterState.passwordError = t("newPassword.errors.specialCharacter");
  }

  if (RegisterState.repeatPassword.length === 0) {
    RegisterState.repeatPasswordError = t(
      "confirmPassword.errors.repeatPassword"
    );
  } else if (
    RegisterState.repeatPassword.length > 0 &&
    RegisterState.repeatPassword !== RegisterState.password
  ) {
    RegisterState.repeatPasswordError = t("confirmPassword.errors.shouldMatch");
  } else {
    RegisterState.repeatPasswordError = undefined;
  }

  if (
    RegisterState.oldPasswordRequired &&
    (!RegisterState.oldPassword || RegisterState.oldPassword.length === 0)
  ) {
    RegisterState.oldPasswordError = t("oldPassword.errors.oldPassword");
  } else {
    RegisterState.oldPasswordError = undefined;
  }

  return RegisterState;
}

export function isChangePasswordStateValid(
  changePasswordState: ChangePasswordState
): boolean {
  return (
    !changePasswordState.passwordError &&
    !changePasswordState.repeatPasswordError &&
    ((changePasswordState.oldPasswordRequired &&
      !changePasswordState.oldPasswordError) ||
      !changePasswordState.oldPasswordRequired)
  );
}
