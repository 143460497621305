import React, { useState } from "react";
import { Scan } from "../../../../../../models/scan/scan";
import { useSnackbar } from "notistack";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface ScanDeleteDialogComponentProps {
  open: boolean;
  scan?: Scan;
  handleClose: (result?: number) => void;
}

export function ScanDeleteDialogComponent({
  open,
  scan,
  handleClose,
}: ScanDeleteDialogComponentProps) {
  const { t: scansTranslations } = useTranslation("scans-page", {
    keyPrefix: "dialogs.delete",
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const handleDelete = async (scan?: Scan) => {
    if (!scan || !scan.id) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.scansDelete(scan.id);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(scan.id);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!scan ? <Skeleton variant="text" /> : scansTranslations("title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!scan ? (
            <Skeleton variant="rectangular" />
          ) : (
            scansTranslations("description")
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!scan ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleClose(undefined)} color="secondary">
            {commonTranslations("cancel")}
          </Button>
        )}
        {!scan ? (
          <Skeleton variant="rectangular" />
        ) : (
          <Button onClick={() => handleDelete(scan)} color="primary">
            {commonTranslations("delete")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
