import React, { useState, useEffect } from "react";
import { User } from "../../../../../../models/user/user";
import { useSnackbar } from "notistack";
import { UserUpdateDto } from "../../../../../../models/user/userUpdateDto";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { Team } from "../../../../../../models/team/team";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { useApi } from "../../../../../common/providers/Api.provider";
import { Role } from "../../../../../../models/user-role/role";
import DeleteIcon from "@mui/icons-material/Delete";
import "./UserUpdateDialog.component.sass";
import { UserRole } from "../../../../../../models/user-role/userRole";
import AddIcon from "@mui/icons-material/Add";
import { convertRoleToText } from "../../../../../../lib/convertRoleToText";
import { useTranslation } from "react-i18next";

export interface UserUpdateDialogComponentProps {
  open: boolean;
  user?: User;
  handleClose: (result?: User) => void;
}

export function UserUpdateDialogComponent({
  open,
  user,
  handleClose,
}: UserUpdateDialogComponentProps) {
  const { t: userTranslations } = useTranslation("users-page", {
    keyPrefix: "dialogs.update",
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [userUpdateDtoState, setUserUpdateDtoState] =
    useState<UserUpdateDto | null>(null);

  const [teamsState, setTeamsState] = useState<Team[]>([]);

  const getTeams = async () => {
    try {
      const resposne = await api.teams();

      setTeamsState(resposne.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (user) {
      setUserUpdateDtoState({
        ...user,
        userRoles: user.userRoles.map((userRole) => ({
          id: userRole.id,
          teamId: userRole.teamId,
          role: userRole.role,
        })),
      });
    }
  }, [user]);

  useEffect(() => {
    if (open) {
      getTeams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (userUpdateDto: UserUpdateDto) => {
    setUserUpdateDtoState(userUpdateDto);
  };

  const handleUpdate = async (userUpdateDto: UserUpdateDto) => {
    if (
      !userUpdateDto.id ||
      !userUpdateDto.email?.length ||
      !userUpdateDto.userRoles.length ||
      !userUpdateDto.userRoles.every(
        (userRole) => userRole.teamId && userRole.role
      ) ||
      !(
        userUpdateDto.userRoles.length ===
        new Set<number>([
          ...userUpdateDto.userRoles.map((userRole) => userRole.teamId),
        ]).size
      )
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.usersUpdate(userUpdateDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setUserUpdateDtoState(null);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog className="user-update-dialog-container" open={open}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>
        {!userUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          userTranslations("title")
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!userUpdateDtoState ? (
            <Skeleton variant="rectangular" />
          ) : (
            userTranslations("description")
          )}
        </DialogContentText>
        {!userUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <TextField
            autoFocus
            fullWidth
            required
            InputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            margin="dense"
            id="email"
            label={userTranslations("email")}
            type="email"
            value={userUpdateDtoState.email}
            onChange={(event) =>
              handleChange({
                ...userUpdateDtoState,
                email: event.target.value,
              })
            }
          />
        )}
        {!userUpdateDtoState ? (
          <Skeleton variant="text" />
        ) : (
          <>
            <span className="label">{userTranslations("minRoles")}</span>
            {userUpdateDtoState.userRoles.map((userRole, userRoleIndex) => (
              <div key={userRoleIndex} className="user-role">
                <TextField
                  fullWidth
                  required
                  select
                  variant="outlined"
                  margin="dense"
                  id={`${userRoleIndex}_user_role_team`}
                  label={userTranslations("team")}
                  value={userRole.teamId ?? ""}
                  onChange={(event) => {
                    const teamId = event.target.value;
                    const userRoles = userUpdateDtoState.userRoles;
                    userRoles[userRoleIndex].teamId = Number(teamId);

                    handleChange({
                      ...userUpdateDtoState,
                      userRoles,
                    });
                  }}
                >
                  {teamsState.map((team, teamIndex) => (
                    <MenuItem key={teamIndex} value={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </TextField>
                <div className={"divider"}></div>
                <TextField
                  fullWidth
                  required
                  select
                  variant="outlined"
                  margin="dense"
                  id={`${userRoleIndex}_user_role_role`}
                  label={userTranslations("role")}
                  value={userRole.role ?? ""}
                  onChange={(event) => {
                    const role = event.target.value;
                    const userRoles = userUpdateDtoState.userRoles;
                    userRoles[userRoleIndex].role = role as Role;

                    handleChange({
                      ...userUpdateDtoState,
                      userRoles,
                    });
                  }}
                >
                  {Object.values(Role).map((role, roleIndex) => (
                    <MenuItem key={roleIndex} value={role}>
                      {convertRoleToText(role, commonTranslations)}
                    </MenuItem>
                  ))}
                </TextField>
                <div className={"divider"}></div>
                <IconButton
                  disabled={userUpdateDtoState.userRoles.length === 1}
                  className={"delete-button"}
                  onClick={() => {
                    userUpdateDtoState.userRoles.splice(userRoleIndex, 1);

                    handleChange({
                      ...userUpdateDtoState,
                    });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
            <Button
              className={"add-button"}
              color="secondary"
              variant="contained"
              onClick={() => {
                userUpdateDtoState.userRoles.push(new UserRole());

                handleChange({
                  ...userUpdateDtoState,
                });
              }}
            >
              <AddIcon />
              {userTranslations("addRole")}
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!userUpdateDtoState ? (
          <Skeleton variant="rectangular" />
        ) : (
          <>
            <Button onClick={() => handleClose(undefined)} color="secondary">
              {commonTranslations("cancel")}
            </Button>
            <Button
              onClick={() => handleUpdate(userUpdateDtoState)}
              color="primary"
            >
              {commonTranslations("edit")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
