import React from "react";
import { Team } from "../../../../../../models/team/team";
import { TableRow, TableCell, Tooltip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

export interface TeamListItemComponentProps {
  team: Team;
  navigateToTeam: (teamId: number) => void;
  setTeamToDelete: React.Dispatch<React.SetStateAction<Team | undefined>>;
}

export function TeamListItemComponent({
  team,
  navigateToTeam,
  setTeamToDelete,
}: TeamListItemComponentProps) {
  const { t } = useTranslation("teams-page", { useSuspense: false });

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {team.id}
      </TableCell>
      <TableCell>{team.name}</TableCell>
      <TableCell>
        <Tooltip title={t("tooltip.edit")} placement="top">
          <IconButton onClick={() => navigateToTeam(team.id)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("tooltip.delete")} placement="top">
          <IconButton onClick={() => setTeamToDelete(team)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
