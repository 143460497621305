import React, { useState, useEffect } from "react";
import { Filter } from "../filter";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { Loader } from "../../../../../common/loader/Loader.component";
import { Questionnaire } from "../../../../../../models/questionnaire/questionnaire";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface QuestionnairesComponentProps {
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function QuestionnairesComponent({
  filter,
  setFilter,
  setHasError,
}: QuestionnairesComponentProps) {
  const { t } = useTranslation("data-page", {
    keyPrefix: "steps.questionnairesSelection",
    useSuspense: false,
  });
  const api = useApi();
  const [questionnairesState, setQuestionnairesState] = useState<
    Questionnaire[]
  >([]);
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionnaireId: number
  ) => {
    const questionnaires = filter.questionnaires;

    if (event.target.checked) {
      questionnaires.push(questionnaireId);
    } else {
      const indexOfQuestionnaire = questionnaires.indexOf(questionnaireId);

      if (indexOfQuestionnaire !== -1) {
        questionnaires.splice(indexOfQuestionnaire, 1);
      }
    }

    setFilter((prevState) => ({ ...prevState, questionnaires }));
  };

  const getQuestionnaires = async () => {
    try {
      setIsLoadingState(true);
      const response = await api.questionnairesFilter(filter.teams);

      setQuestionnairesState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsLoadingState(false);
    }
  };

  useEffect(() => {
    getQuestionnaires();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingState) {
    return <Loader loadingText={t("loading")} />;
  }

  return (
    <div className={"questionnaires-container"}>
      <FormLabel>{t("subtitle")}</FormLabel>
      <FormGroup>
        {questionnairesState.length ? (
          questionnairesState.map((questionnaire, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  color="primary"
                  checked={
                    filter.questionnaires.findIndex(
                      (q) => q === questionnaire.id
                    ) !== -1
                  }
                  onChange={(event) => handleChange(event, questionnaire.id)}
                />
              }
              label={t("questionnaire", {
                name: questionnaire.name,
                revision: questionnaire.revision,
              })}
              labelPlacement="end"
            />
          ))
        ) : (
          <span>{t("empty")}</span>
        )}
      </FormGroup>
    </div>
  );
}
