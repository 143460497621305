import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  LinearProgress,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { getSnackbarOptions } from "../../../../../../../lib/getSnackbarOptions";
import { Category } from "../../../../../../../models/category/category";
import { CategoryAddDto } from "../../../../../../../models/category/categoryAddDto";
import { useApi } from "../../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface CategoryAddDialogComponentProps {
  questionnaireId: number;
  open: boolean;
  handleClose: (result?: Category) => void;
}

export function CategoryAddDialogComponent({
  questionnaireId,
  open,
  handleClose,
}: CategoryAddDialogComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "categories.dialogs.add",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const api = useApi();
  const snackbar = useSnackbar();

  const emptyState = {
    questionnaireId,
    name: "",
    scale: 0,
    showInResult: true,
  };

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const [categoryAddDtoState, setCategoryAddDtoState] =
    useState<CategoryAddDto>(emptyState);

  const handleAdd = async (categoryAddDto: CategoryAddDto) => {
    if (
      !categoryAddDto.questionnaireId ||
      !categoryAddDto.name.length ||
      !categoryAddDto.scale
    ) {
      return;
    }

    try {
      setIsLoadingState(true);

      const response = await api.categoriesAdd(categoryAddDto);

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }

      handleClose(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    } finally {
      setCategoryAddDtoState(emptyState);
      setIsLoadingState(false);
    }
  };

  return (
    <Dialog open={open} className={"step-add-dialog-container"}>
      {isLoadingState && <LinearProgress />}
      <DialogTitle>{questionnaireTranslations("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {questionnaireTranslations("description")}
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="name"
          label={questionnaireTranslations("name")}
          type="text"
          value={categoryAddDtoState.name}
          onChange={(event) =>
            setCategoryAddDtoState({
              ...categoryAddDtoState,
              name: event.target.value,
            })
          }
        />
        <TextField
          fullWidth
          required
          variant="outlined"
          margin="dense"
          id="description"
          label={questionnaireTranslations("scale")}
          type="number"
          inputProps={{
            min: 0,
          }}
          value={categoryAddDtoState.scale}
          onChange={(event) =>
            setCategoryAddDtoState({
              ...categoryAddDtoState,
              scale: Number(event.target.value),
            })
          }
        />
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={categoryAddDtoState.showInResult}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCategoryAddDtoState({
                    ...categoryAddDtoState,
                    showInResult: event.target.checked,
                  });
                }}
              />
            }
            label={questionnaireTranslations("showInScanResult")}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(undefined)} color="secondary">
          {commonTranslations("cancel")}
        </Button>
        <Button onClick={() => handleAdd(categoryAddDtoState)} color="primary">
          {commonTranslations("add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
