import { TFunction } from "i18next";
import { QuestionType } from "../models/question/questionType";

export function convertQuestionTypeToText(
  questionType: QuestionType,
  t: TFunction
): string {
  switch (questionType) {
    case QuestionType.LIKERT_SCALE:
      return t("questions.likertScale.type");
    case QuestionType.OPEN:
      return t("questions.open.type");
    case QuestionType.SELECT:
      return t("questions.select.type");
    case QuestionType.POLAR:
      return t("questions.polar.type");
    default:
      throw Error("Question type not found");
  }
}
