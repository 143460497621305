import React, { useState } from "react";
import {
  TextField,
  Button,
  CardContent,
  Card,
  CardActions,
} from "@mui/material";
import { useUser } from "../common/providers/User.provider";
import { useNavigate, useLocation } from "react-router-dom";
import "./Login.component.sass";
import { LoginDto } from "../../models/user/loginDto";
import { useApi } from "../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export function LoginComponent() {
  const { t: loginTranslations } = useTranslation("login-page", {
    useSuspense: false,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();
  const { setUser } = useUser();
  const [loginDtoState, setLoginDtoState] = useState<LoginDto>({
    username: "",
    password: "",
  });

  const handleLogin = async (event: any) => {
    event.preventDefault();

    const response = await api.login(loginDtoState);

    setUser(response.data);

    const inviteUuid = new URLSearchParams(location.search).get("invite");

    if (inviteUuid) {
      navigate(`/invite/${inviteUuid}`);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="login-container">
      <span className="login-container__title">
        {loginTranslations("title")}
      </span>
      <form onSubmit={handleLogin}>
        <Card>
          <CardContent>
            <TextField
              autoFocus
              fullWidth
              required
              variant="outlined"
              margin="dense"
              id="username"
              label={loginTranslations("emailInput")}
              type="email"
              value={loginDtoState.username}
              tabIndex={0}
              onChange={(event) =>
                setLoginDtoState({
                  ...loginDtoState,
                  username: event.target.value,
                })
              }
            />
            <TextField
              fullWidth
              required
              variant="outlined"
              margin="dense"
              id="password"
              label={loginTranslations("passwordInput")}
              type="password"
              value={loginDtoState.password}
              tabIndex={1}
              onChange={(event) =>
                setLoginDtoState({
                  ...loginDtoState,
                  password: event.target.value,
                })
              }
            />
          </CardContent>
          <CardActions>
            <Button type="submit">{loginTranslations("login")}</Button>
            {location.search.length > 0 && (
              <Button onClick={() => navigate(`/register${location.search}`)}>
                {loginTranslations("register")}
              </Button>
            )}
          </CardActions>
        </Card>
      </form>
    </div>
  );
}
