import { FormGroup, FormLabel, MenuItem, TextField } from "@mui/material";
import React from "react";
import { FileType } from "../FileType";
import { Filter } from "../filter";
import "./Type.component.sass";
import { useTranslation } from "react-i18next";

export interface TypeComponentProps {
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function TypeComponent({
  filter,
  setFilter,
  setHasError,
}: TypeComponentProps) {
  const { t } = useTranslation("data-page", {
    keyPrefix: "steps.fileTypeSelection",
    useSuspense: false,
  });

  return (
    <div className="type-container">
      <FormLabel>{t("subtitle")}</FormLabel>
      <FormGroup>
        <TextField
          className="select"
          select
          required
          margin="dense"
          variant="outlined"
          SelectProps={{
            displayEmpty: true,
          }}
          id="downloadType"
          value={filter.fileType}
          onChange={(event) => {
            setFilter({
              ...filter,
              fileType: event.target.value,
            });
          }}
        >
          <MenuItem value={""}>{t("options.placeholder")}</MenuItem>
          <MenuItem value={FileType.CSV}>{t("options.csv")}</MenuItem>
          <MenuItem value={FileType.IBM_SPSS}>{t("options.ibmspss")}</MenuItem>
        </TextField>
      </FormGroup>
    </div>
  );
}
