import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Link, Tabs, Tab } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Questionnaire } from "../../../../../models/questionnaire/questionnaire";
import { Loader } from "../../../../common/loader/Loader.component";
import { Error } from "../../../../common/error/Error.component";
import "./QuestionnaireDetailPage.component.sass";
import { StepsComponent } from "./steps/Steps.component";
import { DetailsComponent } from "./details/Details.component";
import { TabPanelComponent } from "../../../../common/tab-panel/TabPanel.component";
import { CategoriesComponent } from "./categories/Categories.component";
import { useApi } from "../../../../common/providers/Api.provider";
import { TeamsComponent } from "./teams/Teams.component";
import { RolesComponent } from "./roles/Roles.component";
import { QuestionnaireNewRevisionDialogComponent } from "../questionnaire-list-page/dialog/questionnaire-new-revision-dialog/QuestionnaireNewRevisionDialog.component";
import { useTranslation } from "react-i18next";

export function QuestionnaireDetailPageComponent() {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      useSuspense: false,
    }
  );
  const api = useApi();
  const navigate = useNavigate();
  const { questionnaireId } = useParams<{ questionnaireId: string }>();
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);
  const [isQuestionnaireLoadingState, setIsQuestionnaireLoadingState] =
    useState<boolean>(false);
  const [questionnaireToNewRevisionState, setQuestionnaireToNewRevisionState] =
    useState<Questionnaire | undefined>(undefined);
  const [questionnaireState, setQuestionnaireState] = useState<
    Questionnaire | undefined
  >(undefined);
  const [activeTabState, setActiveTabState] = useState<number>(0);

  const getQuestionnaire = async (questionnaireId: number) => {
    try {
      setIsQuestionnaireLoadingState(true);
      const response = await api.questionnaireById(questionnaireId);

      setQuestionnaireState(response.data);
    } catch (e) {
      console.error(e);
      setHasErrorState(true);
    } finally {
      setIsQuestionnaireLoadingState(false);
    }
  };

  const navigateToQuestionnaire = (questionnaireId: number) => {
    setTimeout(() => {
      navigate(`/questionnaires/${questionnaireId}`);
    });
  };

  const checkIfQuestionnaireHasScans = async (
    questionnaireId: number
  ): Promise<boolean> => {
    try {
      const response = await api.questionnairesCheck(questionnaireId);

      if (response.data) {
        setQuestionnaireToNewRevisionState(questionnaireState);
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  const handleCloseQuestionnaireNewRevisionDialog = (result?: number) => {
    setQuestionnaireToNewRevisionState(undefined);

    if (!!result) {
      navigateToQuestionnaire(result);
    }
  };

  const onSaveChanges = (questionnaire: Questionnaire) => {
    setQuestionnaireState(questionnaire);
  };

  useEffect(() => {
    if (!!questionnaireId) {
      const nummericQuestionnaireId = Number(questionnaireId);
      getQuestionnaire(nummericQuestionnaireId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  if (isQuestionnaireLoadingState || !questionnaireState) {
    return <Loader />;
  }

  if (hasErrorState) {
    return <Error />;
  }

  return (
    <>
      <QuestionnaireNewRevisionDialogComponent
        open={!!questionnaireToNewRevisionState}
        questionnaire={questionnaireToNewRevisionState}
        handleClose={handleCloseQuestionnaireNewRevisionDialog}
      />
      <div className="questionnaire-detail-page-container page">
        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
          <Link color="inherit" href="/questionnaires">
            <AssignmentIcon />
            {questionnaireTranslations("title")}
          </Link>
          <Link className="active" color="textPrimary" aria-current="page">
            {questionnaireState.name}
          </Link>
        </Breadcrumbs>
        <div className="questionnaire-detail-page-container__details">
          <Tabs
            value={activeTabState}
            onChange={(event: React.ChangeEvent<{}>, newIndex: number) => {
              if (newIndex === 1 || newIndex === 2) {
                checkIfQuestionnaireHasScans(Number(questionnaireId)).then(
                  (canNavigate) => {
                    if (canNavigate) {
                      setActiveTabState(newIndex);
                    }
                  }
                );
              } else {
                setActiveTabState(newIndex);
              }
            }}
          >
            <Tab label={questionnaireTranslations("details.tab")} />
            <Tab label={questionnaireTranslations("categories.tab")} />
            <Tab label={questionnaireTranslations("steps.tab")} />
            <Tab label={questionnaireTranslations("teams.tab")} />
            <Tab label={questionnaireTranslations("roles.tab")} />
          </Tabs>
          <TabPanelComponent index={0} activeIndex={activeTabState}>
            <DetailsComponent
              questionnaireId={questionnaireId}
              setHasError={setHasErrorState}
              onSaveChanges={onSaveChanges}
            />
          </TabPanelComponent>
          <TabPanelComponent index={1} activeIndex={activeTabState}>
            <CategoriesComponent
              questionnaireId={questionnaireId}
              setHasError={setHasErrorState}
            />
          </TabPanelComponent>
          <TabPanelComponent index={2} activeIndex={activeTabState}>
            <StepsComponent
              questionnaireId={questionnaireId}
              setHasError={setHasErrorState}
            />
          </TabPanelComponent>
          <TabPanelComponent index={3} activeIndex={activeTabState}>
            <TeamsComponent
              questionnaireId={questionnaireId}
              setHasError={setHasErrorState}
            />
          </TabPanelComponent>
          <TabPanelComponent index={4} activeIndex={activeTabState}>
            <RolesComponent
              questionnaireId={questionnaireId}
              setHasError={setHasErrorState}
            />
          </TabPanelComponent>
        </div>
      </div>
    </>
  );
}
