import React, { useState } from "react";
import "./DataList.component.sass";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  Breadcrumbs,
  Link,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Button,
} from "@mui/material";
import { TeamsComponent } from "../teams/Teams.component";
import { QuestionnairesComponent } from "../questionnaires/Questionnaires.component";
import { Filter } from "../filter";
import { Error as ErrorComponent } from "../../../../../common/error/Error.component";
import { API_URL } from "../../../../../../lib/constants";
import fileDownload from "js-file-download";
import { TypeComponent } from "../type/Type.component";
import { FileType } from "../FileType";
import { useTranslation } from "react-i18next";

const translationKeys = [
  "steps.teamsSelection.title",
  "steps.questionnairesSelection.title",
  "steps.fileTypeSelection.title",
];

const emptyFilter: Filter = {
  teams: [],
  questionnaires: [],
  fileType: "",
};

export function DataListComponent() {
  const { t } = useTranslation("data-page", { useSuspense: false });
  const [activeStepState, setActiveStepState] = useState<number>(0);
  const [filterState, setFilterState] = useState<Filter>(emptyFilter);
  const [hasErrorState, setHasErrorState] = useState<boolean>(false);

  const downloadData = async () => {
    try {
      const apiResponse = await fetch(`${API_URL}/data/download`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(filterState),
      });

      const contentDispositionHeader = apiResponse.headers.get(
        "content-disposition"
      );

      if (contentDispositionHeader) {
        const regexMatches = /^attachment; filename="(.*)"$/gm.exec(
          contentDispositionHeader
        );

        const file =
          filterState.fileType === FileType.IBM_SPSS
            ? "download.xlsx"
            : "download.csv";

        fileDownload(
          await apiResponse.blob(),
          regexMatches && regexMatches.length > 1 ? regexMatches[1] : file
        );

        setFilterState(emptyFilter);
        setActiveStepState(0);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getStepContent = () => {
    switch (activeStepState) {
      case 0:
        return (
          <TeamsComponent
            filter={filterState}
            setFilter={setFilterState}
            setHasError={setHasErrorState}
          />
        );
      case 1:
        return (
          <QuestionnairesComponent
            filter={filterState}
            setFilter={setFilterState}
            setHasError={setHasErrorState}
          />
        );
      case 2:
        return (
          <TypeComponent
            filter={filterState}
            setFilter={setFilterState}
            setHasError={setHasErrorState}
          />
        );
      default:
        throw Error("There is no step with the selected index");
    }
  };

  const isNextDisabled = () => {
    switch (activeStepState) {
      case 0:
        return !filterState.teams.length;
      case 1:
        return !filterState.questionnaires.length;
      case 2:
        return !filterState.fileType.length;
      default:
        return false;
    }
  };

  const handleNext = async () => {
    if (activeStepState === translationKeys.length - 1) {
      await downloadData();
    } else {
      setActiveStepState((prevState) => prevState + 1);
    }
  };

  const handleBack = () => {
    setActiveStepState((prevState) => prevState - 1);
  };

  if (hasErrorState) {
    return <ErrorComponent />;
  }

  return (
    <div className="data-list-container">
      <div className="data-list-container__header">
        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
          <Link className="active" color="textPrimary" aria-current="page">
            <SaveAltIcon />
            {t("title")}
          </Link>
        </Breadcrumbs>
      </div>
      <Stepper activeStep={activeStepState} alternativeLabel>
        {translationKeys.map((key, index) => (
          <Step key={index}>
            <StepLabel>{t(key)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper className="data-list-container__paper" elevation={0}>
        <div className="data-list-container__paper__content">
          {getStepContent()}
        </div>
        <div className="data-list-container__paper__actions">
          <Button disabled={activeStepState === 0} onClick={handleBack}>
            {t("navigation.back")}
          </Button>
          <Button
            disabled={isNextDisabled()}
            variant="contained"
            color="secondary"
            onClick={handleNext}
          >
            {t(
              activeStepState === translationKeys.length - 1
                ? "navigation.downloadData"
                : "navigation.next"
            )}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
