import React, { useState, useEffect, createElement } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  IconButton,
  AccordionDetails,
  Divider,
  AccordionActions,
  Button,
  Menu,
  MenuItem,
  FormGroup,
  Tooltip,
} from "@mui/material";
import { QuestionType } from "../../../../../../models/question/questionType";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Step } from "../../../../../../models/step/step";
import { Question } from "../../../../../../models/question/question";
import { Loader } from "../../../../../common/loader/Loader.component";
import { EditableQuestionComponent } from "../editable-question/EditableQuestion.component";
import "./Step.component.sass";
import { move } from "../../../../../../lib/move";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { useSnackbar } from "notistack";
import { ScanValue } from "../../../../../../models/scan-value/scanValue";
import { getDefaultQuestionData } from "../../../../../../lib/getDefaultQuestionData";
import { questionsMaxScore } from "../../../../../../lib/questionMaxScore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";

export interface StepComponentProps {
  step: Step;

  activeStep: Step | null;
  setActiveStep: React.Dispatch<React.SetStateAction<Step | null>>;

  setStepToUpdate: React.Dispatch<React.SetStateAction<Step | undefined>>;
  setStepToDelete: React.Dispatch<React.SetStateAction<Step | undefined>>;
  sendValueUpward: (event: React.ChangeEvent<{}>) => void;
  sendValueDownward: (event: React.ChangeEvent<{}>) => void;

  isUpwardDisabled: boolean;
  isDownwardDisabled: boolean;

  setStepMaxScore: React.Dispatch<number | undefined>;

  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function StepComponent({
  step,
  activeStep,
  setActiveStep,
  setStepToUpdate,
  setStepToDelete,
  sendValueUpward,
  sendValueDownward,
  isUpwardDisabled,
  isDownwardDisabled,
  setStepMaxScore,
  setHasError,
}: StepComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "steps",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });

  const menuItems: { onClick: () => void; text: string }[] = [
    {
      onClick: () => handleAddQuestion(QuestionType.LIKERT_SCALE),
      text: questionnaireTranslations("questions.likertScale.type"),
    },
    {
      onClick: () => handleAddQuestion(QuestionType.OPEN),
      text: questionnaireTranslations("questions.open.type"),
    },
    {
      onClick: () => handleAddQuestion(QuestionType.POLAR),
      text: questionnaireTranslations("questions.polar.type"),
    },
    {
      onClick: () => handleAddQuestion(QuestionType.SELECT),
      text: questionnaireTranslations("questions.select.type"),
    },
  ];

  const api = useApi();
  const snackbar = useSnackbar();

  const [isQuestionsLoadingState, setIsQuestionsLoadingState] =
    useState<boolean>(false);

  const [scanValuesState, setScanValuesState] = useState<ScanValue[]>([]);

  const [questionsState, setQuestionsState] = useState<Question[]>([]);
  const [maxScoreState, setMaxScoreState] = useState<number>(0);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    const newScanValues: ScanValue[] = [];
    for (const question of questionsState) {
      let scanValue = scanValuesState.find((sv) => sv.question === question);
      if (!scanValue) {
        scanValue = new ScanValue();
        scanValue.question = question;
        scanValue.value = "";
        scanValue.score = 0;
        newScanValues.push(scanValue);
      }
    }
    setScanValuesState([...scanValuesState, ...newScanValues]);

    // setMaxScore when questions change
    setMaxScoreState(questionsMaxScore(questionsState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsState, setMaxScoreState]);

  const getQuestions = async (stepId: number) => {
    try {
      setIsQuestionsLoadingState(true);
      const response = await api.questionsByStepId(stepId);

      setQuestionsState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsQuestionsLoadingState(false);
    }
  };

  const handleSaveQuestions = async () => {
    try {
      const response = await api.questionsAdd({
        stepId: step.id,
        questions: questionsState,
        maxScore: maxScoreState,
      });

      if (response.success) {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "success" })
        );

        setQuestionsState(response.data);
        setStepMaxScore(maxScoreState);
      } else {
        snackbar.enqueueSnackbar(
          response.message,
          getSnackbarOptions({ variant: "error" })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddQuestion = (questionType: QuestionType) => {
    const question = {
      stepId: step.id,
      type: questionType,
      data: getDefaultQuestionData(questionType),
      label: "",
      required: false,
      hint: "",
      order: questionsState.length
        ? Math.max(...questionsState.map((q) => q.order)) + 1
        : 0,
    };

    const newIndex = questionsState.length;
    setQuestionsState([...questionsState, question as Question]);
    setActiveIndex(newIndex);
    handleQuestionTypeMenuClose();
  };

  const handleChangeExpansionPanel = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    if (!!activeStep && activeStep.id === step.id) {
      setActiveStep(null);
    } else {
      getQuestions(step.id);
      setActiveStep(step);
    }
  };

  const handleQuestionTypeMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleQuestionTypeMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditStep = (event: React.ChangeEvent<{}>, step: Step) => {
    event.stopPropagation();
    setStepToUpdate(step);
  };

  const handleDeleteStep = (event: React.ChangeEvent<{}>, step: Step) => {
    event.stopPropagation();
    setStepToDelete(step);
  };

  const handleQuestionUpdate = (question: Question, index: number) => {
    setQuestionsState((prevState) =>
      prevState.map((q, i) => (i === index ? { ...question } : q))
    );
  };

  const handleQuestionRemove = (index: number) => {
    setActiveIndex(null);
    setQuestionsState(questionsState.filter((_, i) => i !== index));
  };

  const handleSendQuestionInDirection = (
    index: number,
    direction: "upward" | "downward"
  ) => {
    const newIndex = direction === "upward" ? index - 1 : index + 1;
    let questions = questionsState;

    if (
      (direction === "upward" && newIndex >= 0) ||
      (direction === "downward" && newIndex <= questions.length - 1)
    ) {
      questions = move(questions, index, newIndex).map((q, i) => ({
        ...q,
        order: i,
      }));

      setActiveIndex(newIndex);
    }

    setQuestionsState(questions);
  };

  const renderQuestions = () => {
    if (isQuestionsLoadingState) {
      return <Loader />;
    }

    if (!questionsState.length) {
      return (
        <Typography>{questionnaireTranslations("noQuestions")}</Typography>
      );
    }

    return (
      <FormGroup>
        {questionsState.map((question: Question, index: number) => (
          <EditableQuestionComponent
            key={index}
            value={question}
            active={index === activeIndex}
            setActive={(active) => {
              if (active) {
                setActiveIndex(index);
              } else {
                setActiveIndex(null);
              }
            }}
            setValue={(newValue: Question) =>
              handleQuestionUpdate(newValue, index)
            }
            removeValue={() => handleQuestionRemove(index)}
            sendValueUpward={() =>
              handleSendQuestionInDirection(index, "upward")
            }
            sendValueDownward={() =>
              handleSendQuestionInDirection(index, "downward")
            }
            scanValue={scanValuesState.find((sv) => sv.question === question)!}
            setScanValue={(value) => {
              setScanValuesState(
                scanValuesState.map((sv) =>
                  sv.question === value.question ? value : sv
                )
              );
            }}
            isUpwardDisabled={index === 0}
            isDownwardDisabled={index === questionsState.length - 1}
            copyQuestion={(question) => {
              const copiedQuestion = JSON.parse(JSON.stringify(question));
              copiedQuestion.order =
                Math.max(...questionsState.map((q) => q.order)) + 1;
              delete copiedQuestion.id;
              setQuestionsState([...questionsState, copiedQuestion]);
              setActiveIndex(questionsState.length);
            }}
          />
        ))}
      </FormGroup>
    );
  };

  return (
    <Accordion
      expanded={!!activeStep && activeStep.id === step.id}
      onChange={handleChangeExpansionPanel}
      className="step-container__expansion-panel"
    >
      <AccordionSummary
        className="step-container__expansion-panel__summary"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography component="span">{step.name}</Typography>
        {step.category && (
          <Typography
            component="span"
            className="step-container__expansion-panel__summary__category"
          >
            - {step.category.name}
          </Typography>
        )}
        <span className="spacer"></span>
        <div className="step-container__expansion-panel__actions">
          <span className="step-container__expansion-panel__actions__text">
            {questionnaireTranslations("actions")}
          </span>
          <Tooltip title={commonTranslations("edit")} placement="top">
            <IconButton onClick={(e) => handleEditStep(e, step)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={commonTranslations("remove")} placement="top">
            <IconButton onClick={(e) => handleDeleteStep(e, step)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {isUpwardDisabled ? (
            <IconButton onClick={sendValueUpward} disabled={true}>
              <ArrowUpwardIcon />
            </IconButton>
          ) : (
            <Tooltip title={commonTranslations("moveUpwards")} placement="top">
              <IconButton onClick={sendValueUpward}>
                <ArrowUpwardIcon />
              </IconButton>
            </Tooltip>
          )}
          {isDownwardDisabled ? (
            <IconButton onClick={sendValueDownward} disabled={true}>
              <ArrowDownwardIcon />
            </IconButton>
          ) : (
            <Tooltip
              title={commonTranslations("moveDownwards")}
              placement="top"
            >
              <IconButton onClick={sendValueDownward}>
                <ArrowDownwardIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="step-container__expansion-panel__info">
          {step.description && (
            <>
              <Typography variant="h5">
                {questionnaireTranslations("description")}
              </Typography>
              {createElement("div", {
                dangerouslySetInnerHTML: { __html: step.description },
              })}
            </>
          )}
        </div>
        <div className="step-container__expansion-panel__questions">
          <Typography variant="h5">
            {questionnaireTranslations("questions.title")}
          </Typography>
          {renderQuestions()}
        </div>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button
          size="small"
          color="primary"
          onClick={handleQuestionTypeMenu}
          startIcon={<AddIcon />}
        >
          {questionnaireTranslations("addQuestion")}
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleQuestionTypeMenuClose}
        >
          {menuItems.map((menuItem, index) => (
            <MenuItem key={index} onClick={menuItem.onClick}>
              {menuItem.text}
            </MenuItem>
          ))}
        </Menu>
        <Button
          color="secondary"
          onClick={handleSaveQuestions}
          startIcon={<SaveIcon />}
        >
          {questionnaireTranslations("saveQuestions")}
        </Button>
      </AccordionActions>
    </Accordion>
  );
}
