import { RoleWithScan } from "./roleWithScan";

export function formatScanToRadarGraphDataStatistics(
  roleWithScan: RoleWithScan,
): Array<{ [key:string]: number | string; category: string }> {
  const entries: Array<{ [key:string]: number | string; category: string }> = [];
  if (roleWithScan && roleWithScan.averageScan) {
    for (const categoryResult of roleWithScan.averageScan!.scanCategoryResults) {
      const category = categoryResult.category;

      entries.push({
        category: category.name,
        [roleWithScan.name]:  Number(categoryResult.scaledScore.toPrecision(2)),
      } as any);
    }
  }

  return entries;
}