
import React from "react";

export const getTspanGroups = (value: string, maxLineLength: number, maxLines: number = 2) => {
    const words = value.split(' ')
    
    type linesAcc = {
        lines: string[],
        currLine: string
    }

    //reduces the words into lines of maxLineLength
    const assembleLines: linesAcc = words.reduce( (acc: linesAcc, word: string) => {
        //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
        if ( (word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
            return {
                lines: acc.lines.concat([acc.currLine]),
                currLine: word
            }
        }
        //otherwise add the word to the current line
        return {
            ...acc,
            currLine: acc.currLine + ' ' + word 
        } 
        
    }, {lines: [], currLine: ''})

    //add the ending state of current line (the last line) to lines
    const allLines = assembleLines.lines.concat([assembleLines.currLine])

    //for now, only take first 2 lines due to tick spacing and possible overflow
    const lines = allLines.slice(0, maxLines)
    let children: JSX.Element[] = []
    let dy = 0

    lines.forEach( (lineText, i) => {
        children.push(
            <tspan x={0} dy={dy} key={i}>
                {
                   lineText
                }
            </tspan> 
        )
        //increment dy to render next line text below
        if(dy <= 0)
          dy += 15
    });

    return children
  }