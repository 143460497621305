import React, { useEffect, useState } from "react";
import {
  Paper,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Questionnaire } from "../../../../../../models/questionnaire/questionnaire";
import { QuestionnaireUpdateDto } from "../../../../../../models/questionnaire/questionnaireUpdateDto";
import { useSnackbar } from "notistack";
import { getSnackbarOptions } from "../../../../../../lib/getSnackbarOptions";
import { Loader } from "../../../../../common/loader/Loader.component";
import "./Details.component.sass";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useApi } from "../../../../../common/providers/Api.provider";
import { getCKEditorConfig } from "../../../../../../lib/getCKEditorConfig";
import { useTranslation } from "react-i18next";

export interface DetailsComponentProps {
  questionnaireId: string | undefined;
  onSaveChanges: (questionnaire: Questionnaire) => void;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function DetailsComponent({
  questionnaireId,
  onSaveChanges,
  setHasError,
}: DetailsComponentProps) {
  const { t: questionnaireTranslations } = useTranslation(
    "questionnaire-page",
    {
      keyPrefix: "details",
      useSuspense: false,
    }
  );
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const snackbar = useSnackbar();

  const [isDetailsLoadingState, setIsDetailsLoadingState] =
    useState<boolean>(false);

  const [questionnaireUpdateDtoState, setQuestionnaireUpdateDtoState] =
    useState<QuestionnaireUpdateDto | null>(null);

  const getQuestionnaire = async (questionnaireId: number) => {
    try {
      setIsDetailsLoadingState(true);
      const response = await api.questionnaireById(questionnaireId);

      setQuestionnaireUpdateDtoState(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsDetailsLoadingState(false);
    }
  };

  const handleUpdate = async (
    questionnaireUpdateDto: QuestionnaireUpdateDto
  ) => {
    if (
      !questionnaireUpdateDto.id ||
      !questionnaireUpdateDto.name.length ||
      !questionnaireUpdateDto.description.length
    ) {
      return;
    }

    try {
      const response = await api.questionnairesUpdate(questionnaireUpdateDto);

      snackbar.enqueueSnackbar(
        response.message,
        getSnackbarOptions({
          variant: "success",
        })
      );

      setQuestionnaireUpdateDtoState(response.data);
      onSaveChanges(response.data);
    } catch (e) {
      snackbar.enqueueSnackbar(
        e as String,
        getSnackbarOptions({
          variant: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (!!questionnaireId) {
      getQuestionnaire(Number(questionnaireId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireId]);

  return (
    <Paper className="questionnaire-details-container">
      {isDetailsLoadingState || !questionnaireUpdateDtoState ? (
        <Loader loadingText={commonTranslations("loading")} />
      ) : (
        <>
          <span className="questionnaire-details-container__title">
            {questionnaireTranslations("title")}
          </span>
          <TextField
            className={"questionnaire-details-container__form-control"}
            fullWidth
            variant="outlined"
            label={questionnaireTranslations("name")}
            value={questionnaireUpdateDtoState.name}
            onChange={(event) =>
              setQuestionnaireUpdateDtoState({
                ...questionnaireUpdateDtoState,
                name: event.target.value,
              })
            }
          />
          <CKEditor
            editor={ClassicEditor}
            data={questionnaireUpdateDtoState.description}
            config={{
              ...getCKEditorConfig(),
              placeholder: questionnaireTranslations("description"),
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setQuestionnaireUpdateDtoState({
                ...questionnaireUpdateDtoState,
                description: data,
              });
            }}
          />
          <CKEditor
            editor={ClassicEditor}
            data={questionnaireUpdateDtoState.scanExplanation ?? ""}
            config={{
              ...getCKEditorConfig(),
              placeholder: questionnaireTranslations("explanation"),
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setQuestionnaireUpdateDtoState({
                ...questionnaireUpdateDtoState,
                scanExplanation: data,
              });
            }}
          />
          <div className="questionnaire-details-container__checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={
                    questionnaireUpdateDtoState.explicitlyAskUserPermission
                  }
                  onChange={(_, checked) => {
                    setQuestionnaireUpdateDtoState({
                      ...questionnaireUpdateDtoState,
                      explicitlyAskUserPermission: checked,
                    });
                  }}
                />
              }
              label={questionnaireTranslations("explicitlyAskUserPermission")}
            />
          </div>
          <div className={"questionnaire-details-container__button-container"}>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => handleUpdate(questionnaireUpdateDtoState)}
            >
              {commonTranslations("saveChanges")}
            </Button>
          </div>
        </>
      )}
    </Paper>
  );
}
